import React, {Component, ReactNode} from 'react'
import {request} from '../services/api'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import {ExpandMore} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import {formatDate, formatDatetimeOps, formatISOYearMonth} from '../utils/datetime'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Box from '@material-ui/core/Box'
import {isHomeOffice, vacationTranslation} from '../constants/Vacations'
import {createStyles, Theme} from '@material-ui/core'
import _ from 'lodash'
import ResponsiveTable from './ResponsiveTable';

type Classes =
    | 'root'
    | 'heading'
    | 'secondaryHeading'
    | 'secondaryHeadingRed'
    | 'icon'
    | 'column'
    | 'helper'
    | 'link'
    | 'rightPadding'
    | 'panelDisabled'
    | 'panelSummaryDisabled'
    | 'smallColumn'
    | 'mediumColumn'
    | 'largerColumn'

const styles = ({palette, typography, spacing}: Theme) => createStyles({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: typography.pxToRem(15),
    color: palette.text.secondary,
  },
  secondaryHeadingRed: {
    fontSize: typography.pxToRem(15),
    color: palette.primary.main,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${palette.divider}`,
    padding: spacing(1, 2),
  },
  link: {
    color: palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  rightPadding: {
    paddingRight: spacing(4.5)
  },
  panelDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
  },
  panelSummaryDisabled: {
    minHeight: '36px'
  },
  smallColumn: {
    width: '100px'
  },
  mediumColumn: {
    width: '130px'
  },
  largerColumn: {
    width: '300px'
  },
});

type PanelProps = {
  amount: number,
  disabled?: boolean,
  withoutIcon?: boolean,
  negative?: boolean,
  header: string,
  children?: ReactNode
}

const Panel = withStyles(styles)((props: PanelProps & WithStyles<Classes>) => {
  const header = (
    <Grid container justifyContent='space-between'>
      <Grid item>
        <Typography className={props.classes.heading}>{props.header}</Typography>
      </Grid>
      <Grid item>
        {!props.negative && (
          <Typography className={props.classes.secondaryHeading}>{props.amount > 0 ? '+'+props.amount : props.amount}</Typography>
        )}
        {props.negative && (
          <Typography className={props.classes.secondaryHeadingRed}>{props.amount > 0 ? '-'+props.amount : props.amount}</Typography>
        )}
      </Grid>
    </Grid>
  );
  if (_.has(props, 'withoutIcon')) {
    return (
      <Accordion expanded={false} disabled={props.disabled} classes={{disabled: props.classes.panelDisabled}}>
        <AccordionSummary classes={{content: props.classes.rightPadding, disabled: props.classes.panelSummaryDisabled}}>
          {header}
        </AccordionSummary>
      </Accordion>
    )
  }
  return (
    <Accordion  disabled={props.disabled} classes={{disabled: props.classes.panelDisabled}}>
      <AccordionSummary expandIcon={<ExpandMore/>} classes={{disabled: props.classes.panelSummaryDisabled}}>
        {header}
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Box className={props.classes.root} pr={2.5}>
          {props.children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
});

type FullReportProps = {
  userId: number,
  month: Date
}

type Record = {
  amount: number,
  budget: number,
  object: {
    id: number,
    duration: number,
    date: string,
    from: string,
    until: string,
    description: string,
    project: {
      id: number,
      name: string
    }
  }
}

type Vacation = {
  amount: number,
  budget: number,
  object: VacationInnerObject
}

export type VacationInnerObject = {
  id: number,
  from: string,
  until: string,
  description: string,
  type: string,
  paidDuration: number
}

type Reward = {
  amount: number,
  budget: number,
  object: {
    id: number,
    date: string,
    description: string,
  }
}

type Benefit = {
  amount: number,
  budget: number,
  object: {
    id: number,
    monthly: boolean,
    date: string,
    description: string,
    name: string,
  }
}

type FullReportStates = {
  userId: number,
  month: Date,
  report: {
    salary: {
      paid: {
        benefits: number,
        salary: number
      },
      earned: {
        rewards: number,
        salary: number,
        vacations: number,
        lunches: number
      }
    },
    benefits: {
      spent: number,
      earned: number
    },
    balance: {
      salary: number,
      benefits: number
    },
    entries: {
      records: Record[],
      rewards: Reward[],
      vacations: Vacation[],
      benefits: Benefit[],
      reports: []
    }
  }
}

class FullReport extends Component<FullReportProps & WithStyles<Classes>, FullReportStates> {
  constructor(props: FullReportProps & WithStyles<Classes>) {
    super(props);
    this.state = {
      userId: props.userId,
      month: props.month,
      report: {
        salary: {
          paid: {
            benefits: 0,
            salary: 0
          },
          earned: {
            rewards: 0,
            salary: 0,
            vacations: 0,
            lunches: 0
          }
        },
        benefits: {
          spent: 0,
          earned: 0
        },
        balance: {
          salary: 0,
          benefits: 0
        },
        entries: {
          records: [],
          rewards: [],
          vacations: [],
          benefits: [],
          reports: []
        }
      }
    }
  }

  componentDidMount() {
    const { month } = this.state;
    this.refresh(month)
  }

  refresh(month: Date) {
    const { userId } = this.state;
    request('GET', '/user/'+userId+'/report-full/'+formatISOYearMonth(month))
      .then(data => this.setState({report: data, month: month}))
  }

  componentDidUpdate(prevProps: FullReportProps & WithStyles<Classes>) {
    if (this.props.month.getTime() !== prevProps.month.getTime()) {
      this.refresh(this.props.month)
    }
  }

  render() {
    const { classes } = this.props;
    const { report } = this.state;
    const { earned, paid } = report.salary;
    const salaryEarned = earned.salary + earned.rewards + earned.lunches + earned.vacations - paid.benefits;
    // const hours = report.entries.records.map(row => row.object.duration).reduce((a, b) => a + b, 0);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <Box mb={2}>
            <Typography variant='h5' component='h2'>
              Mzda
            </Typography>
          </Box>
          <div className={classes.root}>
            <Panel header='Mzda za odpracované hodiny' amount={earned.salary}>
              <ResponsiveTable size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.mediumColumn}>Dátum</TableCell>
                    <TableCell className={classes.smallColumn}>Od</TableCell>
                    <TableCell className={classes.smallColumn}>Do</TableCell>
                    <TableCell className={classes.largerColumn}>Projekt</TableCell>
                    <TableCell>Poznámka</TableCell>
                    <TableCell align='center' className={classes.mediumColumn}>Počet hodín</TableCell>
                    <TableCell align='right' className={classes.smallColumn}/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.entries.records.map(row => (
                    <TableRow key={row.object.id}>
                      <TableCell>{formatDate(row.object.date)}</TableCell>
                      <TableCell>{row.object.from}</TableCell>
                      <TableCell>{row.object.until}</TableCell>
                      <TableCell>{row.object.project ? row.object.project.name : 'Nezaradené'}</TableCell>
                      <TableCell>{row.object.description}</TableCell>
                      <TableCell align='center'>{row.object.duration}</TableCell>
                      <TableCell align='right'>
                        <Typography className={classes.secondaryHeading}>+{row.amount}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ResponsiveTable>
            </Panel>

            {earned.vacations > 0 && (
              <Panel header='Preplatené dovolenky' amount={earned.vacations} classes={classes}>
                <ResponsiveTable size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.mediumColumn}>Od</TableCell>
                      <TableCell className={classes.mediumColumn}>Do</TableCell>
                      <TableCell className={classes.largerColumn}>Typ</TableCell>
                      <TableCell>Popis</TableCell>
                      <TableCell align='center' className={classes.largerColumn}>Preplatených hodín</TableCell>
                      <TableCell align='right' className={classes.smallColumn}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.entries.vacations.map(row => (
                      <TableRow key={row.object.id}>
                        <TableCell>{formatDatetimeOps(row.object.from, isHomeOffice(row.object))}</TableCell>
                        <TableCell>{formatDatetimeOps(row.object.until, isHomeOffice(row.object))}</TableCell>
                        <TableCell>{vacationTranslation(row.object.type)}</TableCell>
                        <TableCell>{row.object.description}</TableCell>
                        <TableCell align='center'>{isHomeOffice(row.object) ? '-' : (row.object.paidDuration ? row.object.paidDuration : 0)}</TableCell>
                        <TableCell align='right'>
                          <Typography className={classes.secondaryHeading}>+{row.amount}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </ResponsiveTable>
              </Panel>
            )}

            {earned.lunches > 0 && (
              <Panel header='Priznaný príspevok na obedy' amount={earned.lunches} withoutIcon classes={classes}/>
            )}

            {earned.rewards > 0 && (
              <Panel header='Odmeny' amount={earned.rewards} classes={classes}>
                <ResponsiveTable size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.mediumColumn}>Dátum</TableCell>
                      <TableCell>Poznámka</TableCell>
                      <TableCell align='right' className={classes.smallColumn}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.entries.rewards.map(row => (
                      <TableRow key={row.object.id}>
                        <TableCell>{formatDate(row.object.date)}</TableCell>
                        <TableCell>{row.object.description}</TableCell>
                        <TableCell align='right'>
                          <Typography className={classes.secondaryHeading}>+{row.amount}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </ResponsiveTable>
              </Panel>
            )}

            {paid.benefits > 0 && (
              <Panel header='Spoluúčasť na benefitoch' amount={paid.benefits} negative classes={classes}>
                <ResponsiveTable size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.mediumColumn}>Dátum</TableCell>
                      <TableCell className={classes.largerColumn}>Názov</TableCell>
                      <TableCell>Poznámka</TableCell>
                      <TableCell align='right' className={classes.smallColumn}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.entries.benefits.filter(r => (r.amount > 0)).map(row => (
                      <TableRow key={row.object.id}>
                        <TableCell>{row.object.monthly ? 'opakujúce sa' : formatDate(row.object.date)}</TableCell>
                        <TableCell>{row.object.name}</TableCell>
                        <TableCell>{row.object.description}</TableCell>
                        <TableCell align='right'>
                          <Typography className={classes.secondaryHeadingRed}>-{row.amount}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </ResponsiveTable>
              </Panel>
            )}

            <Divider/>
            <Panel header='Zarobená čiastka' amount={salaryEarned} disabled withoutIcon classes={classes}/>
            <Panel header='Vyplatená čiastka' amount={paid.salary} disabled withoutIcon classes={classes}/>
          </div>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Box mb={2}>
            <Typography variant='h5' component='h2'>
              Benefity
            </Typography>
          </Box>
          <div className={classes.root}>
            <Panel header='Príspevok za mesiac' amount={report.benefits.earned} withoutIcon classes={classes}/>

            <Panel header='Využité benefity' amount={report.benefits.spent} negative classes={classes}>
              <ResponsiveTable size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.mediumColumn}>Dátum</TableCell>
                    <TableCell className={classes.largerColumn}>Názov</TableCell>
                    <TableCell>Poznámka</TableCell>
                    <TableCell align='right' className={classes.smallColumn}/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.entries.benefits.filter(r => (r.budget > 0)).map(row => (
                    <TableRow key={row.object.id}>
                      <TableCell>{row.object.monthly ? 'opakujúce sa' : formatDate(row.object.date)}</TableCell>
                      <TableCell>{row.object.name}</TableCell>
                      <TableCell>{row.object.description}</TableCell>
                      <TableCell align='right'>
                        <Typography className={classes.secondaryHeadingRed}>-{row.budget}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ResponsiveTable>
            </Panel>

            <Divider/>
            <Panel header='Zmena za aktuálny mesiac' amount={report.balance.benefits} disabled withoutIcon classes={classes}/>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(FullReport)
