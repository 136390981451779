import React, {Component} from 'react'
import clsx from 'clsx'
import MenuAppBar from '../../components/MenuAppBar'
import Block from '../../components/Block'
import {request} from '../../services/api'
import { startOfWeek, endOfWeek, setDay, addWeeks, compareAsc, eachDayOfInterval, parseISO, format, formatISO, formatRFC3339 } from 'date-fns'
import skLocale from 'date-fns/locale/sk'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import {WorkOff, Search, HomeWork, LibraryAddCheck, LocalHospital } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import {lighten} from '@material-ui/core/styles'
import {authentication} from '../../services/authentication'
import _ from 'lodash/lang'
import Box from '@material-ui/core/Box'
import WorkPlanDay from './WorkPlanDay'
import {withSnackbar} from 'notistack'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import {getWithAllowedTypes, isHomeOffice, vacationsWithDoctor, vacationsWithOffs, vacationsWithSick} from '../../constants/Vacations'
//import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
//import DateFnsUtils from '@date-io/date-fns'
import {formatDate, isSameDate, isDateBetween, formatISOYearMonth, isPlanOn} from '../../utils/datetime'
import ResponsiveTable from '../../components/ResponsiveTable'
import WorkPlanHeader from './WorkPlanHeader'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckboxModal from './CheckboxModal'
import {withTranslation} from 'react-i18next';
import csLocale from 'date-fns/locale/cs';
import i18next from 'i18next'
import { getContractBasedOnDate } from '../../utils/userInfo'
import AddVacationsDialog from '../../components/AddVacationsDialog'

const locale = {locale: i18next.language === 'sk' ? skLocale : csLocale}

const styles = (theme) => ({
  tableRoot: {
    tableLayout: 'fixed',
    userSelect: 'none',
    width: '100%'
  },
  tableBox: {
    width: '100%',
  },
  currentDayHighlight: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.95)
  },
  cellHighlight: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.light, 0.8)
    }
  },
  cellHighlightOver: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.7)
  },
  cellPadding: {
    padding: '5px 2px'
  },
  tooltip: {
    padding: theme.spacing(1)
  },
  cell: {
    padding: '0px',
    verticalAlign: 'top',
    width: '16%'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  verticalBorderRight: {
    borderRight: '1px solid lightgray'
  },
  verticalBorders: {
    borderLeft: '1px solid lightgray',
    borderRight: '1px solid lightgray'
  }
})

class WorkPlan extends Component {
  vacationDefault = {
    name: '',
    from: formatRFC3339(new Date()),
    until: formatRFC3339(new Date()),
    type: 'VACATION_OFFICIAL',
    description: '',
    approved: null,
    paidDuration: 0
  }

  constructor(props) {
    super(props)
    this.user = authentication.getUser()
    this.canEdit = this.user.isAdmin()

    const yearMonth = formatISOYearMonth(new Date())
    this.currentContract = getContractBasedOnDate(this.user, yearMonth) 
    this.withOffs = getWithAllowedTypes(vacationsWithOffs, this.currentContract && this.currentContract.type.name)
    this.withDoctor = getWithAllowedTypes(vacationsWithDoctor, this.currentContract && this.currentContract.type.name)
    this.withSick = getWithAllowedTypes(vacationsWithSick, this.currentContract && this.currentContract.type.name)

    this.state = {
      date: new Date(),
      users: [],
      projects: [],
      skills: [],
      plans: [],
      selecting: false,
      selectedUser: undefined,
      selectedProject: undefined,
      place: '',
      startDate: undefined,
      endDate: undefined,
      popoverAnchorEl: null,
      popoverText: '',
      dialogOpen: false,
      dialogVacationOpen: false,
      vacation: this.vacationDefault,
      vacationTypes: vacationsWithOffs,
      vacationLimitMessage: '',
      dialogVacationApproveOpen: false,
      employeeFilter: '',
      skillDialogOpen: false,
      skillFilter: [],
      projectFilter: [],
      planTypeFilter: [],
    }

    this.handleWeekBack = this.handleWeekBack.bind(this)
    this.handleWeekNext = this.handleWeekNext.bind(this)
    this.handleCurrentDay = this.handleCurrentDay.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleApprove = this.handleApprove.bind(this)
    this.handleDisapprove = this.handleDisapprove.bind(this)
    this.handleSave= this.handleSave.bind(this)
    this.handleSaveVacation= this.handleSaveVacation.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChangeVacationDatetime = this.handleChangeVacationDatetime.bind(this)
    this.handleOpenVacation= this.handleOpenVacation.bind(this)
    this.handleApprovedPaidVacation = this.handleApprovedPaidVacation.bind(this)
    this.handleEmployeeFilter = this.handleEmployeeFilter.bind(this)
    this.selectUsers = this.selectUsers.bind(this)
    this.showSkillDialog = this.showSkillDialog.bind(this)
    this.filterUsersByName = this.filterUsersByName.bind(this)
    this.handleSkillFilter = this.handleSkillFilter.bind(this)
    this.handleProjectFilter = this.handleProjectFilter.bind(this)
    this.handlePlanTypeFilter = this.handlePlanTypeFilter.bind(this)
    this.handleSkillSelect = this.handleSkillSelect.bind(this)
  }

  componentDidMount() {
    
    request('GET', '/user').then(data => {
      this.setState({
        users: data.map(u => {
          return {
            id: u.id,
            skills: u.skills,
            fullName: u.firstName + ' ' + u.lastName,
            projects: u.projects.map(p => { return  { id: p.id, name: p.name }} )
          }
        })
      })
    })
    request('GET', '/project').then(data => {
      data.sort((a, b) => b.shortcut > a.shortcut ? -1 : 1)
      this.setState({
        projects: data.map(p => {
          return {
            id: p.id,
            name: p.name,
            shortcut: p.shortcut
          }
        })
      })
    })

    request('GET', '/skill').then(skills => {
      skills.sort((a, b) => b.name > a.name ? -1 : 1)
      this.setState({ skills })
    })

    this.refresh(this.state.date)
  }

  getWeekStartAndEndDates(date) {
    let weekStart = startOfWeek(date, locale)
    let nextWeek = addWeeks(date, 1)
    let weekEnd = endOfWeek(nextWeek, locale)
    return [weekStart, weekEnd]
  }

  refresh(date) {
    const [weekStart, weekEnd] = this.getWeekStartAndEndDates(date)
    let endDate = formatISO(weekEnd, {representation: 'date'})
    let startDate = formatISO(weekStart, {representation: 'date'})
    const user = authentication.getUser()
    request('GET', '/user/' + user.id + '/record/' +startDate+'/'+endDate)
      .then(data => this.setState({
        plans: data
      }))
  }

  handleWeekBack() {
    let date = addWeeks(this.state.date, -2)
    this.setState({date: date})
    this.refresh(date)
  }

  handleWeekNext() {
    let date = addWeeks(this.state.date, 2)
    this.setState({date: date})
    this.refresh(date)
  }

  handleCurrentDay() {
    let date = new Date()
    this.setState({date: date})
    this.refresh(date)
  }

  handleMouseDown(userId, date) {
    const user = authentication.getUser()
    if (user.isAdmin()) { //TODO  || user.id === userId => allow to change for current user
      this.setState({selectedUser: userId, startDate: date, endDate: date, selecting: true})
    }
  }

  handleMouseUp() {
    if (this.state.selectedUser) {
      this.setState({selecting: false, dialogOpen: true})
    }
  }

  handleMouseOver(date) {
    if (this.state.selecting) {
      this.setState({endDate: date})
    }
  }

  handleSave() {
    const { selectedUser, selectedProject, startDate, endDate /*, place */ } = this.state
    let [from, until] = [startDate, endDate]
    if (compareAsc(startDate, endDate) === 1) {
      [from, until] = [endDate, startDate]
    }
    
    const eachDay = eachDayOfInterval({
      start: from,
      end: until
    })

    Promise.all(
      eachDay.map(day => {
      
        let body = {
          project: {id: selectedProject},
          //place: place,
          description: '',
          duration: 8,
          date: formatISO(day, {representation: 'date'}),
          from: '08:00', 
          until: '16:00' 
        }
        return request('POST', `/user/${selectedUser}/record`, body)
      })
    ).then(records => {
      
      if (records.length === eachDay.length) {
        this.props.enqueueSnackbar(this.props.t('record_added_successfully'), {variant: 'success'})
        this.refresh(this.state.date)
        this.setState({dialogOpen: false, place: '', selectedUser: undefined})
      }
      
    })
      .catch(error => this.props.enqueueSnackbar(error.reason, {variant: 'error'}))
    
  }

  handleSaveVacation() {
    const user = authentication.getUser()
    request('POST', '/user/'+user.id+'/vacation', this.state.vacation)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('record_added_successfully'), {variant: 'success'})
        this.refresh(this.state.date)
        this.setState({dialogVacationOpen: false})
      })
      .catch(error => this.props.enqueueSnackbar(error, {variant: 'error'}))
  }

  handleApprovedPaidVacation() {
    let vacation = _.clone(this.state.vacation)
    vacation.approved = formatRFC3339(new Date())
    request('PUT', `/user/${vacation.user}/vacation/${vacation.id}`, vacation)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('record_approved_successfully'), {variant: 'success'})
        this.refresh(this.state.date)
        this.setState({dialogVacationApproveOpen: false})
      })
      .catch(error => this.props.enqueueSnackbar(error, {variant: 'error'}))
  }

  handleClose() {
    this.setState({selectedUser: undefined, startDate: undefined, dialogOpen: false, place: ''})
  }

  handleDelete(plan) {
    let url =  plan.objectType === 'plan' ? `/user/${plan.user}/record/${plan.id}` : `/user/${plan.user}/vacation/${plan.id}`
    request('DELETE', url)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('record_deleted_successfully'), {variant: 'success'})
        this.refresh(this.state.date)
      })
      .catch(error => this.props.enqueueSnackbar(error, {variant: 'error'}))
  }

  handleApprove(plan) {
    if (plan.objectType === 'vacation') {
      if (isHomeOffice(plan)) {
        plan.approved = formatRFC3339(new Date())
        request('PUT', `/user/${plan.user}/vacation/${plan.id}`, plan)
          .then(() => {
            this.props.enqueueSnackbar(this.props.t('record_approved_successfully'), {variant: 'success'})
            this.refresh(this.state.date)
          })
          .catch(error => this.props.enqueueSnackbar(error, {variant: 'error'}))
      }
      else {
        this.setState({dialogVacationApproveOpen: true, vacation: plan})
      }
    }
  }

  handleDisapprove(plan) {
    if (plan.objectType === 'vacation') {
      plan.approved = null
      plan.paidDuration = 0
      request('PUT', `/user/${plan.user}/vacation/${plan.id}`, plan)
        .then(() => {
          this.props.enqueueSnackbar(this.props.t('record_marked_as_unapproved'), {variant: 'success'})
          this.refresh(this.state.date)
        })
        .catch(error => this.props.enqueueSnackbar(error, {variant: 'error'}))
    }
  }

  handleChangeVacationDatetime(field, value, setHours = false) {
    let vacation = _.cloneDeep(this.state.vacation)
    if (setHours) {
      switch (field) {
      case 'from':
        value.setHours(0,0,0)
        break
      default:
        value.setHours(23,59,59)
        break
      }
    }
    vacation[field] = formatRFC3339(value)
    this.setState({vacation})
  }

  refreshVacationLimit() {
    const user = authentication.getUser()
    request('GET', '/user/'+user.id+'/vacation-limit/'+format(new Date(), 'Y')+'/remaining')
      .then(data => {
        let vacationLimitMessage = ''
        if (data.official === 0 && data.unofficial === 0) {
          vacationLimitMessage = this.props.t('no_remaining_vacation')
        }
        else {
          let arr = []
          if (data.official) {
            arr.push(data.official.toString() + this.props.t('days_of_official'))
          }
          if (data.unofficial) {
            arr.push(data.unofficial.toString() + this.props.t('days_of_unofficial'))
          }
          vacationLimitMessage = this.props.t('this_year_remaining') + arr.join(' a ') + this.props.t('of_vacation')
        }
        this.setState({vacationLimitMessage})
      })
      .catch(error => this.props.enqueueSnackbar(error, {variant: 'error'}))
  }

  handleOpenVacation(vacationTypes, vacation = null) {
    if (vacation === null) {

      let from = new Date()
      let until = new Date()
      if (vacationTypes && !vacationTypes.withTime) {
        from.setHours(0,0,0,0)
        until.setHours(23,59,59,0)
      }

      vacation = _.clone(this.vacationDefault)
      vacation.type = vacationTypes && vacationTypes.types[0].type || 'VACATION_OFFICIAL'
      vacation.from = formatRFC3339(from)
      vacation.until = formatRFC3339(until)
    }
    
    this.setState({dialogVacationOpen: true, vacation, vacationTypes})
    this.refreshVacationLimit()
  }

  handlePlanTypeFilter(planTypeFilter) {
    this.setState({ planTypeFilter })
  }


  handleEmployeeFilter(e) {
    this.setState({ employeeFilter: e.target.value })
  }

  filterUsersByName(users) {
    const { employeeFilter } = this.state
    const sanitize = str => str.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')

    if (employeeFilter === '')
      return users
    else {
      const parts = employeeFilter.trim().split(/\s+/g)
      const rexp = parts.map(p => new RegExp(sanitize(p), 'g'))

      return users.filter(user => {
        return rexp.find(re => sanitize(user.fullName).match(re))
      })
    }
  }

  filterUsersBySkill(users) {
    const { skillFilter } = this.state
    return skillFilter.length ? users.filter(u => u.skills.find(s => skillFilter.includes(s.id))) : users
  }

  filterUsersByProject(users) {
    const { projectFilter } = this.state
    return projectFilter.length ? users.filter(u => u.projects.find(s => projectFilter.includes(s.id))) : users
  }

  selectUsers() {
    const { users } = this.state
    return this.filterUsersByName(
      this.filterUsersByProject(
        this.filterUsersBySkill(
          users
        ))) || []
  }

  showSkillDialog() {
    this.setState({ skillDialogOpen: true })
  }

  handleSkillFilter(skillFilter) {
    this.setState({ skillFilter, skillDialogOpen: false })
  }

  handleProjectFilter(projectFilter) {
    this.setState({ projectFilter })
  }

  handleSkillSelect(e) {
    const { skillFilter } = this.state
    const v = Number(e.target.value)
    const i = skillFilter.indexOf(v)
    const nextState = i > -1 ? [ ...skillFilter.slice(0, i), ...skillFilter.slice(i+1) ] : [ ...skillFilter, v ]
    this.setState({ skillFilter: nextState  })
  }

  isPlanShown(plan) {
    const { planTypeFilter } = this.state
    return planTypeFilter.length === 0 || 
      (plan.objectType === 'plan' && planTypeFilter.includes('projects')) ||
      ([ 'VACATION_OFFICIAL' ].includes(plan.type) && planTypeFilter.includes('vacation')) ||
      ([ 'OFF', 'HALF_OFF', 'SICK_LEAVE', 'OTHER', 'DOCTOR' ].includes(plan.type) && planTypeFilter.includes('off'))
  }

  render() {
    const { classes, t } = this.props
    const { date, plans, startDate, endDate, selectedProject/*, place*/, projects, vacation, vacationTypes, dialogVacationOpen, employeeFilter, skillFilter } = this.state
    const [weekStart, weekEnd] = this.getWeekStartAndEndDates(date)
    const weekDays = [1,2,3,4,5,7,8,9,10,11].map(i => setDay(weekStart, i, locale))
    const currentDate = new Date()
    const currentUserId = authentication.getUser().id
    const selectedUsers = this.selectUsers()
    return (
      <MenuAppBar>
        <Block header={t('disco_plan')}>
          <WorkPlanHeader
            date={date} weekStart={weekStart} weekEnd={weekEnd}
            handleCurrentDay={this.handleCurrentDay} handleWeekNext={this.handleWeekNext} handleWeekBack={this.handleWeekBack}
            handleSkillChange={this.handleSkillFilter} skills={this.state.skills.filter(s => skillFilter.includes(s.id))}
            handleProjectChange={this.handleProjectFilter} projects={this.state.projects}
            handlePlanType={this.handlePlanTypeFilter}
          />

          <Box className={classes.tableBox}>
            <ResponsiveTable className={classes.tableRoot} size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={180} className={classes.cellPadding}>
                    <FormControl variant="outlined" size="small">
                      <InputLabel htmlFor="filter-employees">{t('employee')}</InputLabel>
                      <OutlinedInput
                        id="filter-employees"
                        labelWidth={100}
                        onChange={this.handleEmployeeFilter}
                        value={employeeFilter}
                        startAdornment={
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </TableCell>

                  <TableCell className={clsx(classes.verticalBorders, classes.cellPadding)}>
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={<LibraryAddCheck />}
                      onClick={this.showSkillDialog}
                    > Skills
                    </Button>
                    <CheckboxModal title="Vyber skilly" handleChange={this.handleSkillFilter} handleClick={this.handleSkillSelect} open={this.state.skillDialogOpen} options={this.state.skills} selected={skillFilter} />

                  </TableCell>
                  {weekDays.map((date, index) => (
                    <TableCell
                      key={index}
                      className={clsx(
                        isSameDate(currentDate, date) && classes.currentDayHighlight,
                        index === 4 && classes.verticalBorderRight
                      )}
                      align="center"
                    >
                      <span className={classes.nowrap}>{format(date, 'eeeee, ', locale)}</span>
                      <span className={classes.nowrap}>{format(date, 'd. M.', locale)}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedUsers.map(row => (
                  <TableRow key={row.id}  className={clsx(row.id === currentUserId && classes.currentDayHighlight)}>
                    <TableCell component="th" scope="row">
                      {row.fullName}
                    </TableCell>
                    <TableCell className={classes.verticalBorders}>
                      {row.skills.map(s => s.name).join(', ')}
                    </TableCell>
                    {weekDays.map((date, index) => (
                      <TableCell
                        onMouseDown={() => this.handleMouseDown(row.id, date)}
                        onMouseUp={this.handleMouseUp}
                        onMouseOver={() => this.handleMouseOver(date)}
                        onTouchStart={() => this.handleMouseDown(row.id, date)}
                        onTouchMove={this.handleMouseUp}
                        onTouchEnd={() => this.handleMouseOver(date)}
                        key={index}
                        className={clsx(
                          isSameDate(currentDate, date) && classes.currentDayHighlight,
                          row.id === this.state.selectedUser && isDateBetween(date, startDate, endDate) && classes.cellHighlightOver,
                          (this.canEdit || row.id === currentUserId) && _.isUndefined(this.state.selectedUser) && classes.cellHighlight,
                          index === 4 && classes.verticalBorderRight,
                          classes.cell
                        )}
                      >
                        <WorkPlanDay
                          plans={plans.filter(plan=> plan.user === row.id && isPlanOn(plan, date) && this.isPlanShown(plan))}
                          date={date}
                          onDelete={this.handleDelete}
                          onApprove={this.handleApprove}
                          onDisapproved={this.handleDisapprove}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </ResponsiveTable>
          </Box>

          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              { this.withOffs.types.length &&
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<WorkOff/>}
                    onClick={() => this.handleOpenVacation(vacationsWithOffs)}
                  >
                    {this.withOffs.title}
                  </Button>
                </Box> 
              }

              { this.withDoctor.types.length &&
                <Box mt={2} ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<HomeWork/>}
                    onClick={() => this.handleOpenVacation(vacationsWithDoctor)}
                  >
                    {this.withDoctor.title}
                  </Button>
                </Box>
              }

              { this.withSick.types.length &&
                <Box mt={2} ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LocalHospital/>}
                    onClick={() => this.handleOpenVacation(vacationsWithSick)}
                  >
                    {this.withSick.title}
                  </Button>
                </Box>
              }

            </Grid>
          </Grid>
        </Block>

        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>{t('record')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('type_activity_for_employee')} {startDate ? formatDate(startDate) : ''} do {endDate ? formatDate(endDate) : ''}.
            </DialogContentText>
            <FormControl fullWidth margin="dense">
              <InputLabel id="project-label">Projekt</InputLabel>
              <Select
                autoFocus
                labelId="project-label"
                id="project"
                value={selectedProject ? selectedProject : ''}
                onChange={(e) => this.setState({selectedProject: parseInt(e.target.value)})}
              >
                {projects.map(project => (
                  <MenuItem key={'worker-user-'+project.id} value={project.id}>{project.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/*<TextField
              margin="dense"
              id="setting-value"
              label={t('place')}
              onChange={(e) => this.setState({place: e.target.value})}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={place}
              fullWidth
            />*/}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>

        <AddVacationsDialog
          open={dialogVacationOpen}
          vacationTypes={vacationTypes}
          user={this.user} //change this.user for user you want to edit (for admin page edit for example)
          userContract={this.currentContract}
          onSave={() => {
            this.refresh(date);
            this.setState({ dialogVacationOpen: false });
          }}
          onClose={() => this.setState({ dialogVacationOpen: false })}
          editedVacation={vacation}
        />

        {/*} <Dialog open={this.state.dialogVacationOpen} onClose={() => this.setState({dialogVacationOpen: false})}>
          <DialogTitle>{isHomeOffice(vacation) ? 'Home office' : 'Dovolenka'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.vacationLimitMessage}
            </DialogContentText>
            {isHomeOffice(vacation) && (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale.locale}>
                <Grid container spacing={1}>
                  <Grid item xl={6}>
                    <DateTimePicker
                      label="Od"
                      todayLabel="Dnes"
                      cancelLabel={t('cancel')}
                      okLabel={t('set')}
                      fullWidth
                      value={vacation.from}
                      disablePast
                      onChange={value => this.handleChangeVacationDatetime('from', value)}
                      ampm={false}
                      showTodayButton
                      autoOk
                    />
                  </Grid>
                  <Grid item xl={6}>
                    <DateTimePicker
                      label="Do"
                      todayLabel="Dnes"
                      cancelLabel={t('cancel')}
                      okLabel={t('set')}
                      fullWidth
                      value={vacation.until}
                      disablePast
                      onChange={value => this.handleChangeVacationDatetime('until', value)}
                      ampm={false}
                      showTodayButton
                      autoOk
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            )}
            {!isHomeOffice(vacation) && (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale.locale}>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="vacation-type-label">Typ</InputLabel>
                  <Select
                    labelId="vacation-type-label"
                    id="vacation-type"
                    value={vacation.type}
                    onChange={(e) => {
                      let vacation = _.cloneDeep(this.state.vacation)
                      vacation.type = e.target.value
                      this.setState({vacation})
                    }}
                  >
                    {Object.keys(vacations).filter(v => v !== 'HOME_OFFICE').map(v => (
                      <MenuItem key={'vacation-type-'+v} value={v}>{vacations[v]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Grid container spacing={1}>
                  <Grid item xl={6}>
                    <DatePicker
                      fullWidth
                      label="Od"
                      todayLabel="Dnes"
                      cancelLabel={t('cancel')}
                      okLabel={t('set')}
                      value={vacation.from}
                      disablePast
                      onChange={value => this.handleChangeVacationDatetime('from', value, true)}
                      animateYearScrolling
                      showTodayButton
                      autoOk
                    />
                  </Grid>
                  <Grid item xl={6}>
                    <DatePicker
                      fullWidth
                      label="Do"
                      todayLabel="Dnes"
                      cancelLabel={t('cancel')}
                      okLabel={t('set')}
                      value={vacation.until}
                      disablePast
                      onChange={value => this.handleChangeVacationDatetime('until', value, true)}
                      animateYearScrolling
                      showTodayButton
                      autoOk
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            )}
            <TextField
              autoFocus
              margin="dense"
              id="vacation-description"
              label="Poznámka"
              onChange={(e) => {
                let vacation = _.cloneDeep(this.state.vacation)
                vacation.description = e.target.value
                this.setState({vacation})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSaveVacation() } }} //Save a form if enter is pressed
              value={vacation.description}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({dialogVacationOpen: false})} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSaveVacation} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>*/}

        <Dialog open={this.state.dialogVacationApproveOpen} onClose={() => this.setState({dialogVacationApproveOpen: false})}>
          <DialogTitle>{t('vacation_approving')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('dialog_text')} {vacation.from ? format(parseISO(vacation.from), 'd. M. Y', locale) : ''} do {vacation.until ? format(parseISO(vacation.until), 'd. M. Y', locale) : ''}.
            </DialogContentText>
            <TextField
              margin="dense"
              id="vacation-paid-duration"
              label={t('number_of_paid_hours')}
              onChange={(e) => {
                let vacation = _.cloneDeep(this.state.vacation)
                vacation.paidDuration = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({vacation})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleApprovedPaidVacation() } }} //Save a form if enter is pressed
              value={vacation.paidDuration}
              fullWidth
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({dialogVacationApproveOpen: false})} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleApprovedPaidVacation} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </MenuAppBar>
    )
  }
}

export default withTranslation('workplan')(withStyles(styles)(withSnackbar(WorkPlan)))
