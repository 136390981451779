import { startOfMonth } from 'date-fns';


export type Contract = {id: number, from: string, until: string, type: {id: number, name: string}}

export interface UserInfo {
  id: number
  contracts: [Contract] | []
  email: string
  firstName: string
  fullName: string
  isAdmin: () => boolean
  isProjectManager: () => boolean
  isBackOffice: () => boolean,
  isEmployee: () => boolean,
  lastName: string
  nickName: string
  position: string
  role: string
  active: boolean
  lang: 'cs' | 'sk' | ''
}

export const getContractBasedOnDate = (user: UserInfo, date: string): Contract | undefined => {
  if(!user.contracts.length) return undefined

  const usedDate = new Date(date)

  return user.contracts.find(c => (
    usedDate >= startOfMonth(new Date(c.from)) && usedDate <= new Date(c.until)
  ))

}

