import dialogs from './dialogs.json';
import sections from './sections.json'
import project from './project.json'
import settings from './settings.json'
import user from './user.json'
import vacations from './vacations.json'
import workplan from './workplan.json'

export default {
  dialogs,
  project,
  sections,
  settings,
  user,
  vacations,
  workplan
}