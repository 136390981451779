import React, {Component} from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import Block from '../../components/Block'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {Typography} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {request} from '../../services/api'
import Slider from '@material-ui/core/Slider'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import withStyles from '@material-ui/core/styles/withStyles'
import {withSnackbar} from 'notistack'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = () => ({
  sliderDisabledMark: {
    color: '#DDD'
  }
})

class ContractType extends Component {
  minimalHours = 0
  maximalHours = 200
  step = 20
  marks() {
    let marks = []
    for (let i = this.minimalHours; i < this.maximalHours; i += this.step) {
      marks.push({value: i, label: i})
    }
    marks.push({value: this.maximalHours, label: '∞'})
    return marks
  }

  contractTypeDefault = {
    name: '',
    benefitSettings: []
  }

  settingDefault = {
    hours: {
      from: this.minimalHours,
      to: this.maximalHours
    },
    amount: 0
  }

  constructor(props) {
    super(props)
    this.contractTypeId = this.props.match.params.id
    this.state = {contractType: this.contractTypeDefault}
    this.handleSave = this.handleSave.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeSlider = this.handleChangeSlider.bind(this)
    this.handleDeleteSetting = this.handleDeleteSetting.bind(this)
    this.handleAddSetting = this.handleAddSetting.bind(this)
    this.handleChangeValue = this.handleChangeValue.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    request('GET', '/contract-type/' + this.contractTypeId).then(data => this.setState({contractType: data}))
  }

  handleSave() {
    const { contractType } = this.state
    let hour = this.minimalHours
    while (hour < this.maximalHours && hour !== null) {
      const localHour = hour
      let next = contractType.benefitSettings.filter(setting => ((setting.hours.from ? setting.hours.from : 0) === localHour))
      if (next.length !== 1) {
        this.props.enqueueSnackbar(this.props.t('bad_benefit_settings', {hour: hour}), {variant: 'error'})
        return
      }
      hour = next[0].hours.to
    }

    request('PUT', '/contract-type/'+ this.contractTypeId, contractType)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  handleChangeName(e) {
    let type = Object.assign({}, this.state.contractType)
    type['name'] = e.target.value
    this.setState({contractType: type})
  }

  handleChangeSlider(index, value) {
    let min = Math.min(value[0], value[1])
    let max = Math.max(value[0], value[1])
    this.setState(state => {
      let type = {...state.contractType}
      type.benefitSettings[index] = {
        ...type.benefitSettings[index],
        hours: {from: min, to: (max === this.maximalHours ? null : max)}
      }
      return {contractType: type}
    })
  }

  handleChangeValue(index, value) {
    this.setState(state => {
      let type = {...state.contractType}
      type.benefitSettings[index] = {
        ...type.benefitSettings[index],
        amount: parseInt(value) ? parseInt(value) : 0
      }
      return {contractType: type}
    })
  }

  handleDeleteSetting(index) {
    this.setState(state => {
      let type = {...state.contractType}
      type.benefitSettings.splice(index, 1)
      return {contractType: type}
    })
  }

  handleAddSetting() {
    this.setState(state => {
      let type = {...state.contractType}
      type.benefitSettings.push(this.settingDefault)
      return {contractType: type}
    })
  }

  render() {
    const { contractType } = this.state
    const { t } = this.props
    return (
      <MenuAppBar>
        <Block header={t('contract_type')} returnLink="/setting">
          <Grid container spacing={3}>
            <Grid item xl={6}>
              <Box mb={3}>
                <Typography variant="h5">
                  {t('basic_informations')}
                </Typography>
                <TextField id="contract-type-name" label={t('name')} fullWidth margin="dense" value={contractType.name} onChange={this.handleChangeName}/>
              </Box>
            </Grid>
            <Grid item xl={6}>
              <Box mb={3}>
                <Typography variant="h5">
                  {t('benefits_settings')}
                </Typography>
                <Typography variant="body1" align="justify" paragraph>
                  {t('how_to')}
                </Typography>
                <ResponsiveTable size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('monthly_hours_rate')}</TableCell>
                      <TableCell align="right">{t('part_from_benefits')}</TableCell>
                      <TableCell align="right" size="small" style={{width: '50px'}}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contractType.benefitSettings.map((setting, index) => (
                      <TableRow key={index}>
                        <TableCell style={{minWidth: '300px'}}>
                          <Slider
                            value={[
                              setting.hours.from ? setting.hours.from : this.minimalHours,
                              setting.hours.to ? setting.hours.to : this.maximalHours
                            ]}
                            onChange={(e, v) => this.handleChangeSlider(index, v)}
                            valueLabelDisplay="auto"
                            min={this.minimalHours}
                            max={this.maximalHours}
                            step={5}
                            marks={this.marks()}
                          />
                        </TableCell>
                        <TableCell align="right" style={{width: '260px'}}>
                          <Input
                            label={t('amount')}
                            margin="dense"
                            fullWidth
                            value={setting.amount}
                            onChange={(e) => this.handleChangeValue(index, e.target.value)}
                            endAdornment={<InputAdornment position="end">Kč</InputAdornment>}/>
                        </TableCell>
                        <TableCell align="right" size="small" style={{width: '40px', padding: '0 10px'}}>
                          <IconButton onClick={() => this.handleDeleteSetting(index)}>
                            <DeleteIcon fontSize="inherit"/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="add">
                      <TableCell>
                        <Slider
                          disabled
                          value={[this.settingDefault.hours.from, this.settingDefault.hours.to]}
                          min={this.minimalHours}
                          max={this.maximalHours}
                          step={5}
                          marks={this.marks()}
                          classes={{
                            mark: this.props.classes.sliderDisabledMark,
                            markLabel: this.props.classes.sliderDisabledMark,
                            markLabelActive: this.props.classes.sliderDisabledMark,
                            rail: this.props.classes.sliderDisabledMark,
                            track: this.props.classes.sliderDisabledMark,
                            thumb: this.props.classes.sliderDisabledMark
                          }}
                        />
                      </TableCell>
                      <TableCell align="right" style={{width: '260px'}}>
                        <Input
                          disabled
                          label={t('amount')}
                          margin="dense"
                          fullWidth
                          value={this.settingDefault.amount}
                          endAdornment={<InputAdornment position="end">Kč</InputAdornment>}/>
                      </TableCell>
                      <TableCell align="right" size="small" style={{width: '40px'}}>
                        <Fab color="primary" size="small" onClick={this.handleAddSetting}>
                          <AddIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </ResponsiveTable>
              </Box>
            </Grid>
            <Grid item xl={12}>
              <Grid container justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={this.handleSave}>
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Block>
      </MenuAppBar>
    )
  }
}

export default withSnackbar(withStyles(styles)(withTranslation('settings')(ContractType)))
