import React, {useCallback, useEffect, useState} from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import {request} from '../../services/api'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Block from '../../components/Block'
import { ProjectStateTranslation } from '../../constants/ProjectStateTranslation'
import IconButton from '@material-ui/core/IconButton'
import {history} from '../../config/history'
import EditIcon from '@material-ui/icons/Edit'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {makeStyles, Switch} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import clsx from 'clsx'
import {lighten} from '@material-ui/core/styles'
import ResponsiveTable from '../../components/ResponsiveTable'
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  smallColumn: {
    width: '100px'
  },
  highlightRow: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.95)
  },
  highlightCell: {
    color: lighten(theme.palette.secondary.main, 0.6)
  },
}))

export interface ExistingProjectInterface {
  name: string
  shortcut: string
  customer: string
  description: string
  state: string
  id: number
}

const ProjectList = () => {
  const classes = useStyles()
  const { t } = useTranslation('project')
  const [projects, setProjects] = useState([] as ExistingProjectInterface[])
  const [all, setAll] = useState(false);

  const refresh = useCallback((checked = false) => {
    request('GET', '/project').then(data => {
      if (checked) {
        request('GET', '/project/archived').then(data => setProjects(prevState => prevState.concat(data)))
      }
      else {
        setProjects(data)
      }
    })
  }, [])

  useEffect(() => {
    refresh()

    return () => {
    //TODO cancel pending request
    };
  }, [refresh])


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.checked !== all) {
      setAll(e.target.checked)
      refresh(e.target.checked)
    }
  }

  return (
    <MenuAppBar>
      <Block header="Projekty">
        <>
          <ResponsiveTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell align="right">{t('shorthand')}</TableCell>
                <TableCell align="right">{t('customer')}</TableCell>
                <TableCell align="right">{t('description')}</TableCell>
                <TableCell align="right">{t('project_state')}</TableCell>
                <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects && projects.map(row => (
                <TableRow key={row.id} className={clsx(row.state === 'ARCHIVED' && classes.highlightRow)}>
                  <TableCell component="th" scope="row" className={clsx(row.state === 'ARCHIVED' && classes.highlightCell)}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right" className={clsx(row.state === 'ARCHIVED' && classes.highlightCell)}>{row.shortcut}</TableCell>
                  <TableCell align="right" className={clsx(row.state === 'ARCHIVED' && classes.highlightCell)}>{row.customer}</TableCell>
                  <TableCell align="right" className={clsx(row.state === 'ARCHIVED' && classes.highlightCell)}>{row.description}</TableCell>
                  <TableCell align="right" className={clsx(row.state === 'ARCHIVED' && classes.highlightCell)}>{ProjectStateTranslation(row.state)}</TableCell>
                  <TableCell align="center" className={clsx(row.state === 'ARCHIVED' && classes.highlightCell, classes.smallColumn)}>
                    <IconButton onClick={() => history.push('/project/'+row.id)}>
                      <EditIcon fontSize="small"/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ResponsiveTable>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <FormControlLabel
                    control={<Switch
                      checked={all}
                      onChange={(e) => handleChange(e)}
                      value="showAll"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}/>}
                    label={t('show_archived')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={() => history.push('/project/create')}>
                    {t('add_new')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      </Block>
    </MenuAppBar>
  )
}

export default ProjectList
