import React, {Component} from 'react'
import {request} from '../../services/api'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import DeleteIcon from '@material-ui/icons/Delete'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {withSnackbar} from 'notistack'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
})

class BenefitTemplate extends Component {
  templateDefault = {
    name: '',
    description: '',
    amount: {
      budget: 0,
      salary: 0
    },
    monthly: false
  }

  constructor(props) {
    super(props)
    this.state = {templates: [], template: this.templateDefault, dialogOpen: false}
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.refresh = this.refresh.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  handleEdit(row) {
    this.setState({
      template: Object.assign({}, row),  //We have to create a copy of a data
      dialogOpen: true
    })
  }

  handleChange(e) {
    let elementId = e.target.id
    let elements = elementId.split('-')
    if (elements[0] === 'template') {
      let template = Object.assign({}, this.state.template)
      if (elements.length === 2) {
        template[elements[1]] = e.target.value
        this.setState({template: template})
        return
      }
      else if (elements.length === 3) {
        if (elements[1] === 'amount') {
          let amount = Object.assign({}, template.amount)
          amount[elements[2]] = e.target.value
          template.amount = amount
          this.setState({template: template})
          return
        }
      }
    }
    this.props.enqueueSnackbar(this.props.t('error_when_changing_data'), {variant: 'error'})
  }

  handleMonthlySwitch(value) {
    if (value !== this.state.template.monthly) {
      let template = Object.assign({}, this.state.template)
      template.monthly = value
      this.setState({template: template})
    }
  }

  handleClose() {
    this.setState({
      dialogOpen: false
    })
  }

  handleCreate() {
    this.setState({
      template: this.templateDefault,
      dialogOpen: true
    })
  }

  handleDelete(row) {
    request('DELETE', '/benefit-template/'+row.id)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('benefit_template_removed'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  refresh() {
    request('GET', '/benefit-template').then(data => this.setState({templates: data}))
  }

  handleSave() {
    let method = (this.state.template.id ? 'PUT' : 'POST')
    let url = '/benefit-template/'+(this.state.template.id ?  this.state.template.id : '')
    request(method, url, this.state.template)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  render() {
    const { classes, t } = this.props

    return (
      <>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('amount_from_benefits')}</TableCell>
              <TableCell>{t('amount_from_salary')}</TableCell>
              <TableCell>{t('repeating_monthly')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.templates.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.amount.budget}</TableCell>
                <TableCell>{row.amount.salary}</TableCell>
                <TableCell>{row.monthly ? t('yes') : t('no')}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)} size="small">
                    <EditIcon fontSize="inherit"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)} size="small">
                    <DeleteIcon fontSize="inherit"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        <Box mt={2}>
          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={this.handleCreate}>
                {t('add_template')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>{t('template_edit')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="template-name"
              label={t('name')}
              onChange={this.handleChange}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={this.state.template.name}
              fullWidth
            />
            <TextField
              margin="dense"
              id="template-description"
              label={t('description')}
              onChange={this.handleChange}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={this.state.template.description}
              fullWidth
            />
            <TextField
              margin="dense"
              id="template-amount-budget"
              label={t('amount_from_benefits')}
              onChange={e => {
                let template = Object.assign({}, this.state.template)
                let amount = Object.assign({}, this.state.template.amount)
                amount.budget = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                template.amount = amount
                this.setState({template})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={this.state.template.amount.budget}
              fullWidth
            />
            <TextField
              margin="dense"
              id="template-amount-salary"
              label={t('amount_from_salary')}
              onChange={e => {
                let template = Object.assign({}, this.state.template)
                let amount = Object.assign({}, this.state.template.amount)
                amount.salary = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                template.amount = amount
                this.setState({template})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={this.state.template.amount.salary}
              fullWidth
            />
            <FormControlLabel
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              control={<Switch
                checked={this.state.template.monthly}
                onChange={(e) => this.handleMonthlySwitch(e.target.checked)}
                value="template-monthly"
                color="primary"/>}
              label={t('repeating_monthly')}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withSnackbar(withStyles(styles)(withTranslation('settings')(BenefitTemplate)))
