import React, {Component} from 'react'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons'
import {addDays ,addMonths, addYears} from 'date-fns'
import {formatDate, formatYear, formatYearMonth} from '../utils/datetime'
import _ from 'lodash'
import {createStyles} from '@material-ui/core'

type Classes = 'dateSelectContainer' | 'arrows' | 'formattedDate'
const styles = () => createStyles({
  dateSelectContainer: {
    whiteSpace: 'nowrap',
    color: 'white',
    backgroundColor: '#EE333E',
    width: 'fit-content',
    display: 'inline-flex',
    borderRadius: '4px',
    padding: '2px',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 190,
    minHeight: 30
  },
  arrows: {
    padding: 0,
    color: 'white'
  },
  formattedDate: {
    padding: '0 10px'
  }
});

type Variant = 'year' | 'yearMonth' | undefined

type DateSelectProps = {
  variant: Variant,
  // eslint-disable-next-line no-unused-vars
  onChange: (date: Date) => void,
  date: Date,
}

type DateSelectStates = {
  variant: Variant,
  date: Date
}

class DateSelect extends Component<DateSelectProps & WithStyles<Classes>, DateSelectStates> {
  constructor(props: DateSelectProps & WithStyles<Classes>) {
    super(props);
    this.state = {
      variant: this.props.variant,
      date: this.props.date
    };
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
  }

  handleNext() {
    const { onChange } = this.props;
    let { date } = _.clone(this.state);

    date = this.modifyDate(date, 1);
    this.setState({date});

    if (onChange) {
      onChange(date);
    }
  }

  handlePrevious() {
    const { onChange } = this.props;
    let { date } = _.clone(this.state);

    date = this.modifyDate(date, -1);
    this.setState({date});

    if (onChange) {
      onChange(date);
    }
  }

  modifyDate(date: Date, amount: number) {
    const { variant } = this.state;
    switch (variant) {
    case 'year':
      return addYears(date, amount);
    case 'yearMonth':
      return addMonths(date, amount);
    default:
      return addDays(date, amount);
    }
  }

  getFormattedDate() {
    const { variant, date } = this.state;
    switch (variant) {
    case 'year':
      return formatYear(date);
    case 'yearMonth':
      return formatYearMonth(date);
    default:
      return formatDate(date);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.dateSelectContainer}>
        <IconButton onClick={this.handlePrevious} className={classes.arrows}>
          <KeyboardArrowLeft />
        </IconButton>
        <span className={classes.formattedDate}>
          {this.getFormattedDate()}
        </span>
        <IconButton onClick={this.handleNext} className={classes.arrows}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }
}

export default withStyles(styles)(DateSelect)
