import React, {Component} from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import {request} from '../../services/api'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Block from '../../components/Block'
import withStyles from '@material-ui/core/styles/withStyles'
import Switch from '@material-ui/core/Switch'
import { UserRoleTranslation } from '../../constants/UserRoleTranslation'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {history} from '../../config/history'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {lighten} from '@material-ui/core/styles'
import clsx from 'clsx'
import {withSnackbar} from 'notistack'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = (theme) => ({
  smallColumn: {
    width: '100px'
  },
  highlightRow: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.95)
  },
  highlightCell: {
    color: lighten(theme.palette.secondary.main, 0.6)
  },
})

class UserList extends Component {
  constructor(props) {
    super(props)
    this.state = {users: undefined}
    this.handleActivate = this.handleActivate.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    request('GET', '/user').then(data => this.setState({users: data}))
  }

  handleActivate(row, checked) {
    if (row.active === checked) {
      return //Nothing changed
    }
    let clone = Object.assign({}, row)
    clone.active = checked

    request('PUT', '/user/'+ clone.id, clone)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  render() {
    const { classes, t } = this.props

    return (
      <MenuAppBar>
        <Block header={t('users')}>
          <ResponsiveTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('first_name')}</TableCell>
                <TableCell>{t('last_name')}</TableCell>
                <TableCell>{t('nickname')}</TableCell>
                <TableCell>{t('email')}</TableCell>
                <TableCell>{t('position')}</TableCell>
                <TableCell>{t('role')}</TableCell>
                <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
                <TableCell align="center" className={classes.smallColumn}>{t('active')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.users && this.state.users.map(row => (
                <TableRow key={row.id} className={clsx(!row.active && classes.highlightRow)}>
                  <TableCell className={clsx(!row.active && classes.highlightCell)}>{row.firstName}</TableCell>
                  <TableCell className={clsx(!row.active && classes.highlightCell)}>{row.lastName}</TableCell>
                  <TableCell className={clsx(!row.active && classes.highlightCell)}>{row.nickName}</TableCell>
                  <TableCell className={clsx(!row.active && classes.highlightCell)}>{row.email}</TableCell>
                  <TableCell className={clsx(!row.active && classes.highlightCell)}>{row.position}</TableCell>
                  <TableCell className={clsx(!row.active && classes.highlightCell)}>{UserRoleTranslation(row.role)}</TableCell>
                  <TableCell align="center" className={classes.smallColumn}>
                    <IconButton onClick={() => history.push('/user/'+row.id)}>
                      <EditIcon fontSize="small"/>
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" className={classes.smallColumn}>
                    <Switch
                      checked={row.active}
                      onChange={(e) => this.handleActivate(row, e.target.checked)}
                      value="test"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ResponsiveTable>
          <Box mt={2}>
            <Grid item xl={12}>
              <Grid container justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={() => history.push('/user/create')}>
                  {t('add_user')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Block>
      </MenuAppBar>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserList)))
