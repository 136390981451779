import jwt_decode from 'jwt-decode'
import {history} from '../config/history'
import {UserInfo} from '../utils/userInfo';
import { request } from './api';
// import React from 'react'
// import { Redirect } from 'react-router-dom';

const storageKey = 'token'

interface JwtPayload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  user: UserInfo
}

export const authentication = {
  getUser,
  login,
  logout,
  getAuthenticationToken
}

function getUser(required = true) {

  const token = localStorage.getItem(storageKey)
  if (token === null) {
    if (required) {
      // TODO fix history.push --- it doesnt stop loading of other components and resulting in app break
      history.push('/auth')
      // TODO Redirect cannot be used here because of return types
      // return <Redirect to="/auth" />
      // TODO Alternative is to check, if user exists in every parent component that uses it
    }
    return undefined
  }
  
  const content = jwt_decode<JwtPayload>(token)
  const timestamp = (+ new Date())/1000
  
  if (required && content.exp <= timestamp) {
    request('POST', '/auth/refresh').then(data => {
      if (data && data.token) {
        login(data.token)
        window.location.reload()
      }
      else {
        history.push('/auth')
      }
    })
    logout()
    
    // return <Redirect to="/auth" />
    //return undefined
  }
  const user = content.user
  user.isAdmin = () => {
    return user.role === 'ADMIN'
  }
  user.isProjectManager = () => {
    return user.role === 'PROJECT_MANAGER'
  }
  user.isBackOffice = () => {
    return user.role === 'BACK_OFFICE'
  }
  user.isEmployee = () => {
    return user.role === 'EMPLOYEE'
  }
  user.fullName = user.firstName + ' ' + user.lastName
  return user
}

function getAuthenticationToken() {
  const token = localStorage.getItem(storageKey)
  if (token === null) {
    return undefined
  }
  return token
}

function login(token: string) {
  localStorage.setItem(storageKey, token)
}

function logout() {
  localStorage.removeItem(storageKey)
}
