import React, {Component} from 'react'
import {request} from '../../services/api'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import DeleteIcon from '@material-ui/icons/Delete'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {withSnackbar} from 'notistack'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
})

class Skill extends Component {
  skillDefault = {
    name: ''
  }

  constructor(props) {
    super(props)
    this.state = {skills: [], skill: this.skillDefault, dialogOpen: false}
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.refresh = this.refresh.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  handleEdit(row) {
    this.setState({
      skill: Object.assign({}, row),  //We have to create a copy of a data
      dialogOpen: true
    })
  }

  handleChange(e) {
    let skill = this.state.skill
    skill.name = e.target.value
    this.setState({
      skill: skill
    })
  }

  handleClose() {
    this.setState({
      dialogOpen: false
    })
  }

  handleCreate() {
    this.setState({
      skill: this.skillDefault,
      dialogOpen: true
    })
  }

  handleDelete(row) {
    request('DELETE', '/skill/'+row.id)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('skill_removed_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  refresh() {
    request('GET', '/skill').then(data => this.setState({skills: data}))
  }

  handleSave() {
    let method = (this.state.skill.id ? 'PUT' : 'POST')
    let url = '/skill/'+(this.state.skill.id ?  this.state.skill.id : '')
    request(method, url, this.state.skill)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  render() {
    const { classes, t } = this.props

    return (
      <>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('skill')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.skills.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)} size="small">
                    <EditIcon fontSize="inherit"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)} size="small">
                    <DeleteIcon fontSize="inherit"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        <Box mt={2}>
          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={this.handleCreate}>
                {t('add_skill')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>{t('skill_edit')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('type_new_name_of_skill')}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="skill-name"
              onChange={this.handleChange}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={this.state.skill.name}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('settings')(withSnackbar(withStyles(styles)(Skill)))
