import React, {useEffect} from 'react'
import Button from '@material-ui/core/Button'
import { history } from '../../config/history'
import {authentication} from '../../services/authentication'
// import MenuAppBar from '../../components/MenuAppBar'
import Container from '@material-ui/core/Container'
import {Card, makeStyles} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import PersonIcon from '@material-ui/icons/Person'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 600,
    margin: '0 auto'
  },
  root: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '70vh'
  }
}))

const Home = () => {
  const classes = useStyles()
  const { t } = useTranslation('sections', { keyPrefix: 'home' })

  useEffect(() => {
    if (authentication.getUser(false)) {
      history.push('/dashboard')
    }
    // else {
    //   history.push('/auth')
    // }
  }, [])

  return (
  // <MenuAppBar>
    <Container className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {t('header')}
          </Typography>
          <Typography variant="body1">
            {t('info')}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="primary"
            startIcon={<PersonIcon />}
            onClick={() => history.push('/auth')}>
            {t('login')}
          </Button>
        </CardActions>
      </Card>
    </Container>
  // </MenuAppBar>
  )
}

export default Home
