import React, {Component} from 'react'
import Block from '../../components/Block';
import { Box, Typography } from '@material-ui/core'

import withStyles from '@material-ui/core/styles/withStyles'

import ResponsiveTable from '../../components/ResponsiveTable'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import { formatISOYearMonth } from '../../utils/datetime';
import {withTranslation} from 'react-i18next';


const Row = withStyles(() => ({
  root: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: '#efefef',
    },
  },
}))(TableRow);

const styles = () => ({
  smallColumn: {
    width: '100px'
  },

  projectBox: {
    padding: '12px 4px',
    width: '100%'
  }
})

class UserTable extends Component {

  formatRecord(records, projectId) {
    const { date } = this.props
    const yearMonth = formatISOYearMonth(date)
    let record = { total: 0, approved: 0 }
    // eslint-disable-next-line no-prototype-builtins
    if (records.hasOwnProperty(yearMonth)) {
      // eslint-disable-next-line no-prototype-builtins
      if (projectId && records[yearMonth].projects.hasOwnProperty(projectId))
        record = records[yearMonth].projects[projectId]
      else if (!projectId)
        record = records[yearMonth]
    }
    return `${record.approved} / ${record.total}`
  }

  render() {
    const { classes, projects, onSelect, t } = this.props
    return (
      <Box className={classes.projectBox}>
        {projects.map(({project, users}) => <Block key={`project-${project.id}`} header={project.shortcut}>
          <Typography color="secondary" variant="caption" gutterBottom>{project.name}</Typography>
          <Box p={2}>
            <ResponsiveTable size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('role')}</TableCell>
                  <TableCell>{t('approved_in_project')}</TableCell>
                  <TableCell>{t('approved_totally')}</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                  <Row key={`${project.id}-${user.id}`} onClick={() => onSelect(user)}>
                    <TableCell>{user.firstName} {user.lastName}</TableCell>
                    <TableCell>{user.position}</TableCell>
                    <TableCell>{this.formatRecord(user.records, project.id)}</TableCell>
                    <TableCell>{this.formatRecord(user.records)}</TableCell>
                  </Row>
                ))}
              </TableBody>
            </ResponsiveTable>
          </Box>
        </Block>)}
      </Box>
    )
  }
}

export default withStyles(styles)(withTranslation('sections', { keyPrefix: 'review' })(UserTable))
