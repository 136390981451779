import {CLOSE_DRAWER, OPEN_DRAWER} from './menu'
import {REQUEST_FINISHED, REQUEST_STARTED} from './requests'

export function openDrawer() {
  return { type: OPEN_DRAWER }
}

export function closeDrawer() {
  return { type: CLOSE_DRAWER }
}

export function startRequest(id) {
  return { type: REQUEST_STARTED, id }
}

export function finishRequest(id) {
  return { type: REQUEST_FINISHED, id }
}
