import React, {useEffect, useState} from 'react'
import {request} from '../../services/api'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import {history} from '../../config/history'
import {projectStates, projectStatesObject} from '../../constants/ProjectStateTranslation'
import Button from '@material-ui/core/Button'
import {useSnackbar} from 'notistack'
import _ from 'lodash'
import {useTranslation} from 'react-i18next';

interface ProjectBaseInfoProps {
  projectId?: string
}

export interface ProjectInterface {
  name: string
  shortcut: string
  customer: string
  description: string
  state: string
  id?: number
}

interface NewProjectInterface {
  name: string
  shortcut: string
  customer: string
  description: string
  state: string
}

const ProjectBaseInfo = ({projectId}: ProjectBaseInfoProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('project')
  const defaultProject: ProjectInterface = {
    name: '',
    shortcut: '',
    customer: '',
    description: '',
    state: 'PREPARING'
  }
  const [project, setProject] = useState(defaultProject);

  useEffect(() => {
    const refresh = () => {
      if (projectId) {
        request('GET', '/project/' + projectId).then(data => setProject(data))
      }
    }

    refresh()
    return () => {
    //TODO cancel pending request
    };
  }, [projectId]);


  const handleSave = () => {
    const create = (typeof project.id === 'undefined')
    const method = (create ? 'POST' : 'PUT')
    const endpoint = (create ? '/project' : '/project/'+ projectId)
    const successMessage = (create ? t('project_saved') : t('edited_successfully'))
    const errorPartMessage = (create ? t('creating') : t('saving'))
    request(method, endpoint, project)
      .then((data) => {
        enqueueSnackbar(successMessage,{variant: 'success'})
        if (create) {
          history.push('/project/'+data.id)
        }
      })
      .catch((error) => {
        enqueueSnackbar( `${t('error_occurred')}${errorPartMessage}: ${+error.reason}`, {variant: 'error'})
      })
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const elementId = e.target.id
    const elements = elementId.split('-')
    if (elements[0] === 'project') {
      let newProject = _.clone(project)
      if (elements.length === 2) {
        newProject[elements[1] as keyof NewProjectInterface] = e.target.value
        setProject(newProject)
        return
      }
    }
    enqueueSnackbar(t('error_when_changing_data'), {variant: 'error'})
  }


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xl={6}>
          <Box mb={3}>
            <TextField id="project-name" label={t('name')} fullWidth margin="dense" value={project.name} onChange={(e) => handleChange(e)}/>
            <TextField id="project-shortcut" label={t('shorthand')} fullWidth margin="dense" value={project.shortcut} onChange={(e) => handleChange(e)}/>
            <TextField id="project-description" label={t('description')} fullWidth margin="dense" value={project.description} onChange={(e) => handleChange(e)}/>
          </Box>
        </Grid>
        <Grid item xl={6}>
          <Box mb={3}>
            <TextField id="project-customer" label={t('customer')} fullWidth margin="dense" value={project.customer} onChange={(e) => handleChange(e)}/>
            <FormControl fullWidth margin="dense">
              <InputLabel id="project-state-label">{t('project_state')}</InputLabel>
              <Select
                labelId="project-state-label"
                id="project-state"
                value={project.state}
                onChange={(e) => {
                  const newProject = _.clone(project)
                  newProject.state = e.target.value as string
                  setProject(newProject)
                }}
              >
                {Object.keys(projectStates).map(state => (
                  <MenuItem key={'project-state-'+state} value={state}>{projectStates[state as keyof projectStatesObject]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid item xl={12}>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={() => handleSave()}>
            {t('save')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ProjectBaseInfo
