import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import MenuAppBar from '../../components/MenuAppBar'
import {makeStyles, Typography} from '@material-ui/core'
import Block from '../../components/Block'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {isPlanOn, isSameDate} from '../../utils/datetime'
import {endOfWeek, format, formatISO, setDay, startOfWeek} from 'date-fns'
import TableBody from '@material-ui/core/TableBody'
import WorkPlanDay from '../WorkPlan/WorkPlanDay'
import skLocale from 'date-fns/locale/sk'
import {lighten} from '@material-ui/core/styles'
import {authentication} from '../../services/authentication'
import {request} from '../../services/api'
import SpeedDial from '@material-ui/lab/SpeedDial'
import AddRecordDialog from '../../components/AddRecordDialog'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import {Schedule} from '@material-ui/icons'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ResponsiveTable from '../../components/ResponsiveTable'
import {useTranslation} from 'react-i18next'
import csLocale from 'date-fns/locale/cs';


const useStyles = makeStyles((theme) => ({
  tableRoot: {
    userSelect: 'none',
    width: '100%'
  },
  tableBox: {
    width: '100%',
  },
  currentDayHighlight: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.95)
  },
  tooltip: {
    padding: theme.spacing(1)
  },
  cell: {
    padding: '0px',
    verticalAlign: 'top',
    width: '20%'
  },
  row: {
    height: theme.spacing(4)
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  exampleWrapper: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1)
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}))

const Dashboard = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation('sections', { keyPrefix: 'dashboard' })
  const locale = {locale: i18n.language === 'sk' ? skLocale : csLocale}
  const user = authentication.getUser()
  const currentUserId = user && user.id
  const currentDate = new Date()
  const weekStart = startOfWeek(currentDate, locale)
  const weekEnd = endOfWeek(currentDate, locale)
  const weekDays = [1,2,3,4,5].map(i => setDay(weekStart, i, locale))
  const endDate = formatISO(weekEnd, {representation: 'date'})
  const startDate = formatISO(weekStart, {representation: 'date'})

  // const [currentDate, setCurrentDate] = useState(new Date())
  // const [weekStart, setWeekStart] = useState(startOfWeek(currentDate, locale))
  // const [weekEnd, setWeekEnd] = useState(endOfWeek(currentDate, locale))
  // const [weekDays, setWeekDays] = useState([1,2,3,4,5].map(i => setDay(weekStart, i, locale)))
  // const [currentUserId, setCurrentUserId] = useState(authentication.getUser().id)
  const [plans, setPlans] = useState([])
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)


  if(!currentUserId){
    return
  }

  useEffect(() => {
    const controller = new AbortController()
    const fetchData = () => {
      request('GET', `/user/${currentUserId}/record/${startDate}/${endDate}`,  undefined, false, controller.signal)
        .then(data => {
          if(data !== 'cancelled')  setPlans(data)
        })
    }

    fetchData()

    return () => {
      controller.abort()
    }

  }, [currentUserId, endDate, startDate]);

  return (
    <MenuAppBar>
      <Box mb={12} style={{width: '100%'}}>
        <Grid container spacing={1}>
          <Block header="Dashboard">
            <Typography component="p">
              {t('info')}
            </Typography>
          </Block>

          <Block header={t('header') as string} style={{marginBottom: '48px'}}>
            <ResponsiveTable className={classes.tableRoot} size="small">
              <TableHead>
                <TableRow>
                  {weekDays.map((date, index) => (
                    <TableCell
                      key={index}
                      className={clsx(isSameDate(currentDate, date) && classes.currentDayHighlight)}
                      align="center"
                    >
                      <span className={classes.nowrap}>{format(date, 'eee, ', locale)}</span>
                      <span className={classes.nowrap}>{format(date, 'd. M.', locale)}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.row}>
                  {weekDays.map((date, index) => (
                    <TableCell
                      key={index}
                      className={clsx(
                        isSameDate(currentDate, date) && classes.currentDayHighlight,
                        classes.cell
                      )}
                    >
                      <WorkPlanDay
                        plans={plans.filter((plan:any)=> plan.user === currentUserId && isPlanOn(plan, date)
                        )}
                        date={date}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </ResponsiveTable>
          </Block>
        </Grid>
      </Box>

      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="speedDial"
          className={classes.speedDial}
          icon={<SpeedDialIcon/>}
          onClose={() => setSpeedDialOpen(false)}
          onOpen={() => setSpeedDialOpen(true)}
          open={speedDialOpen}
          direction="up"
        >
          <SpeedDialAction
            icon={(<Schedule/>)}
            tooltipTitle={t('add_record')}
            onClick={() => setDialogOpen(true)}
          />
        </SpeedDial>
      </div>

      <AddRecordDialog
        open={dialogOpen}
        userId={currentUserId}
        onSave={() => {
          setDialogOpen(false)
          setSpeedDialOpen(false)
        }}
        onClose={() => {
          setDialogOpen(false)
          setSpeedDialOpen(false)
        }}
      />
    </MenuAppBar>
  )
}

export default Dashboard
