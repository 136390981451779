import config from '../config'
import {authentication} from './authentication'
import {history} from '../config/history'
import { store } from '../reducers'
import {finishRequest, startRequest} from '../reducers/actions'

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE'

// @ts-ignore
const requestFactory = (store) => (method: Methods, endpoint: string, content: any = undefined, blob = false, signal: AbortSignal | null = null) => {
  // const controller = new AbortController()
  const id = method + endpoint + Math.floor(Date.now() / 1000)
  let headers = new Headers()
  let body
  store.dispatch(startRequest(id))

  if (!(content instanceof FormData)) {
    if (content !== undefined) {
      headers.append('Content-Type', 'application/json')
      body = JSON.stringify(content)
    }
  }
  else {
    body = content
  }
  if (authentication.getUser(false)) {
    headers.append('Authorization', 'Bearer ' + authentication.getAuthenticationToken())
  }

  const requestOptions = {
    method: method,
    body: body,
    headers: headers,
    // signal: controller.signal
    signal: signal
  }

  return fetch(`${config.apiUrl}${endpoint}`, requestOptions)
    .then(response => baseResponseHandler(response, blob))
    .catch(e => {
      if(e.name === 'AbortError'){
        return 'cancelled'
      }
      store.dispatch(finishRequest(id))
    })
    .finally(() => store.dispatch(finishRequest(id)))
}

function processStatus(response: Response) {
  if (response.status === 403) {
    //If there is not a token or it is expired
    if (typeof authentication.getUser(false) === 'undefined') {
      history.push('/')
    }
  }
  if ([401, 403, 500].indexOf(response.status) !== -1) {
    //todo
  }
}

function baseResponseHandler(response: Response, blob = false) {
  if (blob) {
    return response.blob().then(data => {
      if (!response.ok) {
        processStatus(response)
        return Promise.reject({reason: response.statusText})
      }
      return data
    })
  }

  return response.text().then((text: string) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      processStatus(response)
      const error = (data && data.message) || response.statusText
      const additionalData = data && data.errors ? ' ( ' + Array.isArray(data.errors) ? data.errors.join(', ') : data.errors + ' )' : ''
      // return Promise.reject({reason: error, additionalText: additionalData})
      return Promise.reject({reason: error + additionalData})
    }
    return data
  })
}

export const request = requestFactory(store)
