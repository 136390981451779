import React, {useState} from 'react'
import {authentication} from '../../services/authentication'
import UserRecords from '../User/UserRecords'
import MenuAppBar from '../../components/MenuAppBar'
import Block from '../../components/Block'
import {formatISOYearMonth} from '../../utils/datetime'
import DateSelect from '../../components/DateSelect'
import {useTranslation} from 'react-i18next';

const TimeSheet = () => {
  const [date, setDate] = useState(new Date())
  const { t } = useTranslation('sections', { keyPrefix: 'time_sheet' })
  const user = authentication.getUser()

  if(!user){
    return
  }

  return (
    <MenuAppBar>
      <Block header={t('header') as string} headerContent={(
        <DateSelect variant="yearMonth" date={date} onChange={d => setDate(d)}/>
      )}>
        {/* @ts-ignore */}
        <UserRecords userId={user.id} month={formatISOYearMonth(date)}/>
      </Block>
    </MenuAppBar>
  )
  // }
}

export default TimeSheet
