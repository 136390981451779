import { createInjectStore } from 'redux-injector'
import {menu} from './menu'
import {requests} from './requests'

let reducersObject = {
  menu,
  requests
}

export let store  = createInjectStore(reducersObject)
