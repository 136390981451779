import {createTheme, lighten} from '@material-ui/core/styles'
import {blue, yellow} from '@material-ui/core/colors'

const primaryColors = {
  main: '#EE333E',
  darkRed: '#CB252F',
  darkestRed: '#A24343',
  lightGray: '#E0E0E0',
  gray: '#8f8f8f',
  blackGray: '#515151',
  orange: '#F9A825',
  blue: '#25ADF9',
  darkGreen: '#0E990B',
  lightGreen: '#11DB86',
}

const buttons = {
  contained: {
    backgroundColor: primaryColors.gray,
    color: 'white',
    '&:disabled': {
      backgroundColor: primaryColors.gray,
    },
    '&:hover': {
      backgroundColor: lighten(primaryColors.gray, 0.2)
    }
  }
}

const theme = createTheme({
  palette: {
    primary: primaryColors,
    secondary: {
      main: '#333333',
    },
  },
  overrides: {
    MuiButton: buttons,
  },
}, {
  workPlan: {
    plan: {
      backgroundColor: lighten('#333333', 0.85)
    },
    vacationApproved: {
      backgroundColor: primaryColors.main
    },
    homeOfficeApproved: {
      backgroundColor: blue[200]
    },
    vacationNonApprove: {
      backgroundColor: yellow[800]
    },
  }
})

export default theme
