import React from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import UserBaseInfo from './UserBaseInfo'
import Block from '../../components/Block'
import {useTranslation} from 'react-i18next';

const UserCreate = () => {
  const { t } = useTranslation('user')

  return (
    <MenuAppBar>
      <Block header={t('new_user') as string}>
        <UserBaseInfo/>
      </Block>
    </MenuAppBar>
  )
}

export default UserCreate
