import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {Provider} from 'react-redux'
import { store } from './reducers'
import {SnackbarProvider} from 'notistack'
import {Close} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import i18next from './i18next'
import {I18nextProvider} from 'react-i18next'


const notistackRef = React.createRef()
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key)
}

// eslint-disable-next-line no-console
console.info(`${process.env.REACT_APP_NAME}, version ${process.env.REACT_APP_VERSION}`)

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider ref={notistackRef} action={(key) => (<IconButton onClick={onClickDismiss(key)}><Close/></IconButton>)} maxSnack={3}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </SnackbarProvider>
  </Provider>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
