import React, {Component} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import {request} from '../../services/api'
import Box from '@material-ui/core/Box'
import {withSnackbar} from 'notistack'
import {Delete, Edit, KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons'
import {formatDate, formatISODate, formatISOYearMonth, formatYearMonth, parseDatetime} from '../../utils/datetime'
import Grid from '@material-ui/core/Grid'
import {addMonths} from 'date-fns'
import {currencyFormat} from '../../utils/currency'
import Button from '@material-ui/core/Button'
import {authentication} from '../../services/authentication'
import _ from 'lodash/lang'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import DialogActions from '@material-ui/core/DialogActions'
import Switch from '@material-ui/core/Switch'
import skLocale from 'date-fns/locale/sk'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';
import csLocale from 'date-fns/locale/cs';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
})

class UserBenefits extends Component {
  benefitDefault = {
    id: undefined,
    name: '',
    description: '',
    date: formatISODate(new Date()),
    amount: {
      budget: 0,
      salary: 0
    },
    monthly: false,
    monthlyUntil: null,
    template: null
  }

  constructor(props, context) {
    super(props, context)
    this.userId = this.props.userId
    this.state = {
      yearMonth: new Date(),
      benefits: [],
      templates: [],
      benefit: this.benefitDefault,
      balance: undefined,
      dialogOpen: false,
      dialogFromTemplateOpen: false,
      template: undefined
    }
    this.refresh = this.refresh.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeUntil = this.handleChangeUntil.bind(this)
    this.handleShowTemplates = this.handleShowTemplates.bind(this)
    this.handleCreateFromTemplate = this.handleCreateFromTemplate.bind(this)
  }

  componentDidMount() {
    this.refresh(this.state.yearMonth)
  }

  refresh(yearMonth) {
    request('GET', '/user/'+this.userId+'/benefit/balance')
      .then(data => this.setState({balance: data.balance}))
    request('GET', '/user/'+this.userId+'/benefit/'+formatISOYearMonth(yearMonth))
      .then(data => this.setState({benefits: data}))
  }

  handleCreate() {
    this.setState({
      dialogOpen: true,
      benefit: _.cloneDeep(this.benefitDefault)
    })
  }

  handleShowTemplates() {
    request('GET', '/benefit-template')
      .then(data => this.setState({
        dialogFromTemplateOpen: true,
        templates: data
      }))
  }

  handleEdit(benefit) {
    this.setState({
      dialogOpen: true,
      benefit: _.cloneDeep(benefit)
    })
  }

  handleCreateFromTemplate() {
    const t = this.state.template
    if (t) {
      let b = _.cloneDeep(this.benefitDefault)
      b.name = t.name
      b.description = t.description
      b.amount.budget = t.amount.budget
      b.amount.salary = t.amount.salary
      b.monthly = t.monthly
      b.monthlyUntil = b.monthly ? formatISOYearMonth(new Date()) : null
      b.template = t
      this.setState({
        dialogFromTemplateOpen: false,
        dialogOpen: true,
        template: undefined,
        benefit: b
      })
    }
    else {
      this.props.enqueueSnackbar(this.props.t('please_select_template'), {variant: 'error'})
    }
  }

  handleDelete(benefit) {
    request('DELETE', '/user/'+this.userId+'/benefit/'+benefit.id)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t(''), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh(this.state.yearMonth)
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  handleSave() {
    const {benefit} = this.state
    let method = (benefit.id ? 'PUT' : 'POST')
    let url = '/user/'+this.userId+'/benefit/'+(benefit.id ?  benefit.id : '')
    request(method, url, benefit)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh(this.state.yearMonth)
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  handleClose() {
    this.setState({
      dialogOpen: false,
      dialogFromTemplateOpen: false
    })
  }

  handleChangeDate(date) {
    try {
      let benefit = _.clone(this.state.benefit)
      benefit.date = date === null ? null : date.toISOString().substring(0,10)
      this.setState({benefit})
    }
    catch (e) {
      //ok
    }
  }

  handleChangeUntil(date) {
    try {
      let benefit = _.clone(this.state.benefit)
      benefit.monthlyUntil = date === null ? null : formatISOYearMonth(date)
      this.setState({benefit})
    }
    catch (e) {
      //ok
    }
  }

  render() {
    const { classes, t, i18n } = this.props
    const { benefits, yearMonth, balance, benefit, templates } = this.state
    const user = authentication.getUser()
    const locale = i18n.language === 'sk' ? skLocale : csLocale

    return (
      <>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box component="span" className={classes.nowrap} mb={2}>
              <IconButton onClick={() => {
                let yearMonth = addMonths(this.state.yearMonth, -1)
                this.setState({yearMonth: yearMonth})
                this.refresh(yearMonth)
              }}>
                <KeyboardArrowLeft/>
              </IconButton>
              {formatYearMonth(yearMonth)}
              <IconButton onClick={() => {
                let yearMonth = addMonths(this.state.yearMonth, 1)
                this.setState({yearMonth: yearMonth})
                this.refresh(yearMonth)
              }}>
                <KeyboardArrowRight/>
              </IconButton>
            </Box>
          </Grid>
          <Grid item>
            {t('actual_benefits_state')} {currencyFormat(balance)}
          </Grid>
        </Grid>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('repeating_monthly')}</TableCell>
              <TableCell>{t('repeating_until')}</TableCell>
              <TableCell align="right">{t('part_from_benefits')}</TableCell>
              <TableCell align="right">{t('part_from_payout')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {benefits.map(row => (
              <TableRow key={row.id}>
                <TableCell>{formatDate(row.date)}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.monthly ? t('yes') : t('no')}</TableCell>
                <TableCell>{row.monthlyUntil ? formatYearMonth(row.monthlyUntil) : (row.monthly ? t('end_not_defined') : '')}</TableCell>
                <TableCell align="right">{currencyFormat(row.amount.budget)}</TableCell>
                <TableCell align="right">{currencyFormat(row.amount.salary)}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)}>
                    <Edit fontSize="small"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)}>
                    <Delete fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        {user.isAdmin() && (
          <Box mt={2}>
            <Grid item xl={12}>
              <Grid container justifyContent="flex-end">
                <Box>
                  <Button variant="contained" color="primary" onClick={this.handleCreate}>
                    {t('add_benefit')}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button variant="contained" color="primary" onClick={this.handleShowTemplates}>
                    {t('add_benefit_from_template')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Dialog open={this.state.dialogFromTemplateOpen} onClose={this.handleClose}>
          <DialogTitle>{t('benefit_from_template')}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="dense">
              <InputLabel id="template-label">{t('template')}</InputLabel>
              <Select
                autoFocus
                labelId="template-label"
                id="template"
                value={this.state.template ? this.state.template.id : ''}
                onChange={(e) => {
                  this.setState({template: templates.filter(t => t.id === parseInt(e.target.value))[0]})
                }}
              >
                {templates.map(t => (
                  <MenuItem key={'template-'+t.id} value={t.id}>{t.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button color="primary" onClick={this.handleCreateFromTemplate}>
              {t('create')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>Benefit</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="benefit-name"
              label={t('name')}
              onChange={e => {
                let b = _.clone(benefit)
                b.name = e.target.value
                this.setState({benefit: b})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={benefit.name}
              fullWidth
            />
            <TextField
              margin="dense"
              id="benefit-description"
              label={t('description')}
              onChange={e => {
                let b = _.clone(benefit)
                b.description = e.target.value
                this.setState({benefit: b})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={benefit.description}
              fullWidth
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              <KeyboardDatePicker
                fullWidth
                margin="dense"
                id="benefit-date"
                label={t('date')}
                format="yyyy-MM-dd"
                value={benefit.date}
                onChange={this.handleChangeDate}
                autoOk
              />
            </MuiPickersUtilsProvider>
            <TextField
              margin="dense"
              id="benefit-amount-benefit"
              label={t('part_from_benefits')}
              onChange={e => {
                let b = _.cloneDeep(benefit)
                b.amount.budget = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({benefit: b})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={benefit.amount.budget}
              fullWidth
            />
            <TextField
              margin="dense"
              id="benefit-amount-salary"
              label={t('part_from_payout')}
              onChange={e => {
                let b = _.cloneDeep(benefit)
                b.amount.salary = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({benefit: b})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={benefit.amount.salary}
              fullWidth
            />
            <Grid container spacing={2} style={{marginTop: '8px'}}>
              <Grid item xl={3}>
                {t('repeat_monthly')}
                <Switch
                  checked={benefit.monthly}
                  onChange={(e) => {
                    if (e.target.checked !== benefit.monthly) {
                      let b = _.cloneDeep(benefit)
                      b.monthly = e.target.checked
                      b.monthlyUntil = b.monthly ? formatISOYearMonth(new Date()) : null
                      this.setState({benefit: b})
                    }
                  }}
                  value="monthly"
                  color="primary"
                />
              </Grid>
              {benefit.monthly && (
                <>
                  <Grid item xl={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                      <DatePicker
                        fullWidth
                        variant="inline"
                        openTo="year"
                        views={['year', 'month']}
                        label={t('repeat_until')}
                        value={benefit.monthlyUntil ? parseDatetime(benefit.monthlyUntil+'-01') : null}
                        onChange={this.handleChangeUntil}
                        autoOk
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xl={3} style={{marginTop: '8px'}}>
                    <Button variant="outlined" onClick={() => {
                      let b = _.cloneDeep(benefit)
                      b.monthlyUntil = null
                      this.setState({benefit: b})
                    }}>
                      {t('until_undefined')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserBenefits)))
