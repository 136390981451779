import React, {useState} from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import {authentication} from '../services/authentication'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import {AccountCircle, ChevronLeft, ExitToApp, AttachMoney, Schedule, AssignmentTurnedIn} from '@material-ui/icons'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AssignmentIcon from '@material-ui/icons/Assignment'
import SettingsIcon from '@material-ui/icons/Settings'
import PeopleIcon from '@material-ui/icons/People'
import MenuIcon from '@material-ui/icons/Menu'
import EventIcon from '@material-ui/icons/Event'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {closeDrawer, openDrawer} from '../reducers/actions'
import {useDispatch, useSelector} from 'react-redux'
import MenuElement from './MenuElement'
import CircularProgress from '@material-ui/core/CircularProgress'
import Badge from '@material-ui/core/Badge'
import Grow from '@material-ui/core/Grow'
import Box from '@material-ui/core/Box'
import logo from './logo.svg'
import {useTranslation} from 'react-i18next';
import {Link, makeStyles} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {UserRoleTranslation} from '../constants/UserRoleTranslation';

const drawerWidth = 200
const maxAppWidth = 1440

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: maxAppWidth,
    margin: '0 auto',
    position: 'relative',
    backgroundColor: 'white',
    minHeight: '100vh'
  },
  title: {
    paddingLeft: theme.spacing(2),
    color: 'white',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  spacer: {
    flexGrow: 1
  },
  logo: {
    verticalAlign: 'middle'
  },
  progressBar: {
    padding: '8px 14px',
    display: 'inlineBlock'
  },
  badge: {
    backgroundColor: 'rgba(255,255,255,0.9)',
    color: '#666'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer - 1,
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  paper: {
    position: 'absolute'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // backgroundColor: theme.palette.primary.darkRed,
    ...theme.mixins.toolbar
  },
  toolBarTop: {
    padding: theme.spacing(0, 1.5, 0, 0.5),
    maxWidth: maxAppWidth,
    margin: '0 auto',
    width: '100%',
    transition: theme.transitions.create(['width', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appToolbarShift: {
    paddingLeft: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
  },
  contentOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
  },
  userName: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  }
}))

interface MenuAppBarProps {
  children: React.ReactElement | React.ReactElement[]
}

const MenuAppBar = ({children}: MenuAppBarProps) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation('sections', { keyPrefix: 'menu_app_bar' })
  const [open, setOpen] = useState(false)
  const [auth] = useState(authentication.getUser())
  const [menuAnchor, setMenuAnchor] = useState(null)
  // const [tab, setTab] = useState(0)
  const handleDrawerOpen = () => dispatch(openDrawer())
  const handleDrawerClose = () => dispatch(closeDrawer())
  // @ts-ignore
  const drawerOpen = useSelector(state => state.menu)
  // @ts-ignore
  const requests = useSelector(state => state.requests)


  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // @ts-ignore
    setMenuAnchor(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    authentication.logout()
    history.push('/')
  }


  return (
    <div className={classes.root}>
      <AppBar position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar className={clsx(classes.toolBarTop, {[classes.appToolbarShift]: drawerOpen})}>
          {auth && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleDrawerOpen()}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: drawerOpen,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link component={RouterLink} to="/dashboard" variant="h6" className={classes.title}>
              Som.
            <img src={logo} height="30" alt="inQool logo" className={classes.logo}/>
              .cz
          </Link>
          <div className={classes.spacer}></div>
          {auth && (
            <>
              <div>
                {requests.length > 0 && (
                  <span className={classes.progressBar}>
                    <Grow in={true} timeout={1000}>
                      <Badge
                        badgeContent={requests.length}
                        max={9} color="primary"
                        classes={{colorPrimary: classes.badge}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        overlap="rectangular">
                        <CircularProgress size={25} color="inherit"/>
                      </Badge>
                    </Grow>
                  </span>
                )}
                <Box component="span" style={{display: 'inline-block'}}>
                  <Box  display={{ xs: 'none', sm: 'block' }}>
                    <Button onClick={(event) => handleMenu(event)} color="inherit" endIcon={<AccountCircle />}>
                        ({UserRoleTranslation(auth.role)}) {auth.fullName}
                    </Button>
                  </Box>
                </Box>
                <Box component="span" style={{display: 'inline-block'}}>
                  <Box display={{ xs: 'block', sm: 'none' }} my={1}>
                    <IconButton onClick={(event) => handleMenu(event)} color="inherit">
                      <AccountCircle />
                    </IconButton>
                  </Box>
                </Box>
                <Menu
                  id="menu-appbar"
                  anchorEl={menuAnchor}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={() => handleClose()}
                >
                  <MenuItem onClick={() => handleLogout()}>
                    <ListItemIcon><ExitToApp /></ListItemIcon>
                    {t('logout')}
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {auth && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx(classes.paper,{
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
          open={drawerOpen}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={() => handleDrawerClose()}>
              <ChevronLeft/>
            </IconButton>
          </div>
          <Divider />
          <List>
            <MenuElement icon={(<DashboardIcon/>)} text="Dashboard" link="/dashboard"/>
            <MenuElement icon={(<EventIcon/>)} text="Disco" link="/work-plan"/>
            <MenuElement icon={(<Schedule/>)} text="Výkaz" link="/time-sheet"/>
            <MenuElement icon={(<AttachMoney/>)} text="Mzda a benefity" link="/report"/>
          </List>
          {(auth.isAdmin() || auth.isProjectManager()) &&
            <>
              <Divider />
              <List>
                <MenuElement icon={(<AssignmentTurnedIn/>)} text={t('approving')} link="/review-time-sheet"/>
                <MenuElement icon={(<AssignmentIcon/>)} text="Projekty" link="/project"/>
              </List>
            </>
          }
          {auth.isAdmin() &&
            <>
              <Divider />
              <List>
                <MenuElement icon={(<PeopleIcon/>)} text={t('users')} link="/user"/>
                <MenuElement icon={(<SettingsIcon/>)} text={t('settings')} link="/setting"/>
              </List>
            </>
          }
        </Drawer>
      )}
      <main className={clsx(classes.content, {
        [classes.contentOpen]: drawerOpen,
        [classes.contentClose]: !drawerOpen,
      })}>
        <div className={classes.toolbar}/>
        <Grid container spacing={1}>
          {children}
        </Grid>
      </main>
    </div>
  )
}

export default MenuAppBar
