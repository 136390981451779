import React from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import ProjectBaseInfo from './ProjectBaseInfo'
import ProjectWorkers from './ProjectWorkers'
import {Group, Info} from '@material-ui/icons'
import Block from '../../components/Block'
import {RouteComponentProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';


const Project = ({match}: RouteComponentProps<{ id: string }>) => {
  const { t } = useTranslation('project')
  const projectId = match.params.id

  return (
    <MenuAppBar>
      <Block header="Projekt" returnLink="/project" tabs={[
        {label: t('base_info'), icon: <Info />},
        {label: t('workers'), icon: <Group />}
      ]} tabsContents={[
        <ProjectBaseInfo key="base" projectId={projectId}/>,
        <ProjectWorkers key="workers" projectId={projectId}/>
      ]}/>
    </MenuAppBar>
  )

}

export default Project
