import React, {Component} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import DateFnsUtils from '@date-io/date-fns'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {request} from '../../services/api'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import {withSnackbar} from 'notistack'
import {currencyFormat} from '../../utils/currency'
import {formatDate} from '../../utils/datetime'
import ResponsiveTable from '../../components/ResponsiveTable'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {withTranslation} from 'react-i18next';

const styles = (theme) => ({
  smallColumn: {
    width: '100px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  margin: {
    margin: theme.spacing(1),
  },
})

class UserContracts extends Component {
  contractDefault = {
    id: undefined,
    from: (new Date()).toISOString().substring(0, 10),
    until: (new Date()).toISOString().substring(0, 10),
    type: {
      id: undefined
    },
    hoursMonthly: null,
    rate: {
      hour: null,
      month: null
    },
    allowedViewers: []
  }

  constructor(props, context) {
    super(props, context)
    this.userId = this.props.userId
    this.state = {
      contracts: [],
      contract: this.contractDefault,
      users: [],
      types: [],
      dialogOpen: false,
      contractUntilUndefined: false,
      selectedDate: null
    }
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.refresh = this.refresh.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  componentDidMount() {
    request('GET', '/user').then(data => this.setState({users: data}))
    request('GET', '/contract-type').then(data => this.setState({types: data}))
    this.refresh()
  }

  refresh() {
    request('GET', '/user/'+this.userId+'/contract').then(data => this.setState({contracts: data}))
  }

  handleEdit(row) {
    const contractUntilUndefined = row.until === '9999-12-31'
    this.setState({
      contract: Object.assign({}, row),  //We have to create a copy of a data
      dialogOpen: true,
      contractUntilUndefined
    })
  }

  handleCreate() {
    this.setState({
      contract: this.contractDefault,
      dialogOpen: true,
      contractUntilUndefined: false
    })
  }

  handleDelete(row) {
    request('DELETE', '/user/'+this.userId+'/contract/'+row.id)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('contract_removed_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  handleSave() {
    let method = (this.state.contract.id ? 'PUT' : 'POST')
    let url = '/user/'+this.userId+'/contract/'+(this.state.contract.id ?  this.state.contract.id : '')
    request(method, url, this.state.contract)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  handleClose() {
    this.setState({
      contract: this.contractDefault,
      dialogOpen: false
    })
  }

  handleChange(e) {
    let value = parseInt(e.target.value) ? parseInt(e.target.value) : null
    let elementId = e.target.id
    let elements = elementId.split('-')
    if (elements[0] === 'contract') {
      let contract = Object.assign({}, this.state.contract)
      if (elements.length === 2) {
        contract[elements[1]] = value
        this.setState({contract: contract})
        return
      }
      if (elements.length === 3 && elements[1] === 'rate') {
        let rate = Object.assign({}, contract.rate)
        rate[elements[2]] = value
        contract['rate'] = rate
        this.setState({contract: contract})
        return
      }
    }
    this.props.enqueueSnackbar(this.props.t('error_when_changing_data'), {variant: 'error'})
  }

  handleChangeDate(field, date) {
    try {
      let contract = Object.assign({}, this.state.contract)
      contract[field] = date === null ? null : date.toISOString().substring(0,10)
      this.setState({contract: contract, selectedDate: contract[field]})
    }
    catch (e) {
      //ok
    }
  }

  handleUntilUndefinedChange(e){
    const contract = Object.assign({}, this.state.contract)
    if(e.target.checked){
      contract.until = '9999-12-31'
    } else{
      contract.until = this.state.selectedDate !== null ? this.state.selectedDate : (new Date()).toISOString().substring(0, 10)
    }
    this.setState({contractUntilUndefined: e.target.checked, contract: contract})
  }

  render() {
    const { classes, t } = this.props
    const { contract } = this.state

    let users = []
    this.state.users.forEach(user => {
      if (user.id !== parseInt(this.userId)) {
        users[user.id] = user.firstName + ' ' + user.lastName
      }
    })

    let typesIds = []
    this.state.types.forEach(type => {typesIds[type.id] = type.name})

    return (
      <>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('valid_from')}</TableCell>
              <TableCell>{t('valid_until')}</TableCell>
              <TableCell>{t('type')}</TableCell>
              <TableCell>{t('salary')}</TableCell>
              <TableCell>{t('hours_month')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.contracts && this.state.contracts.map(row => (
              <TableRow key={row.id}>
                <TableCell>{formatDate(row.from)}</TableCell>
                <TableCell>{row.until === '9999-12-31' ? 'Neurčito' :  formatDate(row.until)}</TableCell>
                <TableCell>{row.type.name}</TableCell>
                <TableCell>{row.rate.hour ? currencyFormat(row.rate.hour)+' / hod.' : currencyFormat(row.rate.month) + t('month_rate')}</TableCell>
                <TableCell>{row.hoursMonthly ? row.hoursMonthly : '-'}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)}>
                    <EditIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)}>
                    <DeleteIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        <Box mt={2}>
          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={this.handleCreate}>
                {t('add_contract')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>Zmluva</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                margin="dense"
                id="contract-from"
                label={t('valid_from')}
                format="yyyy-MM-dd"
                value={contract.from}
                onChange={(d) => this.handleChangeDate('from', d)}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                margin="dense"
                id="contract-until"
                label={t('valid_until')}
                format="yyyy-MM-dd"
                maxDate="9999-12-31"
                value={contract.until}
                disabled={this.state.contractUntilUndefined}
                onChange={(d) => this.handleChangeDate('until', d)}
              />
            </MuiPickersUtilsProvider>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.contractUntilUndefined}
                  onChange={(e) => this.handleUntilUndefinedChange(e)}
                  name="contractUntilUndefined"
                  color="primary"
                />}
              label={t('contract_valid_until_undefined')}
            />
            <TextField
              margin="dense"
              id="contract-hoursMonthly"
              label={t('hours_month')}
              onChange={this.handleChange}
              value={contract.hoursMonthly ? contract.hoursMonthly : ''}
              fullWidth
            />
            <TextField
              margin="dense"
              id="contract-rate-hour"
              label="Mzda/hod."
              onChange={this.handleChange}
              value={contract.rate.hour ? contract.rate.hour : ''}
              fullWidth
            />
            <TextField
              margin="dense"
              id="contract-rate-month"
              label={t('salary_month')}
              onChange={this.handleChange}
              value={contract.rate.month ? contract.rate.month : ''}
              fullWidth
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="contract-type-label">{t('contract_type')}</InputLabel>
              <Select
                labelId="contract-type-label"
                id="contract-type"
                value={contract.type.id ? contract.type.id : ''}
                onChange={(e) => {
                  let c = Object.assign(Object.assign({}, contract), {type: {id: parseInt(e.target.value)}})
                  this.setState({contract: c})
                }}
              >
                {Object.keys(typesIds)
                  .map(id => (
                    <MenuItem key={'contract-type-'+id} value={id}>{typesIds[id]}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="contract-allowed-users-label">{t('allowed_to_see_for')}</InputLabel>
              <Select
                labelId="contract-allowed-viewers-label"
                id="contract-viewers-users"
                multiple
                value={contract.allowedViewers.map(user => user.id.toString())}
                onChange={(e) => {
                  let users = e.target.value.map(v => { return {id: parseInt(v)} })
                  let contract = Object.assign({}, this.state.contract)
                  this.setState({contract: Object.assign(contract, {allowedViewers: users})})
                }}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip key={value} label={users[value]} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {Object.keys(users).map(user => (
                  <MenuItem key={user} value={user}>
                    {users[user]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserContracts)))
