import React, {useState} from 'react'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import {makeStyles, Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {history} from '../config/history'
import Tabs from '@material-ui/core/Tabs'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import _ from 'lodash'
import logoRed from './logoRed.svg'
import {useTranslation} from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  page: {
    padding: theme.spacing(2)
  },
  tabsPage: {
    padding: theme.spacing(2,2,0,2)
  },
  logo: {
    verticalAlign: 'middle'
  },
  marginBottom: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  headerBottom: {
    paddingBottom: theme.spacing(2)
  },
  header: {
    padding: theme.spacing(0, 2, 0, 0),
    display: 'inline-block',
    fontWeight: 700
  },
  blockAppBar: {
    backgroundColor: theme.palette.background.default,
  }
}))

interface HeaderProps {
  header?: string
  headerContent?: string | React.ReactElement
  returnLink?: string
  tabs: boolean
}

interface TabPanelProps {
  children: React.ReactElement
  value: number
  index: number
}

interface BlockProps {
  header?: string
  headerContent?: string | React.ReactElement
  returnLink?: string
  children?: React.ReactElement
  tabs?: {
    label: string
    icon: string | React.ReactElement
  }[]
  tabsContents?: React.ReactElement []
  style?: React.CSSProperties
}

const Header = ({header, headerContent, returnLink, tabs} :HeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation('sections', { keyPrefix: 'block' })

  return (
    <Grid container className={clsx(tabs && classes.tabsPage, classes.marginBottom)}>
      <Grid container item xs={returnLink ? 9 : 12} justifyContent="space-between" alignItems="center">
        <Typography variant="h5" className={clsx(!_.isArray(tabs) && classes.headerBottom, classes.header)}>
          {header} <img src={logoRed} height="25" alt="inQool logo" className={classes.logo}/>
        </Typography>
        {headerContent}
      </Grid>
      {returnLink && (
        <Grid item xs={3}>
          <Grid container justifyContent="flex-end">
            <Button variant="contained" onClick={() => history.push(returnLink)}>
              {t('back')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}

const TabPanel =({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

const Block = ({header, headerContent, returnLink, tabs, tabsContents, children, style}: BlockProps) => {
  const classes = useStyles()
  const [tab, setTab] = useState(0)

  const handleChange = (newValue: number) => {
    setTab(newValue)
  }

  if (tabs && tabsContents) {
    return (
      <Grid item xs={12} style={style}>
        <Paper>
          <Paper color="default" className={classes.blockAppBar} square={true}>
            {header && (
              <Header header={header} headerContent={headerContent} returnLink={returnLink} tabs={true}/>
            )}
            <Tabs
              value={tab}
              onChange={(event, value) => handleChange(value)}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map((obj, index) => (
                <Tab key={index} label={obj.label} icon={obj.icon} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Paper>
          {tabsContents.map((content,index) => (
            <TabPanel key={index} value={tab} index={index}>
              {content}
            </TabPanel>
          ))}
        </Paper>
      </Grid>
    )
  }
  return (
    <Grid item xs={12}>
      <Paper className={classes.page}>
        {header && (
          <Header header={header} headerContent={headerContent} returnLink={returnLink} tabs={false} />
        )}
        {children}
      </Paper>
    </Grid>
  )
}

export default Block
