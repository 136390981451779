import React, {Component} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import {request} from '../../services/api'
import Box from '@material-ui/core/Box'
import {withSnackbar} from 'notistack'
import {Edit, KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons'
import {isHomeOffice, vacationTranslation} from '../../constants/Vacations'
import {formatDatetime, formatDatetimeOps} from '../../utils/datetime'
import {authentication} from '../../services/authentication'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash/lang'
import DialogActions from '@material-ui/core/DialogActions'
import clsx from 'clsx'
import {lighten} from '@material-ui/core/styles'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';


const styles = (theme) => ({
  smallColumn: {
    width: '100px'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  homeOffice: {
    backgroundColor: lighten(theme.workPlan.homeOfficeApproved.backgroundColor, 0.5)
  }
})

class UserVacations extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      year: (new Date()).getFullYear(),
      vacations: [],
      vacationLimit: {},
      remaining: {
        official: 0,
        unofficial: 0
      },
      limit: {
        official: 0,
        unofficial: 0
      },
      dialogOpen: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    this.refresh(this.state.year)
  }

  refresh(year) {
    request('GET', '/user/'+this.props.userId+'/vacation/'+year).then(data => this.setState({vacations: data}))
    request('GET', '/user/'+this.props.userId+'/vacation-limit/'+year).then(data => this.setState({limit: data}))
    request('GET', '/user/'+this.props.userId+'/vacation-limit/'+year+'/remaining').then(data => this.setState({remaining: data}))
  }

  handleClose() {
    this.setState({
      dialogOpen: false
    })
  }

  handleSave() {
    const user = authentication.getUser()
    request('PUT', '/user/'+user.id+'/vacation-limit/'+this.state.year, this.state.limit)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('limits_successfully_saved'), {variant: 'success'})
        this.refresh(this.state.year)
        this.setState({dialogOpen: false})
      })
      .catch(error => this.props.enqueueSnackbar(error.reason, {variant: 'error'}))
  }

  render() {
    const { classes, t } = this.props
    const { vacations, year, remaining, limit } = this.state
    const user = authentication.getUser()

    return (
      <>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box component="span" className={classes.nowrap} mb={2}>
              <IconButton onClick={() => {
                this.setState({year: year-1})
                this.refresh(year-1)
              }}>
                <KeyboardArrowLeft/>
              </IconButton>
              {year}
              <IconButton onClick={() => {
                this.setState({year: year+1})
                this.refresh(year+1)
              }}>
                <KeyboardArrowRight/>
              </IconButton>
            </Box>
          </Grid>
          <Grid item>
            {user.isAdmin() && (
              <>
                {/*//TODO refactor sentence into one i18next sentence*/}
                {t('vacation_remain')} {remaining.official}/{limit.official} {limit.unofficial > 0 ? t('official_and') + remaining.unofficial + '/' + limit.unofficial + t('unofficial') : ''} {t('days_vacations')}
                <Box component="span" ml={2}>
                  <Button startIcon={(<Edit/>)} color="primary" variant="contained" onClick={() => this.setState({dialogOpen: true})}>
                    {t('edit')}
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('from')}</TableCell>
              <TableCell>{t('until')}</TableCell>
              <TableCell>{t('type')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('hours_paid')}</TableCell>
              <TableCell>{t('approved')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vacations.map(row => (
              <TableRow className={clsx(isHomeOffice(row) && classes.homeOffice)} key={row.id}>
                <TableCell>{formatDatetimeOps(row.from, isHomeOffice(row))}</TableCell>
                <TableCell>{formatDatetimeOps(row.until, isHomeOffice(row))}</TableCell>
                <TableCell>{vacationTranslation(row.type)}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{isHomeOffice(row) ? '-' : (row.paidDuration ? row.paidDuration : 0)}</TableCell>
                <TableCell>{row.approved ? formatDatetime(row.approved) : t('no')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>Nastavenie počtu dovoleniek</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Nastavte limity pre dovolenky v roku {year}.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="vacation-limit-official"
              label={t('days_of_official_vacation')}
              onChange={(e) => {
                let limit = _.cloneDeep(this.state.limit)
                limit.official = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({limit})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={limit.official}
              fullWidth
            />
            <TextField
              margin="dense"
              id="vacation-limit-official"
              label={t('days_of_unofficial_vacation')}
              onChange={(e) => {
                let limit = _.cloneDeep(this.state.limit)
                limit.unofficial = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({limit})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={limit.unofficial}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserVacations)))
