import React, {Component} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import {request} from '../../services/api'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import prettyBytes from 'pretty-bytes'
import DialogContentText from '@material-ui/core/DialogContentText'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import {withSnackbar} from 'notistack'
import {formatDatetime} from '../../utils/datetime'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
})

class UserFiles extends Component {
  fileDefault = {
    id: undefined,
    name: '',
    description: '',
    size: 0,
    created: '',
    createdBy: {
      firstName: '',
      lastName: ''
    }
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      files: undefined,
      file: this.fileDefault,
      dialogOpen: false
    }
    this.fileInputRef = React.createRef()
    this.handleDownload = this.handleDownload.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.refresh = this.refresh.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    request('GET', '/user/'+this.props.userId+'/file').then(data => this.setState({files: data}))
  }

  handleChange(e) {
    let file = this.state.file
    file.description = e.target.value
    this.setState({
      file: file
    })
  }

  handleEdit(row) {
    this.setState({
      file: Object.assign({}, row),  //We have to create a copy of a data
      dialogOpen: true
    })
  }

  handleDownload(row) {
    request('GET', '/user/'+this.props.userId+'/file/'+row.id, undefined, true)
      .then(blob => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = row.name
        a.click()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_downloading_data') + error.reason, {variant: 'error'})
      })
  }

  handleCreate() {
    this.setState({
      file: this.fileDefault,
      dialogOpen: true
    })
  }

  handleDelete(row) {
    request('DELETE', '/user/'+this.props.userId+'/file/'+row.id)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('file_deleted_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  handleSave() {
    let method = (this.state.file.id ? 'PUT' : 'POST')
    let url = '/user/'+this.props.userId+'/file/'+(this.state.file.id ?  this.state.file.id : '')
    let data = this.state.file
    if (typeof this.state.file.id === 'undefined') {
      if (this.fileInputRef.current.children.length !== 1 || this.fileInputRef.current.children[0].files.length !== 1) {
        this.props.enqueueSnackbar(this.props.t('select_file_for_upload'), {variant: 'error'})
        return
      }
      data = new FormData()
      data.append('description', this.state.file.description)
      data.append('file', this.fileInputRef.current.children[0].files[0])
    }
    request(method, url, data)
      .then(() => {
        let successMessage = this.state.file.id ? this.props.t('changes_saved_successfully') : this.props.t('file_uploaded_successfully')
        this.props.enqueueSnackbar(successMessage, {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  handleClose() {
    this.setState({
      file: this.fileDefault,
      dialogOpen: false
    })
  }

  render() {
    const { classes, t } = this.props
    const { file } = this.state
    return (
      <>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('size')}</TableCell>
              <TableCell>{t('note')}</TableCell>
              <TableCell>{t('created_at')}</TableCell>
              <TableCell>{t('created_by')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('download')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.files && this.state.files.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{prettyBytes(row.size)}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{formatDatetime(row.created)}</TableCell>
                <TableCell>{row.createdBy.firstName+' '+row.createdBy.lastName}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDownload(row)}>
                    <GetAppIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)}>
                    <EditIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)}>
                    <DeleteIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        <Box mt={2}>
          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={this.handleCreate}>
                {t('add_file')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>Súbor</DialogTitle>
          <DialogContent>
            {file.id && (
              <DialogContentText>
                {t('add_new_note_for_file')} {this.state.file.name}.
              </DialogContentText>
            )}
            <TextField
              autoFocus
              margin="dense"
              label={t('note')}
              id="file-description"
              onChange={this.handleChange}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={file.description}
              fullWidth
            />
            {typeof file.id === 'undefined' && (
              <FormControl fullWidth margin="dense">
                <InputLabel htmlFor="file-file">{t('file')}</InputLabel>
                <Input
                  id="file-file"
                  ref={this.fileInputRef}
                  type="file"
                />
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserFiles)))
