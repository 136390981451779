import React, {useEffect, useState} from 'react'
import {request} from '../../services/api'
import { authentication } from '../../services/authentication'
import {history} from '../../config/history'
import {Typography} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import {useTranslation} from 'react-i18next'
import i18next from '../../i18next';

interface AuthProps {
  location: {
    search: string
  }
}

const Auth = ({location}: AuthProps) => {
  const [error, setError] = useState(undefined)
  const { t } = useTranslation('sections', { keyPrefix: 'auth' })

  useEffect(() => {
    //If a user is already authenticated, go to the home page
    if (authentication.getUser(false)) {
      return history.push('/dashboard')
    }

    //Get code from url
    const getParams = location.search
    const codeStart = getParams.indexOf('code=')
    const code = getParams.substr(codeStart)

    //If there is a not a code, request oath login page
    if (code === '') {
      request('GET', '/auth')
        .then(data => {window.location.href = data.url})
        .catch((error) => setError(error.reason))
    }
    else {
      //If there is a code, try to authenticate to the api
      request('GET', `/auth?${code}`)
        .then(data => {
          authentication.login(data.token)
          const user = authentication.getUser()
          i18next.changeLanguage(user?.lang).then(() => {
            if(user && user.isEmployee()) return history.push('/time-sheet')
            return history.push('/dashboard')
          })
        })
        .catch((error) => setError(error.reason))
    }
  }, [location.search])

  let message = (
    <>
      <Typography variant="h4">{t('logging_in')}</Typography>
      <div className="App-progress"><LinearProgress /></div>
    </>
  )
  if (error) {
    message = (
      <p>
        <Typography variant="h3">{t('error')}</Typography>
        <Typography variant="h5">{error}</Typography>
      </p>
    )
  }
  return (
    <div className="App">
      <header className="App-header">
        {message}
      </header>
    </div>
  )
}

export default Auth
