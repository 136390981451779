import React, {Component} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {createStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import {TableProps} from '@material-ui/core/Table/Table';

type Classes = 'block'
const styles = () => createStyles({
  block: {
    width: '100%',
    overflowX: 'auto'
  },
});


class ResponsiveTable extends Component<TableProps & WithStyles<Classes>> {
  render() {
    const { classes, ...other } = this.props;
    return (
      <Box className={classes.block}>
        <Table {...other}>
          {this.props.children}
        </Table>
      </Box>
    )
  }
}

export default withStyles(styles)(ResponsiveTable)
