export type projectStatesObject = {
  [key: string]: string
}

export const projectStates: projectStatesObject = {
  'ARCHIVED': 'archivovaný',
  'PREPARING': 'pripravovaný',
  'REALIZING': 'realizovaný',
  'SERVICING': 'servisovaný'
}

/**
 * @return {string}
 */
export function ProjectStateTranslation(state: string) {
  if (state in projectStates) {
    return projectStates[state as keyof projectStatesObject]
  }
  return 'neznámy'
}
