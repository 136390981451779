import React, {Component} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {formatISODate} from '../utils/datetime';
import _ from 'lodash';
import {createStyles, Dialog} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import skLocale from 'date-fns/locale/sk';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {request} from '../services/api';
import {withSnackbar, WithSnackbarProps} from 'notistack';
import Typography from '@material-ui/core/Typography';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import {Autocomplete, AutocompleteChangeReason} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import csLocale from 'date-fns/locale/cs';

type Classes = 'nowrap' | 'projectHeader' | 'projectContainer' | 'chips' | 'chip' | 'multiSelect'
const styles = () => createStyles({
  nowrap: {
    whiteSpace: 'nowrap'
  },
  projectHeader: {
    marginTop: 10,
    fontSize: '1.1rem',
    opacity: 0.9
  },
  projectContainer: {
    position: 'relative',
    paddingLeft: 10,
    '&::before': {
      content: '""',
      width: '2px',
      height: '90%',
      position: 'absolute',
      left: 0,
      top: '3%',
      opacity: 0.9,
      backgroundColor: 'black'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  multiSelect: {
    color: 'green',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white'
    }
  }
});

type AddRecordDialogProps = {
  onSave: () => void,
  onClose: () => void,
  open: boolean,
  userId: number,
  record?: Record | Record[] | null,
  t: any,
  i18n: any
}

type Project = {
  id: number,
  name: string
}

type Record = {
  id: number|string|undefined,
  date: string|null,
  project: {
    id: number|null
  },
  from: string,
  until: string,
  duration: number|string,
  description: string,
  approved: null,
  approvedBy: null
}

type UserProjectResponse = {project: Project}[];

type AddRecordDialogStates = {
  records: Record[],
  projects: Project[],
  lastlySelected: number[],
  predefinedDate: string | null,
  predefinedFrom: string | null,
  predefinedUntil: string | null
}

type Props = AddRecordDialogProps & WithStyles<Classes> & WithSnackbarProps

const defaultProject: Project = {id: 0, name: 'Nezaradené'}

class AddRecordDialog extends Component<Props, AddRecordDialogStates> {
  recordDefault: Record = {
    id: undefined,
    date: formatISODate(new Date()),
    project: {
      id: null
    },
    from: '00:00',
    until: '00:00',
    duration: 0,
    description: '',
    approved: null,
    approvedBy: null
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      records: [],
      projects: [],
      lastlySelected: [],
      predefinedDate: null,
      predefinedFrom: null,
      predefinedUntil: null
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    request('GET', '/user/'+this.props.userId+'/project').then((data: UserProjectResponse) => {
      const projects = data.map(p => p.project);
      this.setState({projects: projects})
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open !== prevProps.open) {
      let records: Record[] = [];
      if (this.props.record) {
        records = Array.isArray(this.props.record) ? this.props.record : [this.props.record];
      }
      this.setState({
        records: _.cloneDeep(records),
        predefinedDate: null,
        predefinedUntil: null,
        predefinedFrom: null
      });
    }
  }

  componentWillUnmount() {
    //TODO rewrite to functional component and make request cancel
  }

  handleChangeDate(date: MaterialUiPickersDate) {
    const records = _.clone(this.state.records)
    if(records.length > 0){
      records.map(r => r.date = formatISODate(date))
      this.setState({records});
    } else{
      this.setState({predefinedDate: formatISODate(date)})
    }
  }

  handleFromUntilChange(time: string, type: 'from' | 'until'){
    let records = _.cloneDeep(this.state.records)

    switch (type){
    case 'from':
      records.length > 0 ? this.setState({records: records.map(r => ({...r, from: time }))}) : this.setState({predefinedFrom: time})
      break
    case 'until':
      records.length > 0 ? this.setState({records: records.map(r => ({...r, until: time }))}) : this.setState({predefinedUntil: time})
      break
    default:
    }

  }

  handleMultiselectChange(values: Project[], reason: AutocompleteChangeReason) {
    if (reason === 'clear' || values.length === 0){
      this.setState({records: [], lastlySelected: []});
      return
    }

    const r = _.cloneDeep(this.state.records);
    const projectIds = values.map((v) => v.id);

    if (projectIds.length > r.length) {
      const newRecord = _.clone(this.recordDefault)
      newRecord.date = this.state.records[0]?.date || this.state.predefinedDate || newRecord.date
      newRecord.from = this.state.records[0]?.from || this.state.predefinedFrom || newRecord.from
      newRecord.until = this.state.records[0]?.until || this.state.predefinedUntil || newRecord.until
      newRecord.id =  uuidv4()
      r.push(newRecord)
    }
    if (projectIds.length < r.length) {
      const removedProject = this.state.lastlySelected.find(x => !projectIds.includes(x))
      r.splice(r.findIndex((cr => cr.project.id === removedProject)), 1)
    }

    r.map((cr, i) => cr.project = {
      id: projectIds[i] === 0 ? null : projectIds[i]
    });

    this.setState({records: r, lastlySelected: projectIds});
  }

  async handleSave() {
    const {records} = this.state;
    const {onSave, userId, enqueueSnackbar} = this.props;

    const responses = await Promise.allSettled(
      records.map(async (record) => {
        if (record.id && uuidValidate(record.id.toString())) record.id = undefined
        const method = (record.id ? 'PUT' : 'POST');
        const url = '/user/' + userId + '/record/' + (record.id ? record.id : '');
        await request(method, url, record)
      })
    )

    const errors = responses.reduce<string[]>((acc, next, index) => {
      const projectName = records[index].project && records[index].project.id !== null ? this.state.projects.find(p => p.id === records[index].project.id) : undefined

      if (next.status === 'rejected') {
        acc.push(next.reason)
        enqueueSnackbar(`Nastala chyba pri vkladaní záznamu s projektem ${projectName !== undefined ? projectName.name : 'Nezaradené'} do výkazu: ${next.reason}`, {variant: 'error'})
      }
      if (next.status === 'fulfilled') {
        enqueueSnackbar(`Záznam ${projectName !== undefined ? projectName.name : 'Nezaradené'} úspešne vložený.`, {variant: 'success'});
      }
      return acc;
    }, []);


    if (errors.length) {
      // eslint-disable-next-line no-console
      errors.forEach(e => console.error(e))
      return false
    }

    if (onSave) {
      onSave();
    }

  }

  handleClose() {
    const {onClose} = this.props;
    if (onClose) {
      onClose();
    }
  }

  render() {
    const {open, classes, t, i18n} = this.props;
    const {records, projects} = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <DialogTitle>Výkaz</DialogTitle>
        <DialogContent>
          {projects.length === 0 && (
            <Typography variant="body1">
              {t('user_doesnt_have_project')}
            </Typography>
          )}
          {projects.length > 0 && (
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'sk' ? skLocale : csLocale}>
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="record-date"
                  label={t('date')}
                  format="dd. MM. yyyy"
                  value={records.length > 0 ? records[0].date : (this.state.predefinedDate || formatISODate(new Date()))}
                  onChange={this.handleChangeDate}
                  autoOk
                  // disabled={records.length < 1}
                  // helperText="Výber dátumu a času sa aktivuje po vybraní prvého projektu."
                />
              </MuiPickersUtilsProvider>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    // id="record-from"
                    label="Od"
                    type="time"
                    inputProps={{step: '600'}}
                    onChange={e => this.handleFromUntilChange(e.target.value, 'from')}
                    // onChange={e => {
                    //   let records = _.cloneDeep(this.state.records);
                    //   records = records.map(r => ({...r, from: e.target.value }))
                    //   this.setState({records});
                    // }}
                    onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
                    value={records[0]?.from || this.state.predefinedFrom || '00:00'}
                    // disabled={records.length < 1}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    // id="record-until"
                    label="Do"
                    type="time"
                    inputProps={{step: '600'}}
                    onChange={e => this.handleFromUntilChange(e.target.value, 'until')}
                    // onChange={e => {
                    //   let records = _.cloneDeep(this.state.records);
                    //   records = records.map(r => ({...r, until: e.target.value }))
                    //   this.setState({records});
                    // }}
                    onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
                    value={records[0]?.until || this.state.predefinedUntil || '00:00'}
                    // disabled={records.length < 1}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <FormControl fullWidth margin="dense">
                <Autocomplete
                  multiple
                  id="projects-select"
                  noOptionsText={t('no_other_projects_found')}
                  options={[defaultProject, ...projects]}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  className={classes.multiSelect}
                  filterSelectedOptions
                  value={(records.length > 0) ? records.map(r => {
                    return r.project && r.project.id !== null ? projects.find(p => p.id === r.project.id) || defaultProject : defaultProject
                  }) : []}
                  onChange={(event: any, values: Project[] | [], reason: AutocompleteChangeReason) => this.handleMultiselectChange(values, reason)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Projekty"
                      placeholder="Projekty"
                    />
                  )}
                />
              </FormControl>
              {
                records.map((record, index) => (
                  <div key={index} className={classes.projectContainer}>
                    <Typography variant="h6" className={classes.projectHeader}>
                      {record.project && record.project.id !== null ? projects.map(p => p.id === record.project.id ? p.name : null) : 'Nezarazené'}
                    </Typography>
                    <TextField
                      margin="dense"
                      // id="record-description"
                      label="Poznámka"
                      onChange={e => {
                        const records = _.cloneDeep(this.state.records);
                        const index = records.findIndex(r=> r.id === record.id)
                        records[index].description = e.target.value
                        this.setState({records});
                      }}
                      onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
                      value={record.description}
                      fullWidth
                    />
                    <TextField
                      error={_.isNaN(Number(record.duration)) || record.duration.toString().endsWith('.')}
                      margin="dense"
                      // id="record-duration"
                      label={t('hours')}
                      helperText={t('helper_text')}
                      onChange={e => {
                        if(/^[0-9.]*$/.test(e.target.value)) {
                          const records = _.cloneDeep(this.state.records);
                          const index = records.findIndex(r => r.id === record.id)
                          records[index].duration = parseFloat(e.target.value) && !e.target.value.endsWith('.') ? parseFloat(e.target.value) : e.target.value;
                          this.setState({records})
                        }
                      }}
                      onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
                      value={record.duration}
                      fullWidth
                    />
                  </div>
                ))
              }
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            {t('cancel')}
          </Button>
          {projects.length > 0 && (
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

export default withTranslation('dialogs', { keyPrefix: 'add_record_dialog' })(withSnackbar(withStyles(styles)(AddRecordDialog)))
