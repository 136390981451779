import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import withStyles from '@material-ui/core/styles/withStyles'
import _ from 'lodash/lang'
import { parseISO, isFriday, differenceInBusinessDays } from 'date-fns'
import {Check, Clear, Delete} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import {authentication} from '../../services/authentication'
import {vacationShortcutTranslation, vacationTypeHiddenTranslation} from '../../constants/Vacations'
import clsx from 'clsx'
import {isSameDate } from '../../utils/datetime'
import {darken} from '@material-ui/core/styles'

const zero = '0px'
const margin = '6px'
const padding = '4px'

const styles = (theme) => ({
  tooltip: {
    padding: theme.spacing(1)
  },
  root: {
    padding: '3px 0 9px 0'
    // margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  itemPlan: {
    backgroundColor: theme.workPlan.plan.backgroundColor,
    borderColor: darken(theme.workPlan.plan.backgroundColor,0.1)
  },
  itemVacationApproved: {
    backgroundColor: theme.workPlan.vacationApproved.backgroundColor,
    borderColor: darken(theme.workPlan.vacationApproved.backgroundColor,0.1),
    color: theme.palette.primary.contrastText
  },
  itemVacationHomeOfficeApproved: {
    backgroundColor: theme.workPlan.homeOfficeApproved.backgroundColor,
    borderColor: darken(theme.workPlan.homeOfficeApproved.backgroundColor,0.1)
  },
  itemVacationNonApprove: {
    backgroundColor: theme.workPlan.vacationNonApprove.backgroundColor,
    borderColor: darken(theme.workPlan.vacationNonApprove.backgroundColor,0.1)
  },
  item: {
    minWidth: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '28px',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
  textField: {
    width: '100%',
    textAlign: 'center',
    minHeight: '28px'
  },
  card: {
    minWidth: '80px',
    boxShadow: 'none'
  }
})

/*function computeLeftAndRightContinues(plan, date) {
  const from = parseISO(plan.from)
  const until = parseISO(plan.until)
  const leftContinues = !isSameDate(plan.from, date) && compareAsc(from, date) < 1
  const rightContinues = !isSameDate(plan.until, date) && compareAsc(until, date) > -1
  return [leftContinues, rightContinues]
}*/

function computeLeftAndRightIf(/*plan, date*/) {
  const [leftContinues, rightContinues] = [false, false] //computeLeftAndRightContinues(plan, date)
  const rightIf = rightContinues ? zero : margin
  const leftIf = leftContinues ? zero : margin
  return [leftIf, rightIf]
}

function computeStylesContent(/*plan, date*/) {
  /** TODO rethink joined cells */
  const [leftIf, rightIf] = computeLeftAndRightIf(/*plan, date*/)
  const [leftContinues, rightContinues] = [false, false] //computeLeftAndRightContinues(plan, date)
  return {
    padding: padding,
    borderRadius: [leftIf, rightIf, rightIf, leftIf].join(' '),
    borderLeftWidth: leftContinues ? '0px' : '1px',
    borderRightWidth: rightContinues ? '0px' : '1px'
  }
}

function computeStylesCard(/*plan, date*/) {
  const [leftIf, rightIf] = computeLeftAndRightIf(/*plan, date*/)
  return {
    margin: [margin, rightIf, zero, leftIf].join(' '),
    borderRadius: [leftIf, rightIf, rightIf, leftIf].join(' '),
  }
}

function tooltipTitle(plan) {
  switch (plan.objectType) {
  case 'plan':
    return plan.project.name+(plan.place ? ', '+plan.place : '')
  case 'vacation':
    return vacationTypeHiddenTranslation(plan.type)+(plan.description ? ', '+plan.description : '')
  default:
    return ''
  }
}

function planName(plan) {
  switch (plan.objectType) {
  case 'plan':
    return plan.project.shortcut
  case 'vacation':
    return vacationShortcutTranslation(plan.type)
  default:
    return '?'
  }
}

function planClass(plan, classes) {
  switch (plan.objectType) {
  case 'plan':
    return classes.itemPlan
  case 'vacation':
    switch (plan.type) {
    case 'HOME_OFFICE':
      return (plan.approved ? classes.itemVacationHomeOfficeApproved : classes.itemVacationNonApprove)
    default:
      return (plan.approved ? classes.itemVacationApproved : classes.itemVacationNonApprove)
    }
  default:
    return undefined
  }
}

class WorkPlanDay extends Component {
  render() {
    const { classes, plans, date, onDelete, onApprove, onDisapproved } = this.props
    const user = authentication.getUser()
    if (_.isArray(plans) && plans.length > 0) {
      return (
        <Grid container spacing={0} className={classes.root}>
          {plans.map(plan => (
            <Grid key={plan.objectType + plan.id} item xs={12}>
              <Tooltip
                title={tooltipTitle(plan)}
                classes={{tooltip: classes.tooltip}}
              >
                <Card style={computeStylesCard(plan, date)} className={classes.card}>
                  <CardContent className={clsx(classes.item, planClass(plan, classes))} style={computeStylesContent(plan, date)}>
                    <Box component="span" className={classes.textField}>
                      {planName(plan)}
                      {/*(isSameDate(plan.date, date) || isMonday(date)) ? planName(plan) : ''*/}
                    </Box>
                    <Box component="span" style={{display: 'inline-flex'}}>
                      {isSameDate(plan.until, date) && (user.isAdmin() && plan.objectType === 'vacation') && (
                        <>
                          {plan.approved === null && onApprove && (
                            <IconButton size="small" onMouseDown={(e) => {
                              e.stopPropagation()
                              if (onApprove) {
                                onApprove(plan)
                              }
                            }}>
                              <Check fontSize="inherit"/>
                            </IconButton>
                          )}
                          {plan.approved !== null && onDisapproved && (
                            <IconButton size="small" onMouseDown={(e) => {
                              e.stopPropagation()
                              if (onDisapproved) {
                                onDisapproved(plan)
                              }
                            }}>
                              <Clear fontSize="inherit"/>
                            </IconButton>
                          )}
                        </>
                      )}
                      {(
                        (plan.objectType === 'plan' && isSameDate(plan.date, date)) ||
                        (isSameDate(plan.until, date) || (isFriday(date) && differenceInBusinessDays(date, parseISO(plan.until)) === 0))
                      ) && 
                      (user.isAdmin() || plan.user === user.id) && onDelete && (
                        <IconButton size="small" onMouseDown={(e) => {
                          e.stopPropagation()
                          if (onDelete) {
                            onDelete(plan)
                          }
                        }}>
                          <Delete fontSize="inherit"/>
                        </IconButton>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      )
    }

    return <></>
  }
}

export default withStyles(styles)(WorkPlanDay)
