export const OPEN_DRAWER = 'OPEN_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'

export function menu(state = false, action) {
  switch (action.type) {
  case OPEN_DRAWER:
    return true
  case CLOSE_DRAWER:
    return false
  default:
    return state
  }
}
