import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import {MyRouter} from './config/router'
import CssBaseline from '@material-ui/core/CssBaseline'

import './App.css'
import config from './config'


function App() {
  return (
    <ThemeProvider theme={config.theme}>
      <div className="app-container">
        <CssBaseline />
        <MyRouter/>
      </div>
    </ThemeProvider>
  )
}

export default App
