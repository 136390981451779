// import { makeStyles } from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import {Dialog, DialogContent} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {format, formatRFC3339} from 'date-fns';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import {request} from '../services/api';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DateFnsUtils from '@date-io/date-fns';
import skLocale from 'date-fns/locale/sk';
import csLocale from 'date-fns/locale/cs';
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Contract } from '../utils/userInfo';
import {defaultVacationInterface, vacationTypesInterface} from '../constants/Vacations';
import {useTranslation} from 'react-i18next';


// const useStyles = makeStyles(theme => ({
//
// }))

export const vacationDefault: defaultVacationInterface = {
  id: null,
  name: '',
  from: formatRFC3339(new Date().setHours(0,0,0)),
  origFrom: undefined,
  until: formatRFC3339(new Date().setHours(23,59,59)),
  origUntil: undefined,
  type: '',
  description: '',
  approved: null,
  paidDuration: 0
}


interface Props {
  open: boolean
  vacationTypes: vacationTypesInterface
  user: any
  userContract: Contract
  onSave: () => void
  onClose: () => void
  editedVacation : defaultVacationInterface | null
}

const AddVacationsDialog = ({open, vacationTypes, user, userContract, onSave, onClose, editedVacation = null}: Props) => {
  // const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation('dialogs', { keyPrefix: 'add_vacation_dialog' })
  const [ vacation, setVacation ] = useState(editedVacation || vacationDefault)
  const [ vacationLimitMessage, setVacationLimitMessage ] = useState('')

  const refreshVacationLimit = useCallback((signal) => {
    if(vacationTypes.showLimits && userContract.type.name === 'HPP'){
      request('GET', `/user/${user.id}/vacation-limit/${format(new Date(), 'Y')}/remaining`, undefined, false, signal)
        .then(data => {
          if(data === 'cancelled') return

          let vacationLimitMessage
          if (data.official === 0) {
            vacationLimitMessage = t('no_remaining_vacation')
          }
          else {
            vacationLimitMessage = t('remaining_vacation', {days: data.official.toString()})
          }
          setVacationLimitMessage(vacationLimitMessage)
        })
        .catch(error => enqueueSnackbar(error.reason, {variant: 'error'}))
    }else{
      setVacationLimitMessage('')
    }

  },[enqueueSnackbar, user.id, userContract.type.name, vacationTypes.showLimits])


  const handleNewVacationSet = useCallback(() => {
    const newVacation = _.clone(editedVacation || vacationDefault)
    if(vacationTypes.withTime){
      const from = new Date()
      from.setHours(0,0,0,0)
      const until = new Date()
      until.setHours(23,59,59,0)
      newVacation.from = formatRFC3339(from)
      newVacation.until = formatRFC3339(until)
    }
    newVacation.type = vacationTypes.types[0].id
    setVacation(newVacation)
  }, [editedVacation, vacationTypes])


  useEffect(() => {
    const controller = new AbortController()
    handleNewVacationSet()
    refreshVacationLimit(controller.signal)
    return () => {
      controller.abort()
    }
  }, [handleNewVacationSet, refreshVacationLimit])


  const handleClose = () => {
    if (onClose) onClose()
  }

  const handleChangeVacationDatetime = (field: 'from' | 'until', value: MaterialUiPickersDate, setHours = false) => {
    if(!value) return
    const newVacation = _.cloneDeep(vacation)

    if (setHours) {

      switch (field) {
      case 'from':
        value.setHours(0,0,0)
        if(value > new Date(newVacation.until)) newVacation.until = formatRFC3339(_.clone(value).setHours(23, 59, 59))

        break
      default:
        value.setHours(23,59,59)

        break

      }

    } else if(value > new Date(newVacation.until) && field === 'from'){
      newVacation.until = formatRFC3339(value)
    }

    newVacation[field] = formatRFC3339(value)
    setVacation(newVacation)

  }

  const handleSaveVacation = () => {
    const newVacation = _.clone(vacation)

    if(userContract.type.name === 'HPP'){
      switch (newVacation.type){
      case 'VACATION_OFFICIAL':
        newVacation.paidDuration = 8
        break
      case 'DOCTOR' || 'OTHER':
        newVacation.paidDuration = parseFloat(((new Date(newVacation.until).getTime() - new Date(newVacation.from).getTime()) / (1000 * 60 * 60)).toLocaleString('en', {maximumFractionDigits:1, useGrouping:false}))
        break
      default:
        newVacation.paidDuration = 0
      }
    }

    if(newVacation.id === null) delete newVacation.id
    request(newVacation.id ? 'PUT' : 'POST', `/user/${user.id}/vacation${vacation.id ? `/${vacation.id}` : ''}`, newVacation)
      .then(() => {
        enqueueSnackbar(t('record_added_successfully'), {variant: 'success'})
        if(onSave) onSave()
      })
      .catch(error => enqueueSnackbar(error.reason, {variant: 'error'}))
  }


  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{vacationTypes.title}</DialogTitle>
      <DialogContent>
        {vacationLimitMessage.length > 0 ? <DialogContentText>{vacationLimitMessage}</DialogContentText> : null}
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'sk' ? skLocale : csLocale}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="vacation-type-label">Typ</InputLabel>
            <Select
              labelId="vacation-type-label"
              id="vacation-type"
              value={vacation.type}
              onChange={(e) => {
                const newVacation = _.cloneDeep(vacation)
                newVacation.type = e.target.value as string
                setVacation(newVacation)
              }}
            >
              {vacationTypes.types.map(v => (
                <MenuItem key={'vacation-type-'+v.id} value={v.id}>{v.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            vacationTypes.withTime
              ? <Grid container spacing={1}>
                <Grid item xl={6}>
                  <DateTimePicker
                    label="Od"
                    todayLabel="Dnes"
                    cancelLabel={t('cancel')}
                    okLabel={t('set')}
                    fullWidth
                    value={vacation.from}
                    // disablePast={!vacation.origFrom}
                    onChange={(value: MaterialUiPickersDate) => handleChangeVacationDatetime('from', value)}
                    ampm={false}
                    showTodayButton
                    autoOk
                  />
                </Grid>
                <Grid item xl={6}>
                  <DateTimePicker
                    label="Do"
                    todayLabel="Dnes"
                    cancelLabel={t('cancel')}
                    okLabel={t('set')}
                    fullWidth
                    value={vacation.until}
                    // disablePast={!vacation.origUntil}
                    onChange={(value: MaterialUiPickersDate) => handleChangeVacationDatetime('until', value)}
                    ampm={false}
                    showTodayButton
                    autoOk
                  />
                </Grid>
              </Grid>
              :
              <Grid container spacing={1}>
                <Grid item xl={6}>
                  <DatePicker
                    fullWidth
                    label="Od"
                    todayLabel="Dnes"
                    cancelLabel={t('cancel')}
                    okLabel={t('set')}
                    value={vacation.from}
                    // disablePast={!vacation.origFrom}
                    onChange={(value: MaterialUiPickersDate) => handleChangeVacationDatetime('from', value, true)}
                    animateYearScrolling
                    showTodayButton
                    autoOk
                  />
                </Grid>
                <Grid item xl={6}>
                  <DatePicker
                    fullWidth
                    label="Do"
                    todayLabel="Dnes"
                    cancelLabel={t('cancel')}
                    okLabel={t('set')}
                    value={vacation.until}
                    // disablePast={!vacation.origUntil}
                    onChange={(value: MaterialUiPickersDate) => handleChangeVacationDatetime('until', value, true)}
                    animateYearScrolling
                    showTodayButton
                    autoOk
                  />
                </Grid>
              </Grid>
          }
        </MuiPickersUtilsProvider>
        <TextField
          autoFocus
          margin="dense"
          id="vacation-description"
          label="Poznámka"
          onChange={(e) => {
            const newVacation = _.cloneDeep(vacation)
            newVacation.description = e.target.value
            setVacation(newVacation)
          }}
          onKeyUp={(e) => { if (e.keyCode === 13) { handleSaveVacation() } }} //Save a form if enter is pressed
          value={vacation.description}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          {t('cancel')}
        </Button>
        <Button onClick={() => handleSaveVacation()} color="primary">
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default AddVacationsDialog
