import React from 'react'
import {useTranslation} from 'react-i18next';

interface NotFoundProps {
  location: {
    pathname: string
  }
}

const NotFound = ({location}: NotFoundProps) => {
  const { t } = useTranslation('sections', { keyPrefix: 'not_found' })
  return (
    <div className="App">
      <header className="App-header">
        <h3>{t('header')}</h3>
        <p>
          <code>{location.pathname}</code> {t('text')}
        </p>
      </header>
    </div>
  )
}

export default NotFound
