import React, {Component, Fragment} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import {request} from '../../services/api'
import Box from '@material-ui/core/Box'
import {withSnackbar} from 'notistack'
import {
  Check,
  Clear,
  DateRange,
  Delete,
  DoneAll,
  Edit,
  ExpandLess,
  ExpandMore, HomeWork,
  Info, LocalHospital, WorkOff
} from '@material-ui/icons'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import {formatDate, formatHourMinute, formatISODate, formatMonthDay, isHoliday} from '../../utils/datetime'
import Grid from '@material-ui/core/Grid'
import {authentication} from '../../services/authentication'
import _ from 'lodash'
// import {lighten} from '@material-ui/core/styles'
import AddRecordDialog from '../../components/AddRecordDialog'
import AddVacationsDialog from '../../components/AddVacationsDialog'
import Button from '@material-ui/core/Button'
import ResponsiveTable from '../../components/ResponsiveTable'
import Tooltip from '@material-ui/core/Tooltip'
import Hidden from '@material-ui/core/Hidden'
import clsx from 'clsx'
import {
  vacationsWithOffs,
  vacationsWithDoctor,
  vacationsWithSick,
  getWithAllowedTypes, vacationShortcutTranslation, vacationTypeHiddenTranslation, getFilteredVacationsArray
} from '../../constants/Vacations';
import {getContractBasedOnDate} from '../../utils/userInfo';
import {startOfMonth, endOfMonth, formatISO, isWeekend, differenceInDays, getDaysInMonth} from 'date-fns'
import {withTranslation} from 'react-i18next';

const styles = (theme) => ({
  greyCell: {
    // color: lighten(theme.palette.secondary.light, 0.3)
  },
  extraSmallColumn: {
    width: theme.spacing(5),
    padding: theme.spacing(0.8, 0.5)
  },
  smallColumn: {
    width: '100px'
  },
  mediumColumn: {
    width: '130px'
  },
  largerColumn: {
    width: '300px'
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 700,
      borderBottom: '2px solid black'
    },
    '& .MuiTableRow-head': {
      '& .MuiTableCell-head:first-child': {
        borderLeft: '2px solid white'
      },
      '& .MuiTableCell-head:last-child': {
        borderRight: '2px solid white'
      },
    }
  },
  tableBody: {
    '& .MuiTableCell-body': {
      borderTop: '1px solid #E0E0E0',
      borderBottom: 'none',
      fontSize: '1rem',
      color: '#515151',
      paddingTop: 3,
      paddingBottom: 3
    },
    '& .MuiTableCell-body:last-child': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  lastRow: {
    border: 'none',
    fontSize: '0.8rem'
  },
  greyRow: {
    // backgroundColor: '#f8f8f8'
  },
  date: {
    fontWeight: 700,
  },
  weekend: {
    '& .MuiTableCell-body': {
      backgroundColor: '#F4F2F2'
    }
  },
  openedParent: {
    '& .MuiTableCell-body': {
      color: 'white',
      borderBottomColor: '#515151',
      borderTopColor: '#515151',
      backgroundColor: '#515151'
    },
    '& .MuiTableCell-body:first-child': {
      borderLeft: '2px solid #515151'
    },
    '& .MuiTableCell-body:last-child': {
      borderRight: '2px solid #515151'
    }
  },
  openedChild: {
    backgroundColor: '#F4F2F2',
    '& .MuiTableCell-body:first-child': {
      borderLeft: '2px solid #515151'
    },
    '& .MuiTableCell-body:last-child': {
      borderRight: '2px solid #515151'
    }
  },
  lastOpenedChild: {
    '& .MuiTableCell-body': {
      borderBottom: '3px solid #515151'
    }
  },
  firstOpenedChild: {
    '& .MuiTableCell-body': {
      borderTopColor: '#515151'
    }
  },
  dropdownIcon: {
    // fontSize: "2rem"
    '& .MuiSvgIcon-root': {
      width: '1.4em',
      height: '1.4em'
    }
  },
  icon: {
    verticalAlign: 'middle'
  }
})

// eslint-disable-next-line react/prop-types
const EmptyTableRow = ({canApprove, canEdit, classes, date = null}) => {
  const isWeekOrHoliday = date ? isWeekend(new Date(date)) || isHoliday(new Date(date)) : false
  return(
    <TableRow className={clsx( isWeekOrHoliday ? classes.weekend : null)}>
      <TableCell className={classes.date}>{formatMonthDay(date)}</TableCell>
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      <TableCell> </TableCell>
      {canApprove && (
        <TableCell> </TableCell>
      )}
      {canEdit && (
        <TableCell> </TableCell>
      )}
      <TableCell> </TableCell>
    </TableRow>
  )
}

class UserRecords extends Component {

  constructor(props, context) {
    super(props, context)
    this.userId = this.props.userId
    this.state = {
      yearMonth: this.props.month,
      records: [],
      reviewProjects: [],
      opened: {},
      editedRecords: null,
      dialogOpen: false,
      vacationTypes: vacationsWithOffs,
      dialogVacationOpen: false,
      userContract: undefined,
      editedVacation: null
    }
    const user = authentication.getUser()
    if (user && this.userId === user.id) {
      const currentDate = formatISODate(new Date())
      this.state.opened[currentDate] = true
    }
    this.refresh = this.refresh.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    this.refresh(this.state.yearMonth)

    const user = authentication.getUser()
    request('GET', `/user/${user.id}/project`)
      .then(data => this.setState({reviewProjects: data.filter(d => d.role === 'MANAGER').map(d => d.project.id)}))
  }

  async refresh(yearMonth) {
    let multiData
    let duplicatedVacations = []
    const user = authentication.getUser()
    const userContract = getContractBasedOnDate(user, yearMonth)
    this.setState({userContract: userContract})

    await request('GET', `/user/${this.userId}/record/${yearMonth}`)
      .then(data => {
        multiData = data
      })
    const currentDate = new Date(yearMonth)
    await request('GET', `/user/${this.userId}/vacation/${formatISO(startOfMonth(currentDate), {representation: 'date'})}/${formatISO(endOfMonth(currentDate), {representation: 'date'})}`)
      .then(data => {
        if(_.isArray(data)){
          data.forEach(d => {
            const dateFrom = new Date(d.from)
            const dateUntil = new Date(d.until)
            const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())
            const utc2 = Date.UTC(dateUntil.getFullYear(), dateUntil.getMonth(), dateUntil.getDate())
            const diff = differenceInDays(utc2, utc1)

            d.duration = d.paidDuration
            d.objectType = 'vacation'
            d.origFrom = d.from
            d.origUntil = d.until
            d.project = {
              name: vacationTypeHiddenTranslation(d.type),
              shortcut: vacationShortcutTranslation(d.type)
            }

            for(let i = 0; i <= diff; i++){
              const clone = _.cloneDeep(d)
              const dateFrom = new Date(clone.origFrom)
              const newDate = new Date(dateFrom.setDate(dateFrom.getDate() + i))
              const isSameMonth = currentDate.getMonth() === newDate.getMonth()
              if(isSameMonth) {
                clone.date = formatISO(newDate, {representation: 'date'})
                clone.from = i > 0 ? '00:00' : formatHourMinute(d.origFrom)
                clone.until = (i < diff - 1) ? '23:59' : formatHourMinute(d.origUntil)
                duplicatedVacations.push(clone)
              }
            }
          })
        }

        multiData = [...multiData, ...duplicatedVacations]
      })

    let records = {}
    const daysInMonth = getDaysInMonth(new Date(yearMonth))
    const dateArr = yearMonth.split('-')
    for(let i = 1; i <= daysInMonth; i++){
      const day = i < 10 ? '0' + i : i
      const date = [...dateArr, day].join('-')
      records[date] = {records: [], duration: 0, approved: true, time: {min: null, max: null}}
    }
    if (_.isArray(multiData)) {
      multiData.forEach(rec => {
        // if (!_.has(records, rec.date)) {
        //   records[rec.date] = {records: [], duration: 0, approved: true, time: {min: rec.from, max: rec.until}}
        // }
        records[rec.date].duration += rec.duration
        if (rec.approved === null) {
          records[rec.date].approved = false
        }
        if (rec.from.length < records[rec.date].time.min || rec.from.localeCompare(records[rec.date].time.min) < 0) {
          records[rec.date].time.min = rec.from
        }
        if (rec.until.length > records[rec.date].time.max || rec.until.localeCompare(records[rec.date].time.max) > 0) {
          records[rec.date].time.max = rec.until
        }
        records[rec.date].records.push(rec)
      })
    }
    this.setState({records: records, yearMonth: yearMonth})

  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.userId = this.props.userId
      let opened = {}
      const user = authentication.getUser()
      if (user && this.userId === user.id) {
        const currentDate = formatISODate(new Date())
        opened[currentDate] = true
      }
      const userContract = getContractBasedOnDate(user, this.state.yearMonth)
      this.setState({opened: opened, userContract: userContract})
    }
    if (this.props.month !== prevProps.month || this.props.userId !== prevProps.userId) {
      this.refresh(this.props.month)
    }
  }

  handleEdit(records) {
    //edit single vacation
    if(!Array.isArray(records) && records.objectType){
      const vacRecord = _.cloneDeep(records)
      vacRecord.from = vacRecord.origFrom
      vacRecord.until = vacRecord.origUntil
      this.setState({
        dialogVacationOpen: true,
        editedVacation: vacRecord,
        vacationTypes: getFilteredVacationsArray(vacRecord.type)
      })
    } else{
      //edit multiple projects
      const editableRecords = _.cloneDeep(records)
      this.setState({
        dialogOpen: true,
        editedRecords: Array.isArray(editableRecords) ? editableRecords.filter(r => !r.objectType) : editableRecords
      })
    }
  }

  handleDelete(record) {
    const url = record.objectType && record.objectType === 'vacation' ? `/user/${this.userId}/vacation/${record.id}` : `/user/${this.userId}/record/${record.id}`

    request('DELETE', url)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('record_deleted_successfully'), {variant: 'success'})
        this.refresh(this.state.yearMonth)
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  handleChangeApproved(record) {
    const clonedRecord = _.clone(record)

    if(clonedRecord.objectType){
      clonedRecord.from = clonedRecord.origFrom
      clonedRecord.until = clonedRecord.origUntil
      clonedRecord.approved = clonedRecord.approved ? null : new Date()
    }

    const url = clonedRecord.objectType ? `/user/${this.userId}/vacation/${clonedRecord.id}` : `/user/${this.userId}/record/${clonedRecord.id}`
    clonedRecord.approvedBy = clonedRecord.approvedBy ? null : {id: authentication.getUser().id}
    request('PUT', url, clonedRecord)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.refresh(this.state.yearMonth)
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  handleApprove(date) {
    console.log(date)
    const recordUrl = `/user/${this.userId}/record/${date}/approve`
    request('PUT', recordUrl)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.refresh(this.state.yearMonth)
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })

    const vacationUrl = `/user/${this.userId}/vacation/${date}/approve`
    request('PUT', vacationUrl)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.refresh(this.state.yearMonth)
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }


  render() {
    const { classes, userId, t } = this.props
    const { records, dialogOpen, editedRecords, opened, reviewProjects, userContract, editedVacation, yearMonth, vacationTypes, dialogVacationOpen } = this.state

    const user = authentication.getUser()

    const canEdit = (user.isAdmin() || user.id === userId)
    const canApprove = (user.isAdmin() || reviewProjects.length > 0) && (user.id !== userId)
    const withOffs = getWithAllowedTypes(vacationsWithOffs, userContract?.type.name)
    const withDoctor = getWithAllowedTypes(vacationsWithDoctor, userContract?.type.name)
    const withSick = getWithAllowedTypes(vacationsWithSick, userContract?.type.name)

    return (
      <>
        <ResponsiveTable size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.mediumColumn}>{t('date')}</TableCell>
              <TableCell className={classes.smallColumn}>{t('from')}</TableCell>
              <TableCell className={classes.smallColumn}>{t('until')}</TableCell>
              <TableCell className={classes.largerColumn}>{t('projects')}</TableCell>
              <TableCell>Poznámka</TableCell>
              <TableCell align="center" className={classes.mediumColumn}>{t('hours_count')}</TableCell>
              <TableCell align="center" className={classes.mediumColumn}>{t('lunch')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('state')}</TableCell>
              {canApprove && (
                <TableCell align="center" className={classes.smallColumn}>{t('approve')}</TableCell>
              )}
              {canEdit && (
                <TableCell align="center" className={classes.extraSmallColumn}>{t('edit')}</TableCell>
              )}
              <TableCell align="center" className={classes.extraSmallColumn}>{t('detail')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {Object.keys(records).map(date => {
              return records[date].records.length === 0
                ? <EmptyTableRow
                  key={'date-' + formatDate(date) + '-empty'}
                  canEdit={canEdit}
                  canApprove={canApprove}
                  classes={classes}
                  date={date}
                />
                : <Fragment key={'date-' + formatDate(date)}>
                  <TableRow
                    className={clsx(classes.greyRow, _.has(opened, date) ? classes.openedParent : null, isWeekend(new Date(date)) || isHoliday(new Date(date)) ? classes.weekend : null)}>
                    <TableCell className={classes.date}>{formatMonthDay(date)}</TableCell>
                    <TableCell className={classes.greyCell}>{records[date].time.min}</TableCell>
                    <TableCell className={classes.greyCell}>{records[date].time.max}</TableCell>
                    <TableCell className={classes.greyCell}>
                      {records[date].records
                        .filter(r => r.project)
                        .map(r => r.project.shortcut)
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .join(', ')}
                    </TableCell>
                    <TableCell/>
                    <TableCell align="center" className={classes.greyCell}>{records[date].duration}</TableCell>
                    <TableCell align="center" className={classes.greyCell}>
                      {
                        records[date].duration > 6 && user.contract === 'HPP'
                          ? <CheckBoxOutlinedIcon className={classes.icon}
                            htmlColor={_.has(opened, date) ? 'white' : '#0E990B'}/>
                          : <CheckBoxOutlineBlankOutlinedIcon className={classes.icon}/>
                      }
                    </TableCell>
                    <TableCell align="center" className={classes.greyCell}>
                      {records[date].approved
                        ? <CheckBoxOutlinedIcon className={classes.icon}
                          htmlColor={_.has(opened, date) ? 'white' : '#0E990B'}/>
                        : <CheckBoxOutlineBlankOutlinedIcon className={classes.icon}/>
                      }
                    </TableCell>
                    {canApprove && (
                      <TableCell align="center">
                        {!records[date].approved && (
                          <IconButton size="small" onClick={() => this.handleApprove(date)}>
                            <DoneAll fontSize="inherit" htmlColor={_.has(opened, date) ? 'white' : 'black'}/>
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                    {canEdit && (
                      <TableCell align="center">
                        {!records[date].approved && records[date].records.filter(r => !r.objectType && !r.approved)?.length > 0 && (
                          <IconButton
                            size="small"
                            onClick={() => this.handleEdit(records[date].records)}>
                            <Edit fontSize="inherit" htmlColor={_.has(opened, date) ? 'white' : 'black'}/>
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="center" className={classes.extraSmallColumn}>
                      {_.has(opened, date) && (
                        <IconButton size="small" className={classes.dropdownIcon} onClick={() => {
                          let op = _.clone(opened)
                          delete op[date]
                          this.setState({opened: op})
                        }}>
                          <ExpandLess fontSize="inherit" htmlColor="white"/>
                        </IconButton>
                      )}
                      {!_.has(opened, date) && (
                        <IconButton size="small" className={classes.dropdownIcon} onClick={() => {
                          let op = _.clone(opened)
                          op[date] = true
                          this.setState({opened: op})
                        }}>
                          <ExpandMore fontSize="inherit" htmlColor="black"/>
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                  {_.has(opened, date) && records[date].records.map((row, index, {length}) => (
                    <TableRow key={`${row.id}-${row.objectType ? 'vacation' : 'record'}`} className={
                      clsx(_.has(opened, date) ? classes.openedChild : null,
                        index === length - 1 ? classes.lastOpenedChild : null,
                        index === 0 ? classes.firstOpenedChild : null)
                    }>
                      <TableCell/>
                      <TableCell>{row.from}</TableCell>
                      <TableCell>{row.until}</TableCell>
                      <TableCell>
                        {row.project !== null ? row.project.name : t('uncategorized')}
                      </TableCell>
                      <TableCell>
                        <Hidden lgUp>
                          {row.description && (
                            <Tooltip title={row.description}>
                              <IconButton size="small">
                                <Info fontSize="inherit"/>
                              </IconButton>
                            </Tooltip>
                          )}
                        </Hidden>
                        <Hidden mdDown>
                          {row.description}
                        </Hidden>
                      </TableCell>
                      <TableCell align="center">
                        {row.duration}
                      </TableCell>
                      <TableCell/>
                      <TableCell align="center">
                        {
                          row.approved
                            ? <CheckBoxOutlinedIcon className={classes.icon} htmlColor="#0E990B"/>
                            : <CheckBoxOutlineBlankOutlinedIcon className={classes.icon}/>
                        }
                      </TableCell>
                      {canApprove && (
                        (user.isAdmin() || (row.project && reviewProjects.includes(row.project.id))) && (
                          <TableCell align="center">
                            <IconButton size="small" onClick={() => this.handleChangeApproved(row)}>
                              {row.approved === null && (
                                <Check fontSize="inherit" htmlColor="black"/>
                              )}
                              {row.approved && (
                                <Clear fontSize="inherit" htmlColor="black"/>
                              )}
                            </IconButton>
                          </TableCell>
                        )
                      )}
                      {canEdit && (
                        <>
                          <TableCell align="center">
                            {/*{row.approved === null && !row.objectType && (*/}
                            {row.approved === null  && (
                              <IconButton size="small" onClick={() => this.handleEdit(row)}>
                                <Edit fontSize="inherit" htmlColor="black"/>
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="center" className={classes.extraSmallColumn}>
                            {row.approved === null && (
                              <IconButton size="small" onClick={() => this.handleDelete(row)}>
                                <Delete fontSize="inherit" htmlColor="black"/>
                              </IconButton>
                            )}
                          </TableCell>
                        </>
                      )}
                      {!canEdit && (
                        <TableCell/>
                      )}
                    </TableRow>
                  ))}
                </Fragment>
            })}
            <EmptyTableRow
              canEdit={canEdit}
              canApprove={canApprove}
              classes={classes}
            />
            <TableRow>
              <TableCell className={clsx(classes.greyCell, classes.lastRow)}>{t('summary')}</TableCell>
              <TableCell className={classes.lastRow}/>
              <TableCell className={classes.lastRow}/>
              <TableCell className={classes.lastRow}/>
              <TableCell className={classes.lastRow}/>
              <TableCell align="center" className={clsx(classes.greyCell, classes.lastRow)}>
                {_.sum(Object.keys(records).map(date => records[date].duration))}
              </TableCell>
              <TableCell className={classes.lastRow}/>
              <TableCell className={classes.lastRow}/>
              {canApprove && (
                <TableCell align="center" className={classes.lastRow}>
                  {Object.keys(records).map(date => records[date].approved).filter(a => a === false).length > 0 && (
                    <IconButton size="small" onClick={() => this.handleApprove(yearMonth)}>
                      <DateRange fontSize="inherit"/>
                      <DoneAll fontSize="inherit"/>
                    </IconButton>
                  )}
                </TableCell>
              )}
              {canEdit && (
                <TableCell className={classes.lastRow}/>
              )}
              <TableCell className={classes.lastRow}/>
            </TableRow>
          </TableBody>
        </ResponsiveTable>
        {canEdit && (
          <Box mt={2}>
            <Grid item xl={12}>
              <Grid container justifyContent="flex-end">
                {
                  userContract || user.isAdmin()
                    ?
                    <>
                      <Box>
                        <Button variant="contained" color="primary" onClick={() => this.setState({dialogOpen: true, editedRecords: null})}>
                          {t('add_record')}
                        </Button>
                      </Box>
                      <AddRecordDialog
                        open={dialogOpen}
                        userId={userId}
                        onSave={() => {
                          this.refresh(yearMonth);
                          this.setState({dialogOpen: false});
                        }}
                        onClose={() => this.setState({dialogOpen: false})}
                        record={editedRecords}
                      />
                    </>
                    : null
                }
                {
                  withOffs.types.length
                    ?
                    <Box ml={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<WorkOff/>}
                        onClick={() => this.setState({dialogVacationOpen: true, vacationTypes: withOffs, editedVacation: null})}
                      >
                        {withOffs.title}
                      </Button>
                    </Box>
                    : null
                }
                {
                  withDoctor.types.length
                    ?
                    <Box ml={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<HomeWork/>}
                        onClick={() => this.setState({dialogVacationOpen: true, vacationTypes: withDoctor, editedVacation: null})}
                      >
                        {withDoctor.title}
                      </Button>
                    </Box>
                    : null
                }
                {
                  withSick.types.length
                    ?
                    <Box ml={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<LocalHospital/>}
                        onClick={() => this.setState({dialogVacationOpen: true, vacationTypes: withSick, editedVacation: null})}
                      >
                        {withSick.title}
                      </Button>
                    </Box>
                    : null
                }
                {
                  userContract
                    ?
                    <AddVacationsDialog
                      open={dialogVacationOpen}
                      vacationTypes={vacationTypes}
                      user={user} //change this.user for user you want to edit (for admin page edit for example)
                      userContract={userContract}
                      onSave={() => {
                        this.refresh(yearMonth);
                        this.setState({dialogVacationOpen: false});
                      }}
                      onClose={() => this.setState({dialogVacationOpen: false})}
                      editedVacation={editedVacation}
                    />
                    : null
                }
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserRecords)))
