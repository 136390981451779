import React, {Component} from 'react'
import {request} from '../../services/api'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import SettingTranslation from '../../constants/SettingTranslation'
import {withSnackbar} from 'notistack'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
})

class Setting extends Component {
  constructor(props) {
    super(props)
    this.state = {settings: undefined, setting: undefined, dialogOpen: false}
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  handleEdit(row) {
    this.setState({
      setting: Object.assign({}, row),  //We have to create a copy of a data
      dialogOpen: true
    })
  }

  handleChange(e) {
    let setting = this.state.setting
    setting.value = e.target.value
    this.setState({
      setting: setting
    })
  }

  handleClose() {
    this.setState({
      dialogOpen: false
    })
  }

  refresh() {
    request('GET', '/setting').then(data => this.setState({settings: data}))
  }

  handleSave() {
    request('PUT', '/setting/'+ this.state.setting.key, this.state.setting)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  render() {
    const { classes, t } = this.props

    return (
      <>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('variable')}</TableCell>
              <TableCell align="right">{t('value')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.settings && this.state.settings.map(row => (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row">
                  {SettingTranslation(row.key)}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)} size="small">
                    <EditIcon fontSize="inherit"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        {this.state.setting && (
          <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
            <DialogTitle>Úprava nastavenia</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('type_new_value_for_settings')} {this.state.setting.key}.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="setting-value"
                onChange={this.handleChange}
                onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
                value={this.state.setting.value}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="secondary">
                {t('cancel')}
              </Button>
              <Button onClick={this.handleSave} color="primary">
                {t('save')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
}

export default withTranslation('settings')(withSnackbar(withStyles(styles)(Setting)))
