import React from 'react'
import {Router, Switch, Route, Redirect} from 'react-router-dom'
import { history } from './history'
import {authentication} from '../services/authentication'
import Home from '../pages/Home'
import Auth from '../pages/Auth'
import Dashboard from '../pages/Dashboad'
import NotFound from '../pages/NotFound'
import UserList from '../pages/User'
import User from '../pages/User/User'
import UserCreate from '../pages/User/UserCreate'
import ProjectList from '../pages/Project'
import ProjectCreate from '../pages/Project/ProjectCreate'
import Project from '../pages/Project/Project'
import ContractType from '../pages/Setting/ContractType'
import AllSettings from '../pages/Setting/AllSettings'
import WorkPlan from '../pages/WorkPlan'
import TimeSheet from '../pages/TimeSheet'
import Report from '../pages/Report'
import Review from '../pages/Review/Review'
import { RouteComponentProps } from 'react-router-dom'
import _ from 'lodash'


interface PrivateRouteProps {
  path: string
  component: any
  role?: string[] | string
  rest?: RouteComponentProps
  exact?: boolean
}

export const MyRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/' exact component={Home} />
        <PrivateRoute path='/dashboard' component={Dashboard} />
        <PrivateRoute path='/work-plan' exact component={WorkPlan} />
        <PrivateRoute path='/time-sheet' exact component={TimeSheet} />
        <PrivateRoute path='/review-time-sheet' exact component={Review} />
        <PrivateRoute path='/report' exact component={Report} />
        <PrivateRoute path='/project' exact role={['ADMIN', 'PROJECT_MANAGER']} component={ProjectList} />
        <PrivateRoute path='/project/create' exact role={['ADMIN', 'PROJECT_MANAGER']} component={ProjectCreate} />
        <PrivateRoute path='/project/:id' role={['ADMIN', 'PROJECT_MANAGER']} component={Project} />
        <PrivateRoute path='/user' exact role='ADMIN' component={UserList} />
        <PrivateRoute path='/user/create' exact role='ADMIN' component={UserCreate} />
        <PrivateRoute path='/user/:id' role='ADMIN' component={User} />
        <PrivateRoute path='/setting' exact role='ADMIN' component={AllSettings} />
        <PrivateRoute path='/setting/contract-type/:id' role='ADMIN' component={ContractType} />
        <Route path='/auth' component={Auth} />
        <Route path='*' component={NotFound} />
      </Switch>
    </Router>
  )
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <Route {...rest} render={(props) => {
    const currentUser = authentication.getUser(false)
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
    if (rest.role && currentUser.role !== rest.role) {
      if (!_.isArray(rest.role) || !rest.role.includes(currentUser.role)){
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }
    }
    // authorised so return component
    return <Component {...props} />
  }} />
)
