import _ from 'lodash'
import {VacationInnerObject} from '../components/FullReport';
import i18next from '../i18next';

const translations = {
  'doctor': i18next.t('vacations:DOCTOR'),
  'vac_off': i18next.t('vacations:VACATION_OFFICIAL'),
  'off': i18next.t('vacations:OFF'),
  'half_off': i18next.t('vacations:HALF_OFF'),
  'nat_hol': i18next.t('vacations:NATIONAL_HOLIDAY'),
  'sick': i18next.t('vacations:SICK_LEAVE'),
  'other': i18next.t('vacations:OTHER')
}

const vacationsTypeHidden: vacationsObjectTypes = {
  'DOCTOR': translations.doctor,
  'VACATION_OFFICIAL': translations.vac_off,
  'NATIONAL_HOLIDAY': translations.nat_hol,
  'OFF': translations.off,
  'HALF_OFF': translations.half_off,
  'SICK_LEAVE': translations.sick,
  'OTHER': translations.other
}

const vacationsShortcuts: vacationsObjectTypes = {
  'DOCTOR': '(DOK)',
  'VACATION_OFFICIAL': '(DOV)',
  'OFF': '(OFF)',
  'HALF_OFF': '(OFF/2)',
  'NATIONAL_HOLIDAY': '(SVA)',
  'SICK_LEAVE': '(NES)',
  'OTHER': '(JINÉ)'
}

export const vacations: vacationsObjectTypes = {
  'DOCTOR': translations.doctor,
  'VACATION_OFFICIAL': translations.vac_off,
  'NATIONAL_HOLIDAY': translations.nat_hol,
  'OFF': translations.off,
  'HALF_OFF': translations.half_off,
  'SICK_LEAVE': translations.sick,
  'OTHER': translations.other
}

const ALL = ['HPP', 'DPP', 'DPČ', 'OSVČ']

export const vacationsWithOffs = {
  withTime: false,
  showLimits: true,
  title: i18next.t('vacations:WITH_OFFS_TITLE'),
  types: [
    { id: 'VACATION_OFFICIAL', name: translations.vac_off, canUse: ['HPP'] },
    { id: 'OFF', name: translations.off, canUse: ALL },
    { id: 'HALF_OFF', name: translations.half_off, canUse: ALL },
    { id: 'NATIONAL_HOLIDAY', name: translations.nat_hol, canUse: ALL },
  ]
}

export const vacationsWithDoctor = {
  withTime: true,
  showLimits: false,
  title: i18next.t('vacations:WITH_DOCTOR_TITLE'),
  types: [
    { id: 'DOCTOR', name: translations.doctor, canUse: ['HPP'] },
    { id: 'OTHER', name: translations.other, canUse: ['HPP'] }
  ]
}

export const vacationsWithSick = {
  withTime: false,
  showLimits: false,
  title: i18next.t('vacations:WITH_SICK_TITLE'),
  types: [
    { id: 'SICK_LEAVE', name: translations.sick, canUse: ['HPP'] },
  ]
}

/**
 * @return {string}
 */
export function vacationTranslation(type: string) {
  if (type in vacations) {
    return vacations[type as keyof vacationsObjectTypes]
  }
  return i18next.t('vacations:UNKNOWN_TYPE')
}

/**
 * @return {string}
 */
export function vacationTypeHiddenTranslation(type: string) {
  if (type in vacationsTypeHidden) {
    return vacationsTypeHidden[type as keyof vacationsObjectTypes]
  }
  return i18next.t('vacations:UNKNOWN_TYPE')
}

/**
 * @return {string}
 */
export function vacationShortcutTranslation(type: string) {
  if (type in vacationsShortcuts) {
    return vacationsShortcuts[type]
  }
  return '?'
}

export function isHomeOffice(vacation: VacationInnerObject) {
  if (_.has(vacation, 'type')) {
    return vacation.type === 'HOME_OFFICE'
  }
  return false
}

// export function whatCanBeUsed(vacations, contract) {
//   return vacations.types.filter(t => t.canUse.includes(contract))
// }

export function getWithAllowedTypes(vacations: vacationTypes, contract: string){
  const newVacations = _.clone(vacations)
  newVacations.types = vacations.types.filter((t: { canUse: string[] }) => t.canUse.includes(contract))
  return newVacations
}

export function getFilteredVacationsArray(vacation: string){
  if(vacationsWithOffs.types.find(type => type.id === vacation)) return vacationsWithOffs
  if(vacationsWithDoctor.types.find(type => type.id === vacation)) return vacationsWithDoctor
  if(vacationsWithSick.types.find(type => type.id === vacation)) return vacationsWithSick
}

type vacationsObjectTypes = {
  [key: string]: string
}


type vacationTypes = typeof vacationsWithOffs | typeof vacationsWithDoctor | typeof vacationsWithSick

interface vacationOption {
  id: string
  name: string
  canUse: string[]
}

export interface vacationTypesInterface {
  withTime: boolean
  title: string
  showLimits: boolean
  types: vacationOption[]
}

export interface defaultVacationInterface {
  id?: null | number
  name: string
  from: string
  origFrom: string | undefined
  until: string
  origUntil: string | undefined
  type: string
  description: string
  approved: null | boolean
  paidDuration: number
}

export interface vacationInterface extends defaultVacationInterface{
  user: {
    id: number
  }
}
