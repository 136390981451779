export const workerRoles: workerRolesObject = {
  'MANAGER': 'projektový manažér',
  'WORKER': 'developer'
}

/**
 * @return {string}
 */
export function WorkerRoleTranslation(state: string) {
  if (state in workerRoles) {
    return workerRoles[state]
  }
  return 'neznáma'
}

type workerRolesObject = {
  [key: string]: string
}
