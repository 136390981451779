import React, {Component} from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import UserBaseInfo from './UserBaseInfo'
import UserChildren from './UserChildren'
import UserFiles from './UserFiles'
import {
  Attachment, AttachMoney,
  ChildCare,
  Info,
  LocalActivity, LocalAtm, LocalHospital,
  // Schedule,
  SportsEsports
} from '@material-ui/icons'
import Block from '../../components/Block'
import UserContracts from './UserContracts'
import UserRewards from './UserRewards'
import UserVacations from './UserVacations'
import UserBenefits from './UserBenefits'
import UserReports from './UserReports'
import {withTranslation} from 'react-i18next';
// import UserRecords from './UserRecords'
// import Box from '@material-ui/core/Box'
// import DateSelect from '../../components/DateSelect'
// import {formatISOYearMonth} from '../../utils/datetime'

class User extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      date: new Date(),
      headerInit: this.props.t('user'),
      header: this.props.t('user')
    }
    this.setAdditionalHeaderText = this.setAdditionalHeaderText.bind(this)
  }

  setAdditionalHeaderText(text){
    this.setState({header: `${this.state.headerInit} ${text}`})
  }

  render() {
    const {header} = this.state
    const userId = this.props.match.params.id
    const { t } = this.props
    return (
      <MenuAppBar>
        <Block header={header} returnLink="/user" tabs={[
          {label: t('basic_info'), icon: (<Info />)},
          // {label: t('reports'), icon: (<Schedule />)},
          {label: t('payouts'), icon: (<AttachMoney />)},
          {label: t('vacations'), icon: (<LocalHospital />)},
          {label: t('benefits'), icon: (<SportsEsports />)},
          {label: t('rewards'), icon: (<LocalActivity />)},
          {label: t('files'), icon: (<Attachment />)},
          {label: t('kids'), icon: (<ChildCare />)},
          {label: t('contracts'), icon: (<LocalAtm />)},
        ]} tabsContents={[
          (<UserBaseInfo key="base" userId={userId} setAdditionalHeaderText={this.setAdditionalHeaderText} />),
          // (
          //   <Box key="records">
          //     <Box>
          //       <DateSelect variant="yearMonth" date={date} onChange={d => this.setState({date: d})}/>
          //     </Box>
          //     <UserRecords userId={userId} month={formatISOYearMonth(date)}/>
          //   </Box>
          // ),
          (<UserReports key="reports" userId={userId}/>),
          (<UserVacations key="vacations" userId={userId}/>),
          (<UserBenefits key="benefits" userId={userId}/>),
          (<UserRewards key="rewards" userId={userId}/>),
          (<UserFiles key="files" userId={userId}/>),
          (<UserChildren key="children" userId={userId}/>),
          (<UserContracts key="contracts" userId={userId}/>),
        ]}/>
      </MenuAppBar>
    )
  }
}

export default withTranslation('user')(User)
