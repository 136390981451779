import React from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import {Description, LocalAtm, LocalOffer, Tune} from '@material-ui/icons'
import Block from '../../components/Block'
import Skill from './Skill'
import ContractTypeList from './ContractTypeList'
import BenefitTemplate from './BenefitTemplate'
import Setting from './Setting'
import {useTranslation} from 'react-i18next';

const AllSettings = () => {
  const { t } = useTranslation('settings')
  return (
    <MenuAppBar>
      <Block header={t('header')} tabs={[
        {label: t('contract_types_tab'), icon: (<LocalAtm/>)},
        {label: t('benefits_tab'), icon: (<Description/>)},
        {label: t('skills_tab'), icon: (<LocalOffer/>)},
        {label: t('variables_tab'), icon: (<Tune/>)},
      ]} tabsContents={[
        (<ContractTypeList key="contractTypes"/>),
        (<BenefitTemplate key="benefitTemplates"/>),
        (<Skill key="skills"/>),
        (<Setting key="settings"/>),
      ]}/>
    </MenuAppBar>
  )

}

export default AllSettings
