import {differenceInCalendarDays, format, parseISO, formatISO, compareAsc} from 'date-fns'
import skLocale from 'date-fns/locale/sk'
import _ from 'lodash/lang'
import i18next from 'i18next';
import csLocale from 'date-fns/locale/cs';
import {CzechHolidays} from 'czech-holidays';

const locale = {locale: i18next.language === 'sk' ? skLocale : csLocale}

export function parseDatetime(datetime) {
  if (typeof datetime === 'string') {
    return parseISO(datetime)
  }
  return datetime
}

export function formatYear(datetime) {
  if (datetime === null) return null
  const dt = parseDatetime(datetime)
  return format(dt, 'Y')
}

export function formatDate(datetime) {
  if (datetime === null) return null
  const dt = parseDatetime(datetime)
  return format(dt, 'd. M. Y', locale)
}

export function formatMonthDay(datetime) {
  if (datetime === null) return null
  const dt = parseDatetime(datetime)
  return format(dt, 'd. M.', locale)
}


export function formatISODate(datetime) {
  if (datetime === null) return null
  return formatISO(parseDatetime(datetime), {representation: 'date'})
}

export function formatTime(datetime) {
  const dt = parseDatetime(datetime)
  return format(dt, 'H:mm:ss', locale)
}

export function formatHourMinute(datetime) {
  const dt = parseDatetime(datetime)
  return format(dt, 'HH:mm', locale)
}

export function formatYearMonth(datetime) {
  const dt = parseDatetime(datetime)
  return format(dt, 'LLLL Y', locale)
}

export function formatISOYearMonth(datetime) {
  if (datetime === null) return null
  const dt = parseDatetime(datetime)
  return formatISO(dt, {representation: 'date'}).substring(0,7)
}

export function formatDatetime(datetime) {
  if (datetime === null) return null
  const dt = parseDatetime(datetime)
  return format(dt, 'd. M. Y H:mm:ss', locale)
}

export function formatDatetimeOps(datetime, withTime) {
  if (withTime) {
    return formatDatetime(datetime)
  }
  return formatDate(datetime)
}

export function isSameDate(a, b) {
  let adt = parseDatetime(a)
  let bdt = parseDatetime(b)
  return differenceInCalendarDays(adt, bdt) === 0
}

export function isDateBetween(date, from, until) {
  if (_.isUndefined(from) || _.isUndefined(until)) {
    return false
  }
  return isSameDate(from, date) || isSameDate(until, date) ||
    (compareAsc(from, date) === compareAsc(date, until))
}

export function isPlanOn(plan, date) {
  if (plan.objectType === 'plan')
    return isSameDate(plan.date, date)
    
  const from = parseISO(plan.from)
  const until = parseISO(plan.until)
  
  return isDateBetween(date, from, until) ||
    isDateBetween(date, from, until)
}

export const isHoliday = (date) => {
  if(!date) return false
  const month = date.getMonth() + 1
  const day = date.getDate()
  return !!CzechHolidays(date.getFullYear()).find(date => date.d === day && date.m === month)
}
