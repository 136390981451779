export const REQUEST_STARTED = 'REQUEST_STARTED'
export const REQUEST_FINISHED = 'REQUEST_FINISHED'

export function requests(state = [], action) {
  switch (action.type) {
  case REQUEST_STARTED:
    return [
      ...state,
      {
        id: action.id
      }
    ]
  case REQUEST_FINISHED:
    return state.filter((request) => {
      return request.id !== action.id
    })
  default:
    return state
  }
}
