import React, {Component} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import {request} from '../../services/api'
import Box from '@material-ui/core/Box'
import {withSnackbar} from 'notistack'
import {Delete, Edit} from '@material-ui/icons'
import {formatISOYearMonth, formatYearMonth, parseDatetime} from '../../utils/datetime'
import Grid from '@material-ui/core/Grid'
import {currencyFormat} from '../../utils/currency'
import Button from '@material-ui/core/Button'
import {authentication} from '../../services/authentication'
import _ from 'lodash/lang'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import DialogActions from '@material-ui/core/DialogActions'
import skLocale from 'date-fns/locale/sk'
import Typography from '@material-ui/core/Typography'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';
import csLocale from 'date-fns/locale/cs';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
})

class UserReports extends Component {
  reportDefault = {
    month: formatISOYearMonth(new Date()),
    description: '',
    paid: 0,
    benefits: 0,
    lunchesContribution: 0
  }

  constructor(props, context) {
    super(props, context)
    this.userId = this.props.userId
    this.state = {
      reports: [],
      report: this.reportDefault,
      balance: {
        salary: 0,
        benefits: 0
      },
      dialogOpen: false,
      updating: false
    }
    this.refresh = this.refresh.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    request('GET', '/user/'+this.userId+'/report-full')
      .then(data => {
        let report = _.clone(this.state.report)
        report.paid = data.balance.salary
        this.reportDefault.paid = data.balance.salary
        this.setState({balance: data.balance, report})
      })
    request('GET', '/user/'+this.userId+'/report')
      .then(data => this.setState({reports: data}))
  }

  handleCreate() {
    this.setState({
      dialogOpen: true,
      report: _.clone(this.reportDefault),
      updating: false
    })
    request('GET', '/user/'+this.userId+'/report/'+this.reportDefault.month+'/prepare').then(data => {
      let report = _.clone(this.state.report)
      report.paid =  this.state.balance.salary + data.lunchesContribution
      report.lunchesContribution = data.lunchesContribution
      report.benefits = data.benefits
      this.setState({report})
    })
  }

  handleEdit(report) {
    this.setState({
      dialogOpen: true,
      report: _.clone(report),
      updating: true
    })
  }

  handleDelete(report) {
    request('DELETE', '/user/'+this.userId+'/report/'+report.month)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('report_deleted_successfully'), {variant: 'success'})
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  handleSave() {
    const {report, updating} = this.state
    let method = (updating ? 'PUT' : 'POST')
    let url = '/user/'+this.userId+'/report/'+(updating ?  report.month : '')
    request(method, url, report)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error, {variant: 'error'})
      })
  }

  handleClose() {
    this.setState({
      dialogOpen: false
    })
  }

  handleChangeDate(date) {
    try {
      request('GET', '/user/'+this.userId+'/report/'+formatISOYearMonth(date)+'/prepare').then(data => {
        let report = _.clone(this.state.report)
        report.month = formatISOYearMonth(date)
        report.paid =  this.state.balance.salary + data.lunchesContribution
        report.lunchesContribution = data.lunchesContribution
        report.benefits = data.benefits
        this.setState({report})
      })
    }
    catch (e) {
      //ok
    }
  }

  render() {
    const { classes, t, i18n } = this.props
    const { reports, report, balance } = this.state
    const user = authentication.getUser()

    return (
      <>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Typography variant="body1">
              {t('actual_state_of_budget')} {currencyFormat(balance.benefits)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {t('actual_state_of_unpaid_money')} {currencyFormat(balance.salary)}
            </Typography>
          </Grid>
        </Grid>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('month')}</TableCell>
              <TableCell>{t('note')}</TableCell>
              <TableCell align="right">{t('benefit_budget')}</TableCell>
              <TableCell align="right">{t('allowance_for_lunch')}</TableCell>
              <TableCell align="right">{t('paid')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('eidt')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map(row => (
              <TableRow key={formatISOYearMonth(row.month)}>
                <TableCell>{formatYearMonth(row.month)}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="right">{(row.benefits > 0 ? '+' : '') + currencyFormat(row.benefits)}</TableCell>
                <TableCell align="right">{currencyFormat(row.lunchesContribution)}</TableCell>
                <TableCell align="right">{currencyFormat(row.paid)}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)}>
                    <Edit fontSize="small"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)}>
                    <Delete fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        {user.isAdmin() && (
          <Box mt={2}>
            <Grid item xl={12}>
              <Grid container justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={this.handleCreate}>
                  {t('add_salary')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>Report</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'sk' ? skLocale : csLocale}>
              <DatePicker
                fullWidth
                variant="inline"
                openTo="year"
                views={['year', 'month']}
                label={t('salary_per_month')}
                value={report.month ? parseDatetime(report.month+'-01') : null}
                onChange={this.handleChangeDate}
                autoOk
              />
            </MuiPickersUtilsProvider>
            <TextField
              margin="dense"
              id="report-description"
              label={t('note')}
              onChange={e => {
                let r = _.clone(report)
                r.description = e.target.value
                this.setState({report: r})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={report.description}
              fullWidth
            />
            <TextField
              margin="dense"
              id="report-paid"
              label={t('paid')}
              type="number"
              onChange={e => {
                let b = _.cloneDeep(report)
                b.paid = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({report: b})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={report.paid}
              fullWidth
            />
            <TextField
              margin="dense"
              id="report-benefits"
              label={t('benefit_budget')}
              type="number"
              onChange={e => {
                let b = _.cloneDeep(report)
                b.benefits = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({report: b})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={report.benefits}
              fullWidth
            />
            <TextField
              margin="dense"
              id="report-lunches-contribution"
              label={t('allowance_for_lunch')}
              type="number"
              onChange={e => {
                let b = _.cloneDeep(report)
                b.lunchesContribution = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({report: b})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={report.lunchesContribution}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserReports)))
