import React, {useState} from 'react'
import {authentication} from '../../services/authentication'
import MenuAppBar from '../../components/MenuAppBar'
import Block from '../../components/Block'
import FullReport from '../../components/FullReport'
import DateSelect from '../../components/DateSelect'
import {useTranslation} from 'react-i18next';

const Report = () => {
  const { t } = useTranslation('sections', { keyPrefix: 'report' })
  const [date, setDate] = useState(new Date());
  const user = authentication.getUser()

  if(!user){
    return
  }

  return (
    <MenuAppBar>
      <Block header={t('overview') as string} headerContent={(
        <DateSelect variant="yearMonth" date={date} onChange={d => setDate(d)}/>
      )}>
        <FullReport userId={user.id} month={date}/>
      </Block>
    </MenuAppBar>
  )
}

export default Report
