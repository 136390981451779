import React, {Component} from 'react'
import {request} from '../../services/api'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import DeleteIcon from '@material-ui/icons/Delete'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {history} from '../../config/history'
import {withSnackbar} from 'notistack'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
})

class ContractTypeList extends Component {
  typeDefault = {
    name: '',
    benefitSettings: []
  }

  constructor(props) {
    super(props)
    this.state = {types: [], type: this.typeDefault, dialogOpen: false}
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.refresh = this.refresh.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  handleChange(e) {
    let type = this.state.type
    type.name = e.target.value
    this.setState({
      type: type
    })
  }

  handleClose() {
    this.setState({
      dialogOpen: false
    })
  }

  handleCreate() {
    this.setState({
      type: this.typeDefault,
      dialogOpen: true
    })
  }

  handleDelete(row) {
    request('DELETE', '/contract-type/'+row.id)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('contract_type_deleted_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  refresh() {
    request('GET', '/contract-type').then(data => this.setState({types: data}))
  }

  handleSave() {
    request('POST', '/contract-type', this.state.type)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('contract_type_created_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  render() {
    const { classes, t } = this.props

    return (
      <>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.types.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => history.push('/setting/contract-type/'+row.id)} size="small">
                    <EditIcon fontSize="inherit"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)} size="small">
                    <DeleteIcon fontSize="inherit"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        <Box mt={2}>
          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={this.handleCreate}>
                {t('add_type')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>{t('new_contract_type')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('fill_new_contract_type')}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="type-name"
              onChange={this.handleChange}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={this.state.type.name}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('settings')(withSnackbar(withStyles(styles)(ContractTypeList)))
