import React, {Component} from 'react'
import {request} from '../../services/api'
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { roles } from '../../constants/UserRoleTranslation'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import {history} from '../../config/history'
import {withSnackbar} from 'notistack'
import {withTranslation} from 'react-i18next';

const styles = (theme) => ({
  smallColumn: {
    width: '100px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  margin: {
    margin: theme.spacing(1),
  },
})

const languages = {
  'cs': 'Čeština',
  'sk': 'Slovenština'
}

class UserBaseInfo extends Component {
  userDefault = {
    firstName: '',
    lastName: '',
    nickName: '',
    email: '',
    idNumber: '',
    position: '',
    workingHours: '',
    taxBreaks: '',
    note: '',
    lang: '',
    bank: {
      number: '',
      code: ''
    },
    address: {
      city: '',
      street: '',
      number: '',
      code: ''
    },
    mobile: {
      work: '',
      private: ''
    },
    birthDate: null,
    role: 'EMPLOYEE',
    skills: [],
    active: true
  }

  constructor(props) {
    super(props)
    this.userId = props.userId
    this.state = {user: this.userDefault, skills: []}
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    request('GET', '/skill').then(data => this.setState({skills: data}))
    this.refresh()
  }

  refresh() {
    if (this.userId) {
      request('GET', '/user/' + this.userId).then(data => {
        this.setState({user: data})
        this.props.setAdditionalHeaderText(` - ${data?.firstName} ${data?.lastName}`)
      })
    }
  }

  handleSave() {
    const { user } = this.state
    let create = (typeof user.id === 'undefined')
    let method = (create ? 'POST' : 'PUT')
    let endpoint = (create ? '/user' : '/user/'+ this.userId)
    let successMessage = (create ? this.props.t('user_created_successfully') : this.props.t('changes_saved_successfully'))
    let errorPartMessage = (create ? this.props.t('creating') : this.props.t('saving'))
    request(method, endpoint, user)
      .then((data) => {
        this.props.enqueueSnackbar(successMessage, {variant: 'success'})
        if (create) {
          history.push('/user/'+data.id)
        } else{
          this.refresh()
        }
      })
      .catch((error) => {
        // this.props.enqueueSnackbar('Nastala chyba pri '+errorPartMessage+': ' +error.reason + error.additionalText, {variant: 'error'})
        this.props.enqueueSnackbar(this.props.t('error_occurred_when') + errorPartMessage+': ' +error.reason, {variant: 'error'})
      })
  }

  handleChange(e) {
    let elementId = e.target.id
    let elements = elementId.split('-')
    if (elements[0] === 'user') {
      let user = Object.assign({}, this.state.user)
      if (elements.length === 2) {
        user[elements[1]] = e.target.value
        this.setState({user: user})
        return
      }
      else if (elements.length === 3) {
        switch (elements[1]) {
        case 'bank':
        case 'mobile':
        case 'address':
          user[elements[1]][elements[2]] = e.target.value
          this.setState({user: user})
          return
        default:
          break
        }
      }
    }
    this.props.enqueueSnackbar(this.props.t('error_when_changing_data'), {variant: 'error'})
  }

  render() {
    const { classes, t } = this.props
    const { user } = this.state
    let skills = []
    if (this.state.skills) {
      this.state.skills.forEach(skill => {skills[skill.id] = skill.name})
    }

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xl={6}>
            <Box>
              <Typography variant="h5">{t('')}
                {t('basic_info')}
              </Typography>
              <TextField id="user-firstName" label={t('first_name')} fullWidth margin="dense" value={user.firstName} onChange={this.handleChange}/>
              <TextField id="user-lastName" label={t('last_name')} fullWidth margin="dense" value={user.lastName} onChange={this.handleChange}/>
              <TextField id="user-nickName" label={t('nickname')} fullWidth margin="dense" value={user.nickName} onChange={this.handleChange}/>
              <TextField id="user-email" label={t('email')} fullWidth margin="dense" value={user.email} onChange={this.handleChange}/>
              <TextField id="user-idNumber" label={t('id_card_number')} fullWidth margin="dense" value={user.idNumber} onChange={this.handleChange}/>
              <TextField id="user-position" label={t('position')} fullWidth margin="dense" value={user.position} onChange={this.handleChange}/>
              <TextField id="user-workingHours" label={t('working_hours')} fullWidth margin="dense" value={user.workingHours} onChange={this.handleChange}/>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="user-birth-date"
                  label={t('birth_date')}
                  format="yyyy-MM-dd"
                  value={user.birthDate}
                  onChange={(d) => {
                    try {
                      let u = Object.assign(this.state.user, {birthDate: d.toISOString().substring(0,10)})
                      this.setState({user: u})
                    }
                    catch (e) {
                      //ok
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormControl fullWidth margin="dense">
                <InputLabel id="user-role-label">{t('role_in_system')}</InputLabel>
                <Select
                  labelId="user-role-label"
                  id="user-role"
                  value={user.role}
                  onChange={(e) => {
                    let u = Object.assign(this.state.user, {role: e.target.value})
                    this.setState({user: u})
                  }}
                >
                  {Object.keys(roles).map(role => (
                    <MenuItem key={'user-role-'+role} value={role}>{roles[role]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="user-language-label">{t('user_language')}</InputLabel>
                <Select
                  labelId="user-language-label"
                  id="user-language"
                  value={user.lang}
                  onChange={(e) => {
                    let u = Object.assign(this.state.user, {lang: e.target.value})
                    this.setState({user: u})
                  }}
                >
                  {Object.keys(languages).map(language => (
                    <MenuItem key={'user-role-'+language} value={language}>{languages[language]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField id="user-taxBreaks" label={t('tax_credits')} fullWidth margin="dense" value={user.taxBreaks} onChange={this.handleChange}/>
              <TextField mb={3} id="user-note" label={t('note')} fullWidth margin="dense" value={user.note} onChange={this.handleChange}/>
            </Box>
            <Box mt={3}>
              <Typography variant="h5">
                {t('skills')}
              </Typography>
              <FormControl fullWidth margin="dense">
                <InputLabel id="user-skills-label">{t('skills')}</InputLabel>
                <Select
                  labelId="user-skills-label"
                  id="user-skills"
                  multiple
                  value={user.skills.map(skill => skill.id.toString())}
                  onChange={(e) => {
                    let skills = e.target.value.map(v => { return {id: parseInt(v)} })
                    let user = Object.assign(this.state.user, {skills: skills})
                    this.setState({user: user})
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={skills[value]} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {Object.keys(skills).map(skill => (
                    <MenuItem key={skill} value={skill}>
                      {skills[skill]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xl={6}>
            <Box mb={3}>
              <Typography variant="h5">
                {t('bank_account')}
              </Typography>
              <TextField id="user-bank-number" label={t('bank_number')} fullWidth margin="dense" value={user.bank.number} onChange={this.handleChange}/>
              <TextField id="user-bank-code" label={t('bank_code')} fullWidth margin="dense" value={user.bank.code} onChange={this.handleChange}/>
            </Box>
            <Box my={3}>
              <Typography variant="h5">
                {t('address')}
              </Typography>
              <TextField id="user-address-city" label={t('address_city')} fullWidth margin="dense" value={user.address.city} onChange={this.handleChange}/>
              <TextField id="user-address-street" label={t('address_street')} fullWidth margin="dense" value={user.address.street} onChange={this.handleChange}/>
              <TextField id="user-address-number" label={t('address_number')} fullWidth margin="dense" value={user.address.number} onChange={this.handleChange}/>
              <TextField id="user-address-code" label={t('address_code')} fullWidth margin="dense" value={user.address.code} onChange={this.handleChange}/>
            </Box>
            <Box mt={3}>
              <Typography variant="h5">
                {t('mobile_contact')}
              </Typography>
              <TextField id="user-mobile-work" label={t('work_mobile')} fullWidth margin="dense" value={user.mobile.work} onChange={this.handleChange}/>
              <TextField id="user-mobile-private" label={t('personal_mobile')} fullWidth margin="dense" value={user.mobile.private} onChange={this.handleChange}/>
            </Box>
          </Grid>
          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" className={classes.margin} onClick={this.handleSave}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserBaseInfo)))
