import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import {history} from '../config/history'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

interface MenuElementProps {
  link: string
  text: string
  icon: React.ReactElement
}

const MenuElement = ({ link, icon, text }: MenuElementProps) => {
  return (
    <ListItem button onClick={() => history.push(link)} selected={history.location.pathname.startsWith(link)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text}/>
    </ListItem>
  )
}

export default MenuElement
