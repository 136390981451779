const settings = {
  'VACATION_LIMIT_OFFICIAL_DEFAULT': 'počet dní oficiálnej dovolenky',
  'VACATION_LIMIT_UNOFFICIAL_DEFAULT': 'počet dní neoficiálnej dovolenky',
  'LUNCH_CONTRIBUTION': 'príspevok na obed',
  'LUNCH_CONTRIBUTION_MIN_HOUR': 'minimálny počet odpracovaných hodín pre príspevok na obed'
}

/**
 * @return {string}
 */
function SettingTranslation(state) {
  if (state in settings) {
    return settings[state]
  }
  return 'neznáme nastavenie'
}

export default SettingTranslation