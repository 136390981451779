import React from 'react'
import MenuAppBar from '../../components/MenuAppBar'
import ProjectBaseInfo from './ProjectBaseInfo'
import Block from '../../components/Block'

const ProjectCreate = () => {
  return (
    <MenuAppBar>
      <Block header={'Nový projekt'} returnLink="/project">
        <ProjectBaseInfo />
      </Block>
    </MenuAppBar>
  )
}

export default ProjectCreate
