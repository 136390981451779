import React, {Component} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const styles = () => ({

})

class CheckboxModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: ''
    }

    this.handleFilter = this.handleFilter.bind(this)
    this.selectItems = this.selectItems.bind(this)
  }



  selectItems(items) {
    const { filter } = this.state
    const sanitize = str => str.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')

    if (filter === '')
      return items
    else {
      const parts = filter.trim().split(/\s+/g)
      const rexp = parts.map(p => new RegExp(sanitize(p), 'g'))

      return items.filter(option => {
        return rexp.find(re => sanitize(option.name).match(re) || (option.shortcut && sanitize(option.shortcut).match(re)))
      })
    }
  }

  handleFilter(e) {
    this.setState({ filter: e.target.value })
  }

  render() {
    const { options, title, open, searchInput, selected, handleChange, handleClick } = this.props
    const items = this.selectItems(options)
    const result = selected || []
    return <Dialog
      maxWidth="xs"
      aria-labelledby="skill-dialog-title"
      open={open}
    >

      <DialogTitle id="skill-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {searchInput && <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="filter-items">{searchInput}</InputLabel>
          <OutlinedInput
            id="filter-items"
            labelWidth={100}
            onChange={this.handleFilter}
            value={this.state.filter}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>}
        <FormGroup>
          {items.map(option => {
            const checked = result.indexOf(option.id) > -1
            return <FormControlLabel value={option.id} key={option.name} control={<Checkbox onChange={handleClick} checked={checked} />} label={<span>{option.shortcut ? <span style={{ fontWeight: 'bold'}}>{option.shortcut}</span>: null} {option.name}</span>} />
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleChange.bind(null, result)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  }

}
export default withStyles(styles)(CheckboxModal)
