import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import cs from './config/i18n/cs/cs'
import sk from './config/i18n/sk/sk'
import {authentication} from './services/authentication'

export const defaultNS = 'sections'
export const resources = {
  cs, sk
} as const

const user = authentication.getUser(false)
const userLanguage = user ? user.lang : ''

i18next
  .use(initReactI18next)


if (!i18next.isInitialized) {

  i18next
    .init({
      ns: ['sections'],
      fallbackLng: 'cs',
      lng: userLanguage,
      load: 'languageOnly',
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: true
      },
      defaultNS,
      resources,
      debug: false
    })
}

export default i18next

