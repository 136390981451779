import React, {useCallback, useEffect, useState} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {Dialog, makeStyles} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {request} from '../../services/api'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {workerRoles, WorkerRoleTranslation} from '../../constants/WorkerRoleTranslation'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import {useSnackbar} from 'notistack'
import ResponsiveTable from '../../components/ResponsiveTable'
import {useTranslation} from 'react-i18next'
import _ from 'lodash'
import {UserInfo} from '../../utils/userInfo'

const useStyles = makeStyles(() => ({
  smallColumn: {
    width: '100px'
  },
}))

const workerDefault: WorkerInterface = {
  user: {
    id: 0,
    firstName: '',
    lastName: ''
  },
  role: 'WORKER'
}

interface WorkerInterface {
  user: {
    id: number
    firstName: string
    lastName: string
  },
  role: string
}

interface ProjectWorkersProps {
  projectId: string
}

type usersArrType = {
  [key: number]: string
}[]

const ProjectWorkers = ({projectId}: ProjectWorkersProps) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('project')

  const [workers, setWorkers] = useState([] as WorkerInterface[])
  const [users, setUsers] = useState([] as UserInfo[])
  const [worker, setWorker] = useState(workerDefault)
  const [creating, setCreating] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const refresh = useCallback(() => {
    request('GET', '/project/'+projectId+'/worker').then(data => setWorkers(data))
  }, [projectId])

  useEffect(() => {
    request('GET', '/user').then(data => setUsers(data.filter((u: UserInfo) => u.active)))
    refresh()

    return () => {
    //TODO cancel pending request
    };
  }, [refresh])

  const handleEdit = (row: WorkerInterface) => {
    setDialogOpen(true)
    setCreating(false)
    setWorker(_.clone(row))
  }

  const handleCreate = () => {
    setDialogOpen(true)
    setCreating(true)
    setWorker(workerDefault)
  }

  const handleDelete = (row: WorkerInterface) => {
    request('DELETE', '/project/'+projectId+'/worker/'+row.user.id)
      .then(() => {
        enqueueSnackbar(t('kid_removed'), {variant: 'success'})
        setDialogOpen(false)
        refresh()
      })
      .catch((error) => {
        enqueueSnackbar(t('error_when_removing') + error.reason, {variant: 'error'})
      })
  }

  const handleSave = () => {
    if (!worker.user?.id) {
      enqueueSnackbar(t('worker_must_be_filled'), {variant: 'error'})
      return
    }

    const method = (creating ? 'POST' : 'PUT')
    const url = '/project/'+projectId+'/worker/'+(worker.user.id ?  worker.user.id : '')
    request(method, url, worker)
      .then(() => {
        enqueueSnackbar(t('edited_successfully'), {variant: 'success'})
        setDialogOpen(false)
        refresh()
      })
      .catch((error) => {
        enqueueSnackbar(t('error_when_saving') + error.reason, {variant: 'error'})
      })
  }

  const handleClose = () => {
    setDialogOpen(false)
    setCreating(false)
    setWorker(workerDefault)
  }

  let usersArr: usersArrType = []
  if (users) {
    users.forEach(user => {usersArr[user.id] = user.firstName + ' ' + user.lastName})
  }

  const workersIds: number[] = []
  workers.forEach(worker => {workersIds[worker.user.id] = worker.user.id})

  return (
    <>
      <ResponsiveTable size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('user')}</TableCell>
            <TableCell>{t('role')}</TableCell>
            <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
            <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workers && workers.map(row => (
            <TableRow key={row.user.id}>
              <TableCell>{row.user.firstName} {row.user.lastName}</TableCell>
              <TableCell>{WorkerRoleTranslation(row.role)}</TableCell>
              <TableCell align="center">
                <IconButton onClick={() => handleEdit(row)}>
                  <EditIcon fontSize="small"/>
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => handleDelete(row)}>
                  <DeleteIcon fontSize="small"/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ResponsiveTable>
      <Box mt={2}>
        <Grid item xl={12}>
          <Grid container justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={() => handleCreate()}>
              {t('add_employee')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={dialogOpen} onClose={() => handleClose()}>
        <DialogTitle>{t('employee')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense" disabled={!creating}>
            <InputLabel id="worker-user-label">{t('employee')}</InputLabel>
            <Select
              labelId="worker-user-label"
              id="worker-user"
              value={worker.user.id ? worker.user.id : ''}
              onChange={(e) => {
                const u = Object.assign(Object.assign({}, worker), {user: {id: parseInt(e.target.value as string)}})
                setWorker(u)
              }}
            >
              {Object.keys(usersArr)
                .filter(id => (parseInt(id) === worker.user.id || typeof workersIds[parseInt(id)] === 'undefined'))
                .map(id => (
                  <MenuItem key={'worker-user-'+id} value={id}>{usersArr[parseInt(id)]}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="worker-role-label">{t('role')}</InputLabel>
            <Select
              labelId="worker-role-label"
              id="worker-role"
              value={worker.role}
              onChange={(e) => {
                const newWorker = _.clone(worker)
                newWorker.role = e.target.value as string
                setWorker(newWorker)
              }}
            >
              {Object.keys(workerRoles).map(id => (
                <MenuItem key={'worker-user-'+id} value={id}>{workerRoles[id]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={() => handleSave()} color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ProjectWorkers
