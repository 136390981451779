import React, {Component} from 'react'
import { lighten } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import skLocale from 'date-fns/locale/sk'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { format } from 'date-fns'
import CheckboxModal from './CheckboxModal'
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from 'react-i18next';
import csLocale from 'date-fns/locale/cs';

const styles = (theme) => ({
  label: {
    marginRight: '4px'
  },
  labelIcon: {
    backgroundColor: '#515151',
    color: 'white',
    padding: '4px',
    '&:hover': {
      backgroundColor: theme.palette.primary.gray
    }
  },
 
})

const SomIQToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    padding: '4px 10px',
    color: 'white',
    backgroundColor: theme.palette.primary.gray,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.gray, 0.2),
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main
    }
  },
}))(ToggleButtonGroup);


class WorkPlanHeader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      projectDialogOpen: false,
      projectFilter: [],
      planTypes: []
    }

    this.handleOpenProjectDialog = this.handleOpenProjectDialog.bind(this)
    this.handleProjectSelect = this.handleProjectSelect.bind(this)
    this._handleProjectChange = this._handleProjectChange.bind(this)
    this.handlePlanType = this.handlePlanType.bind(this)
  }

  handlePlanType(e, planTypes) {
    this.setState( { planTypes })
    if (typeof this.props.handlePlanType === 'function') {
      this.props.handlePlanType(planTypes)
    }
  }

  handleOpenProjectDialog() {
    this.setState({ projectDialogOpen: true })
  }

  _handleProjectChange(projectFilter) {
    this.setState({ projectDialogOpen: false, projectFilter })
    const { handleProjectChange } = this.props

    if (typeof handleProjectChange === 'function') {
      handleProjectChange(projectFilter)
    }
  }

  handleProjectSelect(e) {
    const { projectFilter } = this.state
    const { handleProjectChange } = this.props

    const v = Number(e.target.value)
    const i = projectFilter.indexOf(v)
    const nextState = i > -1 ? [ ...projectFilter.slice(0, i), ...projectFilter.slice(i+1) ] : [ ...projectFilter, v ]
    this.setState({ projectFilter: nextState  })

    if (typeof handleProjectChange === 'function') {
      handleProjectChange(nextState)
    }
  }

  _handleSkillChange(skillFilter) {

    this.setState({ projectDialogOpen: false })
    const { handleSkillChange } = this.props

    if (typeof handleSkillChange === 'function') {
      handleSkillChange(skillFilter)
    }
  }


  render() {
    const { weekStart, weekEnd, handleWeekBack, handleWeekNext, handleCurrentDay, projects, classes, skills, t, i18n } = this.props
    const { projectFilter, planTypes } = this.state
    const locale = {locale: i18n.language === 'sk' ? skLocale : csLocale}

    return (
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Button size="small" variant="contained" onClick={this.handleOpenProjectDialog}>Vybrat projekt</Button>
            <CheckboxModal title="Vyber projekt" searchInput="Najdi projekt" handleChange={this._handleProjectChange} handleClick={this.handleProjectSelect} open={this.state.projectDialogOpen} options={projects} selected={projectFilter} />

            <Box p={1} component="span">
              {projects.filter(p => projectFilter.includes(p.id)).map(project => (
                <ButtonGroup className={classes.label} key={`remove-project-${project.id}`} variant="contained" size="small">
                  <Button onClick={this._handleProjectChange.bind(this, [ project.id ])}>{project.shortcut}</Button>
                  <Button onClick={this._handleProjectChange.bind(this, projectFilter.filter(i => i !== project.id))} className={classes.labelIcon} aria-label="delete"><CloseIcon /></Button>
                </ButtonGroup>
              ))}

              {skills.map(skill => (
                <ButtonGroup className={classes.label} key={`remove-skill-${skill.id}`} variant="contained" size="small">
                  <Button onClick={this._handleSkillChange.bind(this, [ skill.id ])}>{skill.name}</Button>
                  <Button onClick={this._handleSkillChange.bind(this, skills.filter(i => i.id !== skill.id).map(i => i.id))} className={classes.labelIcon} aria-label="delete"><CloseIcon /></Button>
                </ButtonGroup>
              ))}
            </Box>
          </Grid>

          <Grid item md={6} x={12} container justifyContent="flex-end">
            <Box mr={2}>
              <SomIQToggleButtonGroup value={planTypes} onChange={this.handlePlanType}>
                <ToggleButton value='projects'>{t('projects')}</ToggleButton>
                <ToggleButton value='off'>{t('off')}</ToggleButton>
                <ToggleButton value='vacation'>{t('vacation')}</ToggleButton>
              </SomIQToggleButtonGroup>
            </Box>
            <ButtonGroup variant="contained" size="small">
              <Button onClick={handleWeekBack}>
                <KeyboardArrowLeft/>
              </Button>
              <Button onClick={handleCurrentDay}>{format(weekStart, 'd.', locale) + ' - ' + format(weekEnd, 'd. MMM Y', locale)}</Button>
              <Button onClick={handleWeekNext}>
                <KeyboardArrowRight/>
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default withTranslation('workplan')(withStyles(styles)(WorkPlanHeader))
