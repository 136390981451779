export const roles = {
  'ADMIN': 'admin',
  'EMPLOYEE': 'zamestnanec',
  'TESTER': 'tester',
  'BACK_OFFICE': 'back office',
  'PROJECT_MANAGER': 'projektový manažér',
  'HUMAN_RESOURCES': 'ľudské zdroje',
}

/**
 * @return {string}
 */
export function UserRoleTranslation(state) {
  if (state in roles) {
    return roles[state]
  }
  return 'neznáma'
}
