import React, {Component} from 'react'
import {authentication} from '../../services/authentication'
import {request} from '../../services/api'
import {formatISOYearMonth} from '../../utils/datetime'

import MenuAppBar from '../../components/MenuAppBar'
import Block from '../../components/Block';
import { Box, Button } from '@material-ui/core'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DateSelect from '../../components/DateSelect'

import {withSnackbar} from 'notistack'
import UserRecords from '../User/UserRecords'
import UserTable from './UserTable'
import {withTranslation} from 'react-i18next';

class Review extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      user: null,
      users: [],
      projects: [],
      date: new Date(),
    }

    this.setUser = this.setUser.bind(this)
  }


  componentDidMount() {
    request('GET', '/user/'+authentication.getUser().id+'/review/projects/')
      .then(projects => {
        const users = projects.reduce((p, c) => {
          return [ ...p, ...c.users.filter(u => !p.find(v => v.id === u.id))]
        }, [])

        this.setState({ projects, users })
      })
  }

  setUser(user = null) {
    this.setState({ user })
  }

  render() {
    const { user, date, users } = this.state
    const { t } = this.props

    return (
      <MenuAppBar>
        <Block header={t('header')}>
          <Box mt={2}>
            <Box component="div" display="inline-block">
              <Autocomplete
                options={users}
                getOptionLabel={option => option.firstName + ' ' + option.lastName}
                style={{ width: 300 }}
                value={user}
                renderInput={params => (
                  <TextField {...params} label={t('user')} fullWidth />
                )}
                onChange={(e, v) => {
                  this.setState({user: v})
                }}
              />
            </Box>
            {user && <Button variant="contained" color="primary" style={{ verticalAlign: 'bottom', marginLeft: '1rem' }} onClick={() => this.setUser(null)}>Zpět</Button>}

            <Box component="div" display="inline-block" style={{float: 'right'}}>
              <DateSelect variant="yearMonth" date={date} onChange={d => this.setState({date: d})}/>
            </Box>
          </Box>
        </Block>

        {user ? (
          <Block>
            <UserRecords userId={user.id} month={formatISOYearMonth(date)}/>
          </Block>
        ) :
          <UserTable projects={this.state.projects} date={date} onSelect={this.setUser} />
        }
      </MenuAppBar>
    )
  }
}

export default withTranslation('sections', {keyPrefix: 'review'})(withSnackbar(Review))
