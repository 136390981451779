import React, {Component} from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {Dialog} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import DateFnsUtils from '@date-io/date-fns'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {request} from '../../services/api'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {withSnackbar} from 'notistack'
import {currencyFormat} from '../../utils/currency'
import {formatDate} from '../../utils/datetime'
import ResponsiveTable from '../../components/ResponsiveTable'
import {withTranslation} from 'react-i18next';

const styles = () => ({
  smallColumn: {
    width: '100px'
  },
})

class UserRewards extends Component {
  rewardDefault = {
    id: undefined,
    date: (new Date()).toISOString().substring(0, 10),
    amount: 0,
    description: ''
  }

  constructor(props, context) {
    super(props, context)
    this.userId = this.props.userId
    this.state = {
      rewards: [],
      reward: this.rewardDefault,
      dialogOpen: false
    }
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.refresh = this.refresh.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    request('GET', '/user/'+this.userId+'/reward').then(data => this.setState({rewards: data}))
  }

  handleEdit(row) {
    this.setState({
      reward: Object.assign({}, row),
      dialogOpen: true
    })
  }

  handleCreate() {
    this.setState({
      reward: this.rewardDefault,
      dialogOpen: true
    })
  }

  handleDelete(row) {
    request('DELETE', '/user/'+this.userId+'/reward/'+row.id)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('reward_successfully_removed'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_deleting_data') + error.reason, {variant: 'error'})
      })
  }

  handleSave() {
    let method = (this.state.reward.id ? 'PUT' : 'POST')
    let url = '/user/'+this.userId+'/reward/'+(this.state.reward.id ?  this.state.reward.id : '')
    request(method, url, this.state.reward)
      .then(() => {
        this.props.enqueueSnackbar(this.props.t('changes_saved_successfully'), {variant: 'success'})
        this.setState({
          dialogOpen: false
        })
        this.refresh()
      })
      .catch((error) => {
        this.props.enqueueSnackbar(this.props.t('error_when_saving_data') + error.reason, {variant: 'error'})
      })
  }

  handleClose() {
    this.setState({
      reward: this.rewardDefault,
      dialogOpen: false
    })
  }

  handleChangeDate(field, date) {
    try {
      let reward = Object.assign({}, this.state.reward)
      reward[field] = date === null ? null : date.toISOString().substring(0,10)
      this.setState({reward: reward})
    }
    catch (e) {
      //ok
    }
  }

  render() {
    const { classes, t } = this.props
    const { reward } = this.state

    return (
      <>
        <ResponsiveTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('reward')}</TableCell>
              <TableCell>{t('note')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('edit')}</TableCell>
              <TableCell align="center" className={classes.smallColumn}>{t('delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rewards && this.state.rewards.map(row => (
              <TableRow key={row.id}>
                <TableCell>{formatDate(row.date)}</TableCell>
                <TableCell>{currencyFormat(row.amount)}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleEdit(row)}>
                    <EditIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => this.handleDelete(row)}>
                    <DeleteIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
        <Box mt={2}>
          <Grid item xl={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={this.handleCreate}>
                {t('add_reward')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <DialogTitle>Odmena</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="reward-amount"
              label={t('reward')}
              onChange={e => {
                let reward = Object.assign({}, this.state.reward)
                reward.amount = parseInt(e.target.value) ? parseInt(e.target.value) : 0
                this.setState({reward})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={reward.amount}
              fullWidth
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                margin="dense"
                id="reward-date"
                label={t('date')}
                format="yyyy-MM-dd"
                value={reward.from}
                onChange={(d) => this.handleChangeDate('from', d)}
              />
            </MuiPickersUtilsProvider>
            <TextField
              margin="dense"
              id="reward-description"
              label={t('note')}
              onChange={e => {
                let reward = Object.assign({}, this.state.reward)
                reward.description = e.target.value
                this.setState({reward})
              }}
              onKeyUp={(e) => { if (e.keyCode === 13) { this.handleSave() } }} //Save a form if enter is pressed
              value={reward.description}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              {t('cancel')}
            </Button>
            <Button onClick={this.handleSave} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withTranslation('user')(withSnackbar(withStyles(styles)(UserRewards)))
